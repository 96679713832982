import * as React from "react";
import { Descriptions, Card, Divider } from "antd";
import { useQuery } from "react-query";
import { getHostelById } from "../Requests";

const ShowHostelDetailsForm: React.FC<{ id: string }> = (props) => {
  const { data: hostel } = useQuery(
    ["hostel", props.id],
    async () => getHostelById(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );

  return (
    <Card>
      <Divider orientation="left">Hostel Info</Divider>

      <Descriptions
      // title="Basic Info"
      // extra={<HostelManageModal id={hostel.id} />}
      >
        <Descriptions.Item label="Hostel Name">
          {hostel.hostelName}
        </Descriptions.Item>
        <Descriptions.Item label="Email">{hostel.email}</Descriptions.Item>
        <Descriptions.Item label="Mobile">
          +91-{hostel.contact}
        </Descriptions.Item>
        <Descriptions.Item label="Website">{hostel.website}</Descriptions.Item>
        <Descriptions.Item label="Branch">{hostel.branch}</Descriptions.Item>

        <Descriptions.Item label="Address">{hostel.address}</Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Social Media Detail</Divider>
      <Descriptions>
        <Descriptions.Item label="FacebookUrl">
          {hostel.facebookUrl}
        </Descriptions.Item>
        <Descriptions.Item label="TwitterUrl">
          {hostel.twitterUrl}
        </Descriptions.Item>
        <Descriptions.Item label="InstagramUrl">
          {hostel.instagramUrl}
        </Descriptions.Item>

        <Descriptions.Item label="YoutubeUrl">
          {hostel.youtubeUrl}
        </Descriptions.Item>
        <Descriptions.Item label="LinkedInUrl">
          {hostel.linkedInUrl}
        </Descriptions.Item>
        <Descriptions.Item label="PinterestUrl">
          {hostel.pinterestUrl}
        </Descriptions.Item>
      </Descriptions>
    </Card>
    // </Card>
  );
};

export default ShowHostelDetailsForm;
