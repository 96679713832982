import { TRoute } from "../../utils/routes";
import HostelPageHome from "./Page/Home";

const HostelRouter: TRoute[] = [
  {
    path: "/hostelprofile",
    name: "hostelprofile",
    title: "hostelprofile",
    component: HostelPageHome,
  },
];

export default HostelRouter;
