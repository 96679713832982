import * as React from "react";

import { Descriptions, Card, Divider, Typography, Button, Space, Row, Col, Input } from "antd";
import { useQuery } from "react-query";
import { getPaymentById } from "../Requests";
import ReactToPrint from "react-to-print";
import { s3Path } from "../../../utils/s3Config/s3upload";

import {
  PhoneFilled,
  MailFilled,
  GlobalOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import moment from "moment";
var converter = require('number-to-words');
const ShowPaymentDetailsForm: React.FC<{ id: string }> = (props) => {
  const { data: payment } = useQuery(
    ["payment", props.id],
    async () => getPaymentById(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );
  const componentRef = React.useRef(null);


  return (
   <>
      <ReactToPrint
  trigger={() => <Button color="primary">Print Paymentt Receipt</Button>}
  content={() => componentRef.current}
/>
      <div ref={componentRef} style={{backgroundColor:"lightyellow"}}>
        <Row style={{ margin: 20 }} justify={"space-between"} align={"middle"}>
          <Col>
        <Space.Compact direction="vertical">
              <Typography.Title level={2}>
              {payment?.occupantId?.hostelId?.hostelName}
              </Typography.Title>
                <Typography.Text>
              {payment?.occupantId?.hostelId?.address}, {payment?.occupantId?.hostelId?.branch} 
              </Typography.Text>
              <Typography.Text>
               <PhoneFilled />  Mob.: +91-{payment?.occupantId?.hostelId?.contact}
              </Typography.Text>
              <Typography.Text>
                <MailFilled /> {payment?.occupantId?.hostelId?.email}
              </Typography.Text>
              <Typography.Text>
                <GlobalOutlined /> {payment?.occupantId?.hostelId?.website}
              </Typography.Text>
            
            </Space.Compact>
            </Col>
             <Col>
            <img src={s3Path+payment?.occupantId?.hostelId?.logoUrl} alt="WWC" height={100} width={100} />
          </Col>
        </Row>
        <Row justify={"center"}>
          <Button size="small" type="primary"   shape="round" style={{backgroundColor:"black", fontWeight:"bold"}}>MONEY RECEIPT</Button>
        </Row>
        <Row justify={"space-between"} style={{ margin: 20 }}>
          <Col>
            <Space>
            <Typography.Text strong>Receipt No.</Typography.Text>
              <Typography.Text>{payment?.seq}</Typography.Text>
              </Space>
          </Col>
          <Col>
            <Space>
            <Typography.Text strong>Payment Date</Typography.Text>
              <Typography.Text>{moment(payment?.createdAt).format("DD/MM/YYYY")}</Typography.Text>
              </Space>
          </Col>
        </Row>
        <Row style={{margin:20, borderBottom:'1px #00154 dotted'}}>
          <Space>
         <Typography.Text strong>Received with thanks from Mrs. /Mr/Miss.</Typography.Text>
         <span style={{textDecorationLine: "underline",textDecorationStyle: "dashed" ,whiteSpace:"pre", alignItems:"center"}}>
  {payment?.occupantId?.parentName}                                                                                                               </span>
            </Space>
        </Row>
        <Row style={{ margin: 20 }}>
           <Space>
         <Typography.Text strong>Room NO./Month:</Typography.Text>
            <span style={{textDecorationLine: "underline",textDecorationStyle: "dashed" ,whiteSpace:"pre", alignItems:"center"}}>
              {`${payment?.occupantId?.roomId?.roomNo}\(${payment?.remarks }\)  `}                                                                                                                                                   </span>
            </Space>
        </Row>
        <Row style={{margin:20}}>
          <Col>
            <Space>
            <Typography.Text strong>Vide Cash/Cheque/DD No.</Typography.Text>
               <span style={{textDecorationLine: "underline",textDecorationStyle: "dashed" ,whiteSpace:"pre"}}>                                                                                                                                                </span>
            </Space>
          </Col>
          <Col>
            <Space>
            <Typography.Text strong>Date :</Typography.Text>
            <span style={{ textDecorationLine: "underline", textDecorationStyle: "dashed", whiteSpace: "pre" }}>
                {moment(payment?.createdAt).format("DD/MMM/YYYY")} </span>
              </Space>
          </Col>
        </Row>
        <Row style={{margin:20}}>
            <Space>
            <Typography.Text strong>Drawn on </Typography.Text>
               <span style={{textDecorationLine: "underline",textDecorationStyle: "dashed" ,whiteSpace:"pre"}}>                                                                                                                                                                                                              </span>
            </Space>
        </Row>
        <Row style={{margin:20}}>
            <Space>
            <Typography.Text strong>a some of Rupees on </Typography.Text>
            <span style={{ textDecorationLine: "underline", textDecorationStyle: "dashed", whiteSpace: "pre" }}>
              {converter.toWords(payment?.amount)}                                                                                                  </span>
            </Space>
        </Row>
        <Row style={{margin:20}}>
            <Space>
            <Typography.Text strong> on account of </Typography.Text>
               <span style={{textDecorationLine: "underline",textDecorationStyle: "dashed" ,whiteSpace:"pre"}}>                                                                                                                                                                                                        </span>
            </Space>
        </Row>

        <Row style={{ margin: 20 , marginBottom:20}} justify={"space-between"} align={"middle"}>
          <Col>
            <Input addonBefore="Rs." value={payment?.amount} disabled width={20}/>
          </Col>
          <Col>
            <Typography.Text style={{fontStyle:"italic"}}>Tenant's Signature</Typography.Text>
          </Col>
             <Col>
            <Typography.Text strong>Authorised Signature</Typography.Text>
          </Col>

        </Row>
        <br />
        
                   
        </div>
 </>
  );
};

export default ShowPaymentDetailsForm;
