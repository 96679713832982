import { request } from "../../../utils/services";

export const getAllDailyMenu = async () => {
  const { data } = await request("admin.dailyMenu.list");
  return data;
};
export const getAllActiveDailyMenu = async () => {
  const { data } = await request("admin.dailyMenu.active");
  return data;
};

export const getDailyMenuById = async (id: string) => {
  const { data } = await request("admin.dailyMenu.id", { id });
  return data;
};
export const getDailyMenuByHostelId = async (id: string) => {
  const { data } = await request("admin.dailyMenu.hostelId", { id });
  return data;
};
export const getDailyMenuIdWithDetail = async (id: string) => {
  const { data } = await request("admin.dailyMenu.id.detail", { id });
  return data;
};
