import * as React from "react";
import {
  Form,
  Button,
  Select,
  Col,
  Row,
  message,
  Typography,
  Card,
  Tag,
  Image,
  Avatar,
} from "antd";

import { InfoCircleOutlined } from "@ant-design/icons";

import { ManageAssignedRoomModalContext } from "../Modal/assignedRoom";
import LocalStorageService from "../../../utils/local-storage";
import { useMutation, useQuery } from "react-query";
import { request } from "../../../utils/services";
import { queryClient } from "../../..";
import {
  getAllRoom,
  getRoomByHostelId,
  getRoomById,
  getRoomByIdDetail,
} from "../../Room/Requests";
import {
  getAllPaymentTerms,
  getPaymentTermsById,
} from "../../PaymentTerms/Requests";
import CreatePaymentTermsModal from "../../PaymentTerms/Modal/CreatePaymentTerms";
import { getOccupantById } from "../Requests";
import { log } from "console";
import { s3Path } from "../../../utils/s3Config/s3upload";
// import { getAllActiveUnit, getAllUnit } from "src/modules/Unit/Requests";
// import { getAllPersonnel } from "src/modules/HR/Personnel/Requests";
const { Text, Title } = Typography;
const CreateAssignRoomForm: React.FC<{ id?: string ,hostelId:string}> = (props) => {
  const modalContext = React.useContext(ManageAssignedRoomModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  // const [isAssigned, setIsAssigned] = React.useState(true);
  const [isPaymentTermsSelected, setIsPaymentTermsSelected] =
    React.useState(false);
  const [paymentTermsData, setPaymentTermsData] = React.useState<Object | any>(
    {}
  );
  const [isRoomSelected, setIsRoomSelected] = React.useState(false);
  const [roomData, setRoomData] = React.useState<Object | any>({});
  const assignRoomMutation = useMutation(
    (values: any) => request("admin.occupant.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("occupant");
      },
    }
  );
  const unAssignRoomMutation = useMutation(
    (values: any) =>
      request("admin.room.unassign", { id: values.roomId }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("occupant");
      },
    }
  );
  const {
    isLoading,
    data: occupant,
    error,
    isError,
  } = useQuery(
    ["occupant", props.id],
    async () => getOccupantById(props.id ? props.id : "0"),
    {
      refetchOnWindowFocus: false,
    }
  );
  const [isAssigned, setIsAssigned] = React.useState(
    occupant.roomId ? true : false
  );

  const { data: room } = useQuery(["room",props.hostelId], async () => getRoomByHostelId(props.hostelId), {
    refetchOnWindowFocus: false,
  });
  const { data: paymentTerms } = useQuery(
    ["paymentTerms"],
    async () => getAllPaymentTerms(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    values.updatedBy = LocalStorageService.getAccount().id;
    values.occupantId = props.id;

    values.availability = roomData.availability - 1;

    // values.orderStatus = "AssignedToUnit"; // Timeline
    // values.content = "Order Assigned by"; // Timeline
    // values.description = "Assigned to"; // Timeline
    //values.partyId = party[values.partyId].id;
    setFormLoading(true);
    console.log(values);

    assignRoomMutation
      .mutateAsync(values)
      .then((payload: any) => {
        setFormLoading(false);
        message.success(payload.message);
        modalContext.setIsModalVisible(false);
        setIsAssigned(true);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e.message);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const UnassignedRoom = async (values: any) => {
    values.updatedBy = LocalStorageService.getAccount().id;
    values.occupantId = props.id;
    //values.roomId = occupant.roomId;
    values.availability = roomData.availability + 1;
    //console.log(values, "v");
    unAssignRoomMutation
      .mutateAsync(values)
      .then((payload: any) => {
        form.setFieldsValue({
          roomId: null,
          paymentTermsId: null,
        });
        setIsAssigned(false);
        setIsRoomSelected(false);
        setIsPaymentTermsSelected(false);

        message.success("Room Un-Assigned");
      })
      .catch((e: any) => {
        //setFormLoading(false);
        console.log(e);

        message.error(e);
      });
  };
  const mutateRoom = useMutation((id: string) => getRoomByIdDetail(id));
  const OnRoomSelect = (i: any) => {
    setIsRoomSelected(true);

    mutateRoom
      .mutateAsync(i)
      .then((payload) => {
        setRoomData(payload);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  const mutatePayment = useMutation((id: string) => getPaymentTermsById(id));
  const OnPaymentTermsSelect = (i: any) => {
    setIsPaymentTermsSelected(true);
    mutatePayment
      .mutateAsync(i)
      .then((payload) => {
        setPaymentTermsData(payload);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  // console.log(occupant);
  React.useEffect(() => {
    if (occupant.roomId && occupant.paymentTermsId) {
      OnRoomSelect(occupant.roomId);
      OnPaymentTermsSelect(occupant.paymentTermsId);
    }
  }, []);
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={occupant}
    >
      <Form.Item
        label="Room No"
        style={{ fontWeight: "bold" }}
        name="roomId"
        tooltip={{
          title: "Room no",
          icon: <InfoCircleOutlined />,
        }}
      >
        {isRoomSelected ? (
          <Card>
            <Row justify={"space-between"} align={"middle"}>
              <Title level={3}>{roomData?.roomNo}</Title>
              <Col>
                <Row>
                  {roomData?.facilityId &&
                    roomData?.facilityId?.map((item: any, i: number) => {
                      return (
                        <Avatar
                          size={"small"}
                          src={<img src={s3Path + item.icon} alt={"R"} />}
                        />
                      );
                    })}
                </Row>
                <Row>
                  <Text code> Occupancy:{roomData.occupancy}</Text>
                  <Text code> Available:{roomData.availability}</Text>
                </Row>
              </Col>
              {!isAssigned && (
                <Button
                  onClick={() => {
                    form.setFieldsValue({
                      roomId: null,
                    });
                    setIsRoomSelected(false);
                  }}
                  type="dashed"
                >
                  Change Room
                </Button>
              )}
            </Row>
          </Card>
        ) : (
          <Select
            onSelect={OnRoomSelect}
            showSearch
            size="large"
            popupMatchSelectWidth
            // style={{ minHeight: 150 }}
            placeholder="Select Room No"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input)
            }
          >
            {room &&
              room?.map((item: any, i: number) => {
                return (
                  <Select.Option
                    value={item?.id}
                    key={i}
                    disabled={item.availability === 0 ? true : false}
                  >
                    <Row justify={"space-between"} align={"top"}>
                      <h4>{item?.roomNo}</h4>
                      <Col>
                        <Row>
                          {item?.facilityId &&
                            item?.facilityId?.map((item: any, i: number) => {
                              return (
                                <Avatar
                                  size={"small"}
                                  src={
                                    <img src={s3Path + item.icon} alt={"R"} />
                                  }
                                />
                              );
                            })}
                        </Row>
                        <Row>
                          <Text code> Occupancy:{item.occupancy}</Text>
                          <Text code> Available:{item.availability}</Text>
                        </Row>
                      </Col>
                    </Row>
                  </Select.Option>
                );
              })}
          </Select>
        )}
      </Form.Item>

      <Form.Item
        label="Plan"
        style={{ fontWeight: "bold" }}
        name="paymentTermsId"
        tooltip={{
          title: "Plan",
          icon: <InfoCircleOutlined />,
        }}
      >
        {isPaymentTermsSelected ? (
          <Card>
            <Row justify={"space-between"} align={"middle"}>
              <Col>
                <Text
                  style={{
                    margin: 2,
                    fontWeight: "bold",
                    textDecorationLine: "underline",
                  }}
                >
                  {paymentTermsData.title}
                </Text>
                {paymentTermsData?.description?.map((item: any, i: number) => {
                  return (
                    <Col>
                      <Text style={{ margin: 2, fontWeight: "normal" }}>
                        {i + 1 + "." + item}
                      </Text>
                    </Col>
                  );
                })}
              </Col>
              <Col>
                <Row>
                  <Text code>Price: ₹{paymentTermsData?.price}</Text>
                </Row>
                <Row>
                  <Text type="success" code>
                    Frequency:{paymentTermsData.frequency}
                  </Text>
                </Row>
                <Row>
                  <Text type="danger" code>
                    Food:{paymentTermsData.food}
                  </Text>
                </Row>
              </Col>

              {!isAssigned && (
                <Button
                  onClick={() => {
                    form.setFieldsValue({
                      paymentTermsId: null,
                    });
                    setIsPaymentTermsSelected(false);
                  }}
                  type="dashed"
                >
                  Change Plan
                </Button>
              )}
            </Row>
          </Card>
        ) : (
          <Select
            onSelect={OnPaymentTermsSelect}
            showSearch
            popupMatchSelectWidth
            // style={{ minHeight: 150 }}
            placeholder="Select Plan"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input)
            }
            dropdownRender={(menu) => (
              <>
                <CreatePaymentTermsModal />

                {menu}
              </>
            )}
          >
            {paymentTerms &&
              paymentTerms?.map((item: any, i: number) => {
                return (
                  <Select.Option value={item?.id} key={i}>
                    <Row justify={"space-between"} align={"middle"}>
                      <Col>
                        <h4>{item.title}</h4>
                        {item?.description?.map((item: any, i: number) => {
                          return (
                            <Col>
                              <Text style={{ margin: 2 }}>
                                {i + 1 + "." + item}
                              </Text>
                            </Col>
                          );
                        })}
                      </Col>

                      <Col>
                        <Row>
                          <Text code>Price: ₹{paymentTermsData?.price}</Text>
                        </Row>
                        <Row>
                          <Text type="success" code>
                            Frequency:{paymentTermsData.frequency}
                          </Text>
                        </Row>
                        <Row>
                          <Text type="danger" code>
                            Food:{paymentTermsData.food}
                          </Text>
                        </Row>
                      </Col>
                    </Row>
                  </Select.Option>
                );
              })}
          </Select>
        )}
      </Form.Item>
      {isAssigned ? (
        <Button onClick={() => UnassignedRoom({ roomId: occupant.roomId })}>
          Unassigned Room
        </Button>
      ) : (
        <Form.Item style={{ textAlign: "center" }}>
          <Button
            size="large"
            loading={formLoading}
            type="primary"
            htmlType="submit"
            shape="round"
          >
            Assign Room
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

export default CreateAssignRoomForm;
