import * as React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Switch,
  Select,
  Row,
  Col,
  Upload,
  Divider,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  MobileOutlined,
  UserOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";
import { ManageOccupantModalContext } from "../Modal/ManageOccupant";
import { useQuery } from "react-query";
import { getOccupantById } from "../Requests";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { s3Path, uploadFileToAWS } from "../../../utils/s3Config/s3upload";
import LocalStorageService from "../../../utils/local-storage";
import TextArea from "antd/es/input/TextArea";
import { InputRules } from "../../../utils/InputRules";
import { getAllHostel } from "../../Hotel Detail/Requests";

const ManageOccupantForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageOccupantModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();

  const ManageOccupantMutation = useMutation(
    (values: any) => request("admin.occupant.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("occupant");
      },
    }
  );
  const { data: occupant } = useQuery(
    ["occupant", props.id],
    async () => getOccupantById(props.id),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
    }
  );

  const { data: hostel } = useQuery(["hostel"], async () => getAllHostel(), {
    refetchOnWindowFocus: false,
  });
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const onFinish = async (values: any) => {
    values.updatedBy = LocalStorageService.getAccount().id;
    setFormLoading(true);
    // const response = await uploadFileToAWS(selectedFile, "Profile");
    // values.imageUrl = response.Key;
    ManageOccupantMutation.mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The occupant has been successfully Managed.");
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={occupant}
    >
      <Row gutter={20} align={"bottom"}>
        {/* <Col flex={3} span={12}>
          <Form.Item
            label="Occupant Photo"
            style={{ fontWeight: "bold" }}
            name="imageUrl"
            valuePropName="checked"
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              accept="image/png, image/jpeg"
              action={async (file) => {
                const response: any = await uploadFileToAWS(file, "Occupant");
                setGallery(response.key);
                return response.key;
              }}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
        </Col> */}
        <Col flex={3} span={12}>
          <Form.Item
            label="Select Hostel"
            name="hostelId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "hostelId",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Select Hostel ",
              },

              { whitespace: true },
            ]}
            hasFeedback
          >
            <Select
              showSearch
              placeholder="Select Hostel"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA!.children as unknown as string)
                  .toLowerCase()
                  .localeCompare(
                    (optionB!.children as unknown as string).toLowerCase()
                  )
              }
            >
              {hostel &&
                hostel?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.id} key={i}>
                      {item?.hostelName}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Occupant Name"
            name="name"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Occupant Name",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Your Name",
              },

              { whitespace: true },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input
              type="alphabet"
              addonBefore={<UserOutlined />}
              placeholder="Enter Name"
              maxLength={20}
            />
          </Form.Item>
        </Col>

        <Col flex={3} span={12}>
          <Form.Item
            label="Phone No"
            name="phoneNo"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Official Phone no",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please input Your Phone number!",
              },
              ...InputRules.phoneNo,
              // {
              //   pattern: new RegExp("([0-9])"),
              //   message: "field accept only number",
              // },
              // {
              //   min: 10,
              //   message: "Enter a Valid Number+",
              // },
            ]}
          >
            <Input
              placeholder="Enter Your Mobile Number"
              addonBefore="+91-"
              addonAfter={<MobileOutlined />}
              minLength={10}
              maxLength={10}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Age"
            style={{ fontWeight: "bold" }}
            name="age"
            tooltip={{
              title: "Age",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              { required: true, message: "Please Enter Your Age" },
              {
                min: 2,
                max: 2,
                message: "Value should 2 character",
              },
            ]}
          >
            <Input placeholder="Enter Your Age" type="number" maxLength={2} />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Gender"
            style={{ fontWeight: "bold" }}
            name="gender"
            tooltip={{
              title: "Gender",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ required: true, message: "Please Select Your Gender" }]}
          >
            <Select
              placeholder="Select Gender"
              options={[
                { value: "Male", label: "Male" },
                { value: "Female", label: "Female" },
                { value: "Other", label: "Other" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Adhaar No."
            style={{ fontWeight: "bold" }}
            name="adharNo"
            tooltip={{
              title: "Adhar No",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              { required: true, message: "Please Enter your Adhar number" },
              {
                min: 12,
                message: "Value should be 12 Character ",
              },
              ...InputRules.number,
            ]}
          >
            <Input placeholder="Enter Your Adhar Number" maxLength={12} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={2} span={12}>
          <Form.Item
            label="Occupation"
            style={{ fontWeight: "bold" }}
            name="occupation"
            tooltip={{
              title: "Occupation",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              { required: true, message: "Please Enter Your Occupation" },
              ...InputRules.text,
            ]}
          >
            <Input placeholder="Enter Your Occupant" maxLength={20} />
          </Form.Item>
        </Col>
        <Col flex={2} span={12}>
          <Form.Item
            label="WorkPlace/College/School"
            style={{ fontWeight: "bold" }}
            name="workPlace"
            tooltip={{
              title: "WorkPlace",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter your work Place",
              },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Your WorkPlace" maxLength={30} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Address"
            style={{ fontWeight: "bold" }}
            name="address"
            tooltip={{
              title: "Address",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ required: true }, ...InputRules.text]}
          >
            <TextArea
              placeholder="Enter Your Permanent Address"
              maxLength={50}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Remarks"
            style={{ fontWeight: "bold" }}
            name="remarks"
            tooltip={{
              title: "remarks",
              icon: <InfoCircleOutlined />,
            }}
            rules={[...InputRules.text]}
          >
            <TextArea placeholder="Enter Rmarks" maxLength={50} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Reference"
            style={{ fontWeight: "bold" }}
            name="reference"
            tooltip={{
              title: "Reference",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              { required: true, message: "Please Enter Your Reference" },
              ...InputRules.text,
            ]}
          >
            <Input placeholder="Enter Reference" maxLength={20} />
          </Form.Item>
        </Col>

        <Col flex={1} span={8}>
          <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
            // rules={[{ required: true }]}
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>
      </Row>{" "}
      <Divider />
      <h2>Family Detail</h2>
      <Divider />
      <Row gutter={20}>
        <Col flex={2} span={8}>
          <Form.Item
            label="Parent Name"
            style={{ fontWeight: "bold" }}
            name="parentName"
            tooltip={{
              title: "Parent Name",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              { required: true, message: "Please Enter Your Parent Name" },
              ...InputRules.text,
            ]}
          >
            <Input
              addonBefore={<UserOutlined />}
              placeholder="Enter Your Parent Name"
              maxLength={20}
            />
          </Form.Item>
        </Col>
        <Col flex={2} span={8}>
          <Form.Item
            label="Phone No"
            style={{ fontWeight: "bold" }}
            name="pmobNo"
            tooltip={{
              title: "Phone no",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Your Parent Mobile Number",
              },
              ...InputRules.phoneNo,
            ]}
          >
            <Input
              placeholder="Enter Your Parent Mobile Number"
              addonBefore="+91"
              maxLength={10}
              addonAfter={<MobileOutlined />}
            />
          </Form.Item>
        </Col>

        <Col flex={2} span={8}>
          <Form.Item
            label="Relation"
            style={{ fontWeight: "bold" }}
            name="relation"
            tooltip={{
              title: "relation",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              { required: true, message: " Please Enter Relation" },
              ...InputRules.text,
            ]}
          >
            <Input placeholder="Enter Relation" maxLength={15} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={2} span={8}>
          <Form.Item
            label="Address"
            style={{ fontWeight: "bold" }}
            name="paddress"
            tooltip={{
              title: "Parent Address",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Your Parent Address",
              },
              ...InputRules.text,
            ]}
          >
            <TextArea placeholder="Enter Your Parent Address" maxLength={50} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Update Occupant
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageOccupantForm;
