import * as React from "react";
import { Form, Button, message, Row, Input, Col, Switch } from "antd";

import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  UserOutlined,
  MailOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";

import LocalStorageService from "../../../utils/local-storage";
import { CreateEnquiryModalContext } from "../Modal/CreateEnquiry";
import { type } from "os";
import TextArea from "antd/es/input/TextArea";
import { InputRules } from "../../../utils/InputRules";

const CreateEnquiry: React.FC = () => {
  const modalContext = React.useContext(CreateEnquiryModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const createEnquiryMutation = useMutation(
    (values: any) => request("admin.enquiry.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("enquiry");
      },
    }
  );

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;

    setFormLoading(true);

    createEnquiryMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Enquiry has been successfully created.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Name"
        name="name"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please enter your  Name",
          },
          { whitespace: true },
          { min: 3 },
          ...InputRules.text,
        ]}
        hasFeedback
      >
        <Input
          addonBefore={<UserOutlined />}
          placeholder="Enter Your Name"
          maxLength={20}
        />
      </Form.Item>

      <Form.Item
        label="Mobile No"
        name="mobileNo"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Mobile No",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Your  Mobile No",
          },
          { whitespace: true },
          ...InputRules.phoneNo,
        ]}
        hasFeedback
      >
        <Input
          maxLength={10}
          placeholder="Enter Your Mobile Number"
          addonBefore="+91"
          addonAfter={<MobileOutlined />}
        />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Email",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please enter your Email",
          },
          { whitespace: true },
          { min: 3 },
          { type: "email", message: "Please enter a valid Email" },
        ]}
        hasFeedback
      >
        <Input
          addonBefore={<MailOutlined />}
          placeholder="Enter Your Email"
          maxLength={35}
        />
      </Form.Item>
      <Form.Item
        label="How can we help you?"
        name="help"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Help",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ whitespace: true }, { min: 3 }]}
        hasFeedback
      >
        <TextArea
          maxLength={50}
          placeholder="I was wondering about availability and rates. I need help with the following: "
        />
      </Form.Item>
      <Row gutter={20}>
        <Col flex={2} span={12}>
          <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateEnquiry;
