import { request } from "../../../utils/services";

export const getAllActiveImage = async () => {
  const { data } = await request("admin.image.list.active");
  return data;
};

export const getAllImage = async () => {
  const { data } = await request("admin.image.list");
  return data;
};

export const getImageById = async (id: string) => {
  const { data } = await request("admin.image.id", { id });
  return data;
};
