import { request } from "../../../utils/services";

export const getAllActiveOccupant = async () => {
  const { data } = await request("admin.occupant.list.active");
  return data;
};

export const getAllOccupant = async () => {
  const { data } = await request("admin.occupant.list");
  return data;
};

export const getOccupantById = async (id: string) => {
  const { data } = await request("admin.occupant.id", { id });
  return data;
};
export const getOccupantByIdDetail = async (id: string) => {
  const { data } = await request("admin.occupant.id.detail", { id });
  return data;
};
export const getOccupantByHostelId = async (id: string) => {
  const { data } = await request("admin.occupant.hostelId", { id });
  return data;
};
