import * as React from "react";

import { useMutation, useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Segmented, Space, Table, Tag, Typography } from "antd";

import {
  getAllActiveDailyMenu,
  getAllDailyMenu,
  getDailyMenuByHostelId,
} from "../Requests/index";

import ManageDailyMenuModal from "../Modal/ManageDailyMenu";
import { getAllHostel } from "../../Hotel Detail/Requests";
import DailyMenuViewModal from "../Modal/ShowDailyMenu";

interface DataType {
  id: number;
  name: any;
}

const DailyMenuList: React.FC = () => {
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["dailymenu"], () => getAllActiveDailyMenu(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Hostel Detail",
      dataIndex: "hostelId",
      key: "hostelId",
      render: (_: any, i: any) => (
        <div>
          <Typography.Title level={5}>
            {i?.hostelId?.hostelName}
          </Typography.Title>
        </div>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: "View/Print",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <DailyMenuViewModal id={id} />
        </Space>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <ManageDailyMenuModal id={id} />
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table size="small" columns={column} dataSource={response} />
    </>
  );
};

export default DailyMenuList;
