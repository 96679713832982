import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LocalStorageService from "../../../utils/local-storage";
import CreateHostelForm from "../Form/CreateHostelForm";

const HostelPageHome: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <h2>Hostel Details</h2>
      <CreateHostelForm id={LocalStorageService.getAccount().id} />
    </>
  );
};

export default withRouter(HostelPageHome);
