import { request } from "../../../utils/services";

export const getAllActiveBooking = async () => {
  const { data } = await request("admin.booking.list.active");
  return data;
};

export const getAllBooking = async () => {
  const { data } = await request("admin.booking.list");
  return data;
};

export const getBookingById = async (id: string) => {
  const { data } = await request("admin.booking.id", { id });
  return data;
};
export const getBookingByIdDetail = async (id: string) => {
  const { data } = await request("admin.booking.id.detail", { id });
  return data;
};
