import { TRoute } from "../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const EnquiryRouter: TRoute[] = [
  {
    path: "/enquiry",
    name: "enquiry",
    title: "enquiry",
    component: DepartmentPageHome,
  },
];

export default EnquiryRouter;
