import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";

import { Row } from "antd";
import CreateHostelModal from "../Modal/CreateHostel";
import HostelList from "../Component/List";
import CreateHostelForm from "../Form/CreateHostelForm";
import LocalStorageService from "../../../utils/local-storage";
import CreateHostel from "../Form/CreateHostelForm";

const HostelPageHome: React.FC<RouteComponentProps> = () => {
  return (
    <>
      {/* <h2>Hostel Details</h2> */}
      <CreateHostel />
    </>
    // <React.Fragment>
    //   <Card>
    //     <CardHeader>
    //       <CardHeaderToolbar>
    //         <Row justify="space-between">
    //           <h2>Hostel List</h2>
    //           <CreateHostelModal />
    //         </Row>
    //       </CardHeaderToolbar>
    //     </CardHeader>
    //     <CardBody>
    //       <HostelList />
    //     </CardBody>
    //   </Card>
    // </React.Fragment>
  );
};

export default withRouter(HostelPageHome);
