import { request } from "../../../utils/services";

export const getAllActiveFacility = async () => {
  const { data } = await request("admin.facility.list.active");
  return data;
};

export const getAllFacility = async () => {
  const { data } = await request("admin.facility.list");
  return data;
};

export const getFacilityById = async (id: string) => {
  const { data } = await request("admin.facility.id", { id });
  return data;
};
