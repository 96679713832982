import * as React from "react";
import {
  Form,
  Button,
  message,
  Select,
  Row,
  Typography,
  Switch,
  Col,
  Input,
  Divider,
  DatePicker,
  Tag,
  Card,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { queryClient } from "../../..";
import { ManageBookingModalContext } from "../Modal/ManageBooking";

import { useQuery } from "react-query";

import TextArea from "antd/lib/input/TextArea";

import { getBookingById } from "../Requests";
import moment from "moment";
import Upload, { RcFile, UploadFile, UploadProps } from "antd/es/upload";

import { getAllRoom, getRoomById } from "../../Room/Requests";
import { getAllOccupant, getOccupantById } from "../../Occupant/Requests";
import {
  getAllPaymentTerms,
  getPaymentTermsById,
} from "../../PaymentTerms/Requests";

const { Text, Title } = Typography;

const ManageBookingForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageBookingModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [isRoomSelected, setIsRoomSelected] = React.useState(false);
  const [roomData, setRoomData] = React.useState<Object | any>({});
  const [isOccupantSelected, setIsOccupantSelected] = React.useState(false);
  const [occupantData, setOccupantData] = React.useState<Object | any>({});
  const [isPaymentTermsSelected, setIsPaymentTermsSelected] =
    React.useState(false);
  const [paymentTermsData, setPaymentTermsData] = React.useState<Object | any>(
    {}
  );
  const manageBookingMutation = useMutation(
    (values: any) => request("admin.booking.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Booking");
      },
    }
  );
  const { data: booking } = useQuery(
    ["booking", props.id],
    async () => getBookingById(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: room } = useQuery(["room"], async () => getAllRoom(), {
    refetchOnWindowFocus: false,
  });
  const { data: occupant } = useQuery(
    ["occupant"],
    async () => getAllOccupant(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: paymentTerms } = useQuery(
    ["paymentTerms"],
    async () => getAllPaymentTerms(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
    setFormLoading(true);

    manageBookingMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Booking has been successfully managed.");
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const mutateRoom = useMutation((id: string) => getRoomById(id));
  const OnRoomSelect = (i: any) => {
    setIsRoomSelected(true);
    mutateRoom
      .mutateAsync(i)
      .then((payload) => {
        setRoomData(payload);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  const mutateOccupant = useMutation((id: string) => getOccupantById(id));
  const OnOccupantSelect = (i: any) => {
    setIsOccupantSelected(true);
    mutateOccupant
      .mutateAsync(i)
      .then((payload) => {
        setOccupantData(payload);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  const mutatePayment = useMutation((id: string) => getPaymentTermsById(id));
  const OnPaymentTermsSelect = (i: any) => {
    setIsPaymentTermsSelected(true);
    mutatePayment
      .mutateAsync(i)
      .then((payload) => {
        setPaymentTermsData(payload);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };

  // React.useEffect(() => {
  //   booking.occupantId = booking.occupantId._id;
  //   booking.paymentTermsId = booking.paymentTermsId._id;
  //   booking.roomId = booking.roomId._id;
  // }, []);
  booking.registrationDate = moment(booking.registrationDate, "YYYY-MM-DD");

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={booking}
    >
      {/* <Row gutter={15}>
        <Col flex={2} span={12}> */}
      <Form.Item
        label="RegistrationDate"
        name="registrationDate"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Registration Date",
          icon: <InfoCircleOutlined />,
        }}
        hasFeedback
      >
        <DatePicker />
      </Form.Item>
      {/* </Col>
        <Col flex={3} span={12}> */}
      <Form.Item
        label="Occupant Name"
        style={{ fontWeight: "bold" }}
        name="occupantId"
        tooltip={{
          title: "Occupant Name",
          icon: <InfoCircleOutlined />,
        }}
      >
        {isOccupantSelected ? (
          <Card>
            <Row justify={"space-between"} align={"middle"}>
              <Title level={3}>{occupantData?.name}</Title>
              <Col>
                <Row>
                  <Text type="success" code>
                    Phone No:{occupantData?.phoneNo}
                  </Text>
                </Row>
                <Row>
                  <Text type="warning" code>
                    Address:{occupantData?.address}
                  </Text>
                </Row>
                <Row>
                  <Text type="danger" code>
                    {" "}
                    Parent Name:{occupantData?.parentName}
                  </Text>
                </Row>
              </Col>
              <Button
                onClick={() => setIsOccupantSelected(false)}
                type="dashed"
              >
                Change Occupant
              </Button>
            </Row>
          </Card>
        ) : (
          <Select
            onSelect={OnOccupantSelect}
            showSearch
            popupMatchSelectWidth
            // style={{ minHeight: 150 }}
            placeholder="Select Occupant"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input)
            }
          >
            {occupant &&
              occupant?.map((item: any, i: number) => {
                return (
                  <Select.Option value={item?.id} key={i}>
                    <Row justify={"space-between"} align={"middle"}>
                      <Title level={5}>{item?.name}</Title>
                      <Col flex={3} span={6}>
                        <Row>
                          <Text type="success" code>
                            Phone No:{item?.phoneNo}
                          </Text>
                        </Row>
                        <Row>
                          <Text type="warning" code>
                            Address:{item?.address}
                          </Text>
                        </Row>
                        <Row>
                          <Text type="danger" code>
                            {" "}
                            Parent Name:{item?.parentName}
                          </Text>
                        </Row>
                      </Col>
                    </Row>
                    <Divider />
                  </Select.Option>
                );
              })}
          </Select>
        )}
      </Form.Item>
      {/* </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={2} span={12}> */}
      <Form.Item
        label="Room No"
        style={{ fontWeight: "bold" }}
        name="roomId"
        tooltip={{
          title: "Room no",
          icon: <InfoCircleOutlined />,
        }}
      >
        {isRoomSelected ? (
          <Card>
            <Row justify={"space-between"} align={"middle"}>
              <Title level={3}>{roomData?.roomNo}</Title>
              <Col>
                <Row>
                  {roomData?.facilityId &&
                    roomData?.facilityId?.map((item: any, i: number) => {
                      return (
                        <Tag color="red" style={{ margin: 2 }}>
                          {item.title}{" "}
                        </Tag>
                      );
                    })}
                </Row>
                <Row>
                  <Text code> Occupancy:{roomData.occupancy}</Text>
                  <Text code> Available:{roomData.availability}</Text>
                </Row>
              </Col>
              <Button onClick={() => setIsRoomSelected(false)} type="dashed">
                Change Room
              </Button>
            </Row>
          </Card>
        ) : (
          <Select
            onSelect={OnRoomSelect}
            showSearch
            popupMatchSelectWidth
            // style={{ minHeight: 150 }}
            placeholder="Select Room No"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input)
            }
          >
            {room &&
              room?.map((item: any, i: number) => {
                return (
                  <Select.Option value={item?.id} key={i}>
                    <Row justify={"space-between"} align={"middle"}>
                      <Title level={3}>{item?.roomNo}</Title>
                      <Col>
                        <Row>
                          {item?.facilityId &&
                            item?.facilityId?.map((item: any, i: number) => {
                              return (
                                <Tag color="red" style={{ margin: 2 }}>
                                  {item.title}{" "}
                                </Tag>
                              );
                            })}
                        </Row>
                        <Row>
                          <Text code> Occupancy:{item.occupancy}</Text>
                          <Text code> Available:{item.availability}</Text>
                        </Row>
                      </Col>
                    </Row>
                    <Divider />
                  </Select.Option>
                );
              })}
          </Select>
        )}
      </Form.Item>
      {/* </Col>
        <Col flex={2} span={12}> */}
      <Form.Item
        label="Payment Terms"
        style={{ fontWeight: "bold" }}
        name="paymentTermsId"
        tooltip={{
          title: "Payment Terms",
          icon: <InfoCircleOutlined />,
        }}
      >
        {isPaymentTermsSelected ? (
          <Card>
            <Row justify={"space-between"} align={"middle"}>
              <Title level={3}>{paymentTermsData?.price}</Title>
              <Col>
                <Row>
                  <Text type="success" code>
                    Frequency:{paymentTermsData.frequency}
                  </Text>
                  <Text type="danger" code>
                    Food:{paymentTermsData.food}
                  </Text>
                </Row>
              </Col>
              <Button
                onClick={() => setIsPaymentTermsSelected(false)}
                type="dashed"
              >
                Change Payment Terms
              </Button>
            </Row>
          </Card>
        ) : (
          <Select
            onSelect={OnPaymentTermsSelect}
            showSearch
            popupMatchSelectWidth
            // style={{ minHeight: 150 }}
            placeholder="Select Payment Terms"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input)
            }
          >
            {paymentTerms &&
              paymentTerms?.map((item: any, i: number) => {
                return (
                  <Select.Option value={item?.id} key={i}>
                    <Row justify={"space-between"} align={"middle"}>
                      <Title level={5}>{item?.price}</Title>
                      <Col>
                        <Text type="success" code>
                          Frequency:{item.frequency}
                        </Text>
                        <Text type="danger" code>
                          Food:{item.food}
                        </Text>
                      </Col>
                    </Row>
                    <Divider />
                  </Select.Option>
                );
              })}
          </Select>
        )}
      </Form.Item>
      {/* </Col>
      </Row>

      <Row gutter={20}>
        <Col flex={2} span={12}> */}
      <Form.Item
        label="Status"
        style={{ fontWeight: "bold" }}
        name="isActive"
        tooltip={{
          title: "status",
          icon: <InfoCircleOutlined />,
        }}
        valuePropName="checked"
        // rules={[{ required: true }]}
      >
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>
      {/* </Col>
      </Row> */}

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Manage Booking
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageBookingForm;
