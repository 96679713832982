import * as React from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  message,
  Tabs,
  Row,
  Col,
  Typography,
} from "antd";
import { request } from "../../../utils/services";
import { AuthContext } from "../Context/AuthContext";
import LocalStorageService from "../../../utils/local-storage";
import CookiesService from "../../../utils/cookie";
import loginAnim from "../../../assets/lottie/loginAnim.json";
import logo from "../../../assets/img/logo.png";
import Company_Long from "../../../assets/img/Company_Long.png";
import Lottie from "lottie-react";
import {
  LockOutlined,
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  HeartTwoTone,
} from "@ant-design/icons";
import {
  FormContainer,
  FormTop,
  FormHeader,
  FormDescription,
  FormMain,
  FormTitle,
} from "./Form.styled";

const LoginAuth: React.FC = (props) => {
  const authContext = React.useContext(AuthContext);
  const [type, setType] = React.useState("login");
  const [isloading, setLoading] = React.useState(false);

  const onFinish = async (values: any) => {
    const authApi = values.email ? "user.signup" : "user.login";
    setLoading(true);
    try {
      const { payload } = await request(authApi, {}, values);
      setLoading(false);
      console.log(payload);

      message.success("User Authentication Successfully");
      CookiesService.storeToken(payload.accessToken);
      LocalStorageService.storeAccount(payload);

      authContext.setToken(payload.accessToken);
    } catch (e) {
      setLoading(false);
      console.log("error", e);
      message.error("User Authentication Failed");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <FormContainer>
        <Row justify={"space-around"} align={"middle"}>
          <Col>
            {" "}
            <Lottie
              animationData={loginAnim}
              loop={true}
              style={{ height: "70vh" }}
            />
          </Col>
          <Col>
            <FormTop>
              <FormHeader>
                {/* <FormTitle>Hostel Management Software</FormTitle> */}
                <img src={logo} width={250} />
              </FormHeader>
            </FormTop>
            <br />
            <br />
            <FormMain>
              <Tabs defaultActiveKey={type} onChange={setType}>
                <Tabs.TabPane
                  key="login"
                  tab="Login to Account"
                  className="tab-nav-list"
                >
                  <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                  >
                    <Form.Item
                      label="Username"
                      name="username"
                      rules={[
                        {
                          required: true,
                          message: "Please input your username or email!",
                        },
                      ]}
                    >
                      <Input prefix={<UserOutlined />} />
                    </Form.Item>

                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        prefix={<LockOutlined />}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                    {/* <div
                  style={{
                    marginBottom: 24,
                  }}
                >
                  <Form.Item name="remember" valuePropName="checked">
                    <Checkbox>Remember me</Checkbox>
                    <a
                      style={{
                        float: "right",
                      }}
                      href="!#"
                    >
                      Forgot Password?
                    </a>
                  </Form.Item>
                </div> */}
                    <Form.Item wrapperCol={{ offset: 0 }}>
                      <Button
                        type="primary"
                        size="large"
                        loading={isloading}
                        htmlType="submit"
                        block
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </Tabs.TabPane>
              </Tabs>
            </FormMain>
          </Col>
        </Row>
      </FormContainer>
      <Row justify={"center"} align={"middle"}>
        <Typography.Text strong style={{ margin: 5 }}>
          Designed & Developed By{" "}
        </Typography.Text>{" "}
        <img src={Company_Long} width={100} />
        <Typography.Text strong style={{ margin: 5 }}>
          with
        </Typography.Text>
        <HeartTwoTone twoToneColor="#eb2f96" style={{ fontSize: 22 }} />
      </Row>
    </>
  );
};

export default LoginAuth;
