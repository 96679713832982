import * as React from "react";
import { Form, Button, message, Row, Col, Switch, Input, Divider } from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { queryClient } from "../../../../src";
import { ManageTestimonialModalContext } from "../Modal/ManageTestimonial";

import { useQuery } from "react-query";

import TextArea from "antd/lib/input/TextArea";

import moment from "moment";
import { getTestimonialById } from "../Requests";
import Upload, { RcFile, UploadFile, UploadProps } from "antd/es/upload";
import { InputRules } from "../../../utils/InputRules";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
const ManageTestimonialForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageTestimonialModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const manageTestimonialMutation = useMutation(
    (values: any) =>
      request("admin.testimonial.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("testimonial");
      },
    }
  );
  const { data: testimonial } = useQuery(
    ["testimonial", props.id],
    async () => getTestimonialById(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
    setFormLoading(true);

    manageTestimonialMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Testimonial has been successfully managed.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState("");
  const [previewTitle, setPreviewTitle] = React.useState("");
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };
  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={testimonial}
      form={form}
    >
      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Name"
            name="name"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Name",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Name",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Name" maxLength={20} />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="City"
            name="city"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "City",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter City",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter City" maxLength={15} />
          </Form.Item>
        </Col>
        {/* <Col flex={5} span={12}>
          <Form.Item
            label="Upload Image"
            style={{ fontWeight: "bold" }}
            name="imageUrl"
            valuePropName="checked"
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              accept="image/png, image/jpeg"
              action={async (file) => {
                const response: any = await uploadFileToAWS(
                  file,
                  "testimonial"
                );
                setGallery(response.key);
                return response.key;
              }}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
        </Col> */}
      </Row>

      <Form.Item
        label="Feedback"
        name="feedback"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Feedback",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Your Feedback",
          },
          { whitespace: true },
          { min: 3 },
          ...InputRules.text,
        ]}
        hasFeedback
      >
        <TextArea placeholder="Enter Your Feedback" maxLength={50} />
      </Form.Item>
      <Divider />

      <h3>For SEO Purpose</h3>
      <Divider />
      <Row gutter={15}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Meta Title"
            name="metaTitle"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Meta Title",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Meta Title",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Meta Title" maxLength={25} />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Meta Keyword"
            name="metaKeyword"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Meta Keyword",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Meta Keyword",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Meta Keyword" maxLength={25} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Meta Discription"
        name="metaDiscription"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Meta Discription",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Meta Discription",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <TextArea placeholder="Enter Meta Discription" maxLength={500} />
      </Form.Item>
      <Row gutter={20}>
        <Col flex={2} span={12}>
          <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
            rules={[{ required: true }]}
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageTestimonialForm;
