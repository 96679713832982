import { request } from "../../../utils/services";

export const getAllActiveEnquiry = async () => {
  const { data } = await request("admin.enquiry.list.active");
  return data;
};

export const getAllEnquiry = async () => {
  const { data } = await request("admin.enquiry.list");
  return data;
};

export const getEnquiryById = async (id: string) => {
  const { data } = await request("admin.enquiry.id", { id });
  return data;
};
