import * as React from "react";
import { Form, Button, message, Select, Row, Switch, Col, Input } from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import { InfoCircleOutlined } from "@ant-design/icons";
import { queryClient } from "../../../../src";
import { ManageRoomModalContext } from "../Modal/ManageRoom";

import { useQuery } from "react-query";

import { getRoomById } from "../Requests";

import { getAllFacility } from "../../Facility/Requests";
import CreateFacilityModal from "../../Facility/Modal/CreateFacility";
import { InputRules } from "../../../utils/InputRules";
import { getAllHostel } from "../../Hotel Detail/Requests";

const ManageRoomForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageRoomModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const manageRoomMutation = useMutation(
    (values: any) => request("admin.room.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Room");
      },
    }
  );
  const {
    isLoading,
    data: room,
    error,
    isError,
  } = useQuery(["room", props.id], async () => getRoomById(props.id), {
    refetchOnWindowFocus: false,
  });
  const { data: facility } = useQuery(
    ["facility"],
    async () => getAllFacility(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: hostel } = useQuery(["hostel"], async () => getAllHostel(), {
    refetchOnWindowFocus: false,
  });
  const onFinish = async (values: any) => {
    setFormLoading(true);

    manageRoomMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Room has been successfully managed.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={room}
    >
      <Form.Item
        label="Select Hostel"
        name="hostelId"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "hostelId",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Hostel ",
          },

          { whitespace: true },
        ]}
        hasFeedback
      >
        <Select
          showSearch
          placeholder="Select Hostel"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA!.children as unknown as string)
              .toLowerCase()
              .localeCompare(
                (optionB!.children as unknown as string).toLowerCase()
              )
          }
        >
          {hostel &&
            hostel?.map((item: any, i: number) => {
              return (
                <Select.Option value={item?.id} key={i}>
                  {item?.hostelName}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Row gutter={15}>
        <Col flex={2} span={12}>
          <Form.Item
            label="Room No"
            name="roomNo"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Room No",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Your Room No",
              },

              ...InputRules.number,
            ]}
            hasFeedback
          >
            <Input
              placeholder="Enter Room Number"
              maxLength={5}
              minLength={3}
            />
          </Form.Item>
        </Col>
        <Col flex={2} span={12}>
          <Form.Item
            label="Facility"
            style={{ fontWeight: "bold" }}
            name="facilityId"
            tooltip={{
              title: "Facility",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Select
              mode="multiple"
              showSearch
              placeholder="Select facility"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA!.children as unknown as string)
                  .toLowerCase()
                  .localeCompare(
                    (optionB!.children as unknown as string).toLowerCase()
                  )
              }
              dropdownRender={(menu) => (
                <>
                  <CreateFacilityModal />

                  {menu}
                </>
              )}
            >
              {facility &&
                facility?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.id} key={i}>
                      {item?.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Floor"
            name="floor"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "floor",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Floor",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Floor" maxLength={8} />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Size"
            name="size"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "size",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter size",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Size" maxLength={15} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Occupancy"
            name="occupancy"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Occupancy",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Occupancy",
              },

              ...InputRules.number,
            ]}
            hasFeedback
          >
            <Input
              type="Number"
              placeholder="Enter Occupany"
              maxLength={1}
              minLength={1}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Bathroom"
            style={{ fontWeight: "bold" }}
            name="bathroom"
            tooltip={{
              title: "bathroom",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Select bathroom"
              options={[
                { value: "Attached", label: "Attached" },
                { value: "Shared", label: "Shared" },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col flex={2} span={12}>
          <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
            // rules={[{ required: true }]}
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Update Room
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageRoomForm;
