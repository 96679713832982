import * as React from "react";

import { useMutation, useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import {
  Avatar,
  Button,
  Row,
  Segmented,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";

import { getAllRoom, getRoomByHostelId } from "../Requests";

import ManageRoomModal from "../Modal/ManageRoom";
import { s3Path } from "../../../utils/s3Config/s3upload";
import { getOccupantByHostelId } from "../../Occupant/Requests";
import { getAllHostel } from "../../Hotel Detail/Requests";
const { Text } = Typography;
interface DataType {
  id: number;
  name: any;
}

const RoomList: React.FC = () => {
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["room"], () => getAllRoom(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  const [roomData, setRoomData] = React.useState<any>(response);

  const { data: hostel } = useQuery(["hostel"], () => getAllHostel(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  const mutateHostel = useMutation((id: string) => getRoomByHostelId(id));
  const OnHostelSelect = (i: any) => {
    // console.log(i);

    // setSelectedHostelId(i);
    //  setIsOccupantSelected(true);
    i === "All"
      ? setRoomData(response)
      : mutateHostel
          .mutateAsync(i)
          .then((payload) => {
            setRoomData(payload);
          })
          .catch((e) => {
            console.log(e, "error");
          });
  };
  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      width: "10%",
      title: "Room No",
      dataIndex: "roomNo",
      key: "roomNo",
      align: "center",
    },
    {
      title: "Room Detail",
      dataIndex: "floor",
      key: "floor",
      align: "center",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Text code style={{ fontStyle: "italic" }} type="secondary">
              Floor:{i?.floor}
            </Text>
            <Text code style={{ fontStyle: "italic" }} type="secondary">
              Size:{i?.size}
            </Text>
            <Text code style={{ fontStyle: "italic" }} type="secondary">
              Bathroom:{i?.bathroom}
            </Text>
          </Space.Compact>
        </Space>
      ),
    },
    {
      title: "Occupancy",
      dataIndex: "occupancy",
      key: "occupancy",
      align: "center",
      render: (_: any, id: any) => (
        <>
          <Text code> Occupancy:{id.occupancy}</Text>
          <Text code> Available:{id.availability}</Text>
        </>
      ),
    },

    {
      title: "Facility",
      dataIndex: "facilityId",
      key: "facilityId",
      align: "center",
      render: (_: any, id: any) => (
        <>
          {id?.facilityId &&
            id?.facilityId?.map((item: any, i: number) => {
              return (
                <>
                  <Avatar src={<img src={s3Path + item.icon} alt={"R"} />} />
                </>
              );
            })}
        </>
      ),
    },

    {
      width: "15%",
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      align: "center",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      width: "15%",
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (id: any) => (
        <Space size={10}>
          <ManageRoomModal id={id} />
        </Space>
      ),
    },
  ];

  return (
    <>
      <Segmented
        onChange={OnHostelSelect}
        options={[
          {
            label: "All",
            value: "All",
          },
          ...(hostel &&
            hostel?.map((item: any, i: number) => {
              return {
                label:
                  // <div style={{ padding: 4 }}>
                  //   {/* <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=8" /> */}
                  //   <div>User 1</div>
                  // </div>
                  item.hostelName,
                value: item.id,
              };
            })),
        ]}
      />

      <Table
        size="small"
        columns={column}
        dataSource={roomData}
        scroll={{ y: "68vh" }}
      />
    </>
  );
};

export default RoomList;
