import { path } from "./path";

export const blogCategoriesRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveBlogCategories",
    module: "blogCategories",
    alias: "admin.blogCategories.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateBlogCategories/:id",
    module: "blogCategories",
    alias: "admin.blogCategories.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveBlogCategories",
    module: "blogCategories",
    alias: "admin.blogCategories.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllBlogCategories",
    module: "blogCategories",
    alias: "admin.blogCategories.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneBlogCategories/:id",
    module: "blogCategories",
    alias: "admin.blogCategories.id",
    isSubDomain: true,
  },
];
