import * as React from "react";
import { Descriptions, Card, Divider } from "antd";
import { useQuery } from "react-query";
import { getTestimonialById } from "../Requests";

const ShowTestimonialDetailsForm: React.FC<{ id: string }> = (props) => {
  const { data: testimonial } = useQuery(
    ["testimonial", props.id],
    async () => getTestimonialById(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );

  return (
    <Card>
      <Divider orientation="left">Testimonial Info</Divider>

      <Descriptions>
        <Descriptions.Item label="Name">{testimonial.name}</Descriptions.Item>
        <Descriptions.Item label="City">{testimonial.city}</Descriptions.Item>
        <Descriptions.Item label="Description">
          {testimonial.discription}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">SEO Detail</Divider>
      <Descriptions>
        <Descriptions.Item label="Meta Title">
          {testimonial.metaTitle}
        </Descriptions.Item>
        <Descriptions.Item label="Meta Keyword">
          {testimonial.metaKeyword}
        </Descriptions.Item>
        <Descriptions.Item label="Meta Desciption">
          {testimonial.metaDiscription}
        </Descriptions.Item>
      </Descriptions>
    </Card>
    // </Card>
  );
};

export default ShowTestimonialDetailsForm;
