import * as React from "react";
import { Form, Button, message, Row, Col, Switch, Input, Select } from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  TagOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";

import { useQuery } from "react-query";

import { getPaymentTermsById } from "../Requests";

import { ManagePaymentTermsModalContext } from "../Modal/ManagePaymentTerms";
import { InputRules } from "../../../utils/InputRules";

const ManagePaymentTermsForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManagePaymentTermsModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const managePaymentTermsMutation = useMutation(
    (values: any) =>
      request("admin.paymentTerms.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("PaymentTerms");
      },
    }
  );
  const {
    isLoading,
    data: paymentTerms,
    error,
    isError,
  } = useQuery(
    ["paymentTerms", props.id],
    async () => getPaymentTermsById(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
    setFormLoading(true);

    managePaymentTermsMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The PaymentTerms has been successfully managed.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={paymentTerms}
      form={form}
    >
      <Form.Item
        label="Title"
        name="title"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Title",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Title",
          },
          { whitespace: true },

          ...InputRules.text,
        ]}
        hasFeedback
      >
        <Input placeholder="Enter Title" maxLength={20} />
      </Form.Item>
      <Form.Item
        label="Price"
        name="price"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Price",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Price",
          },
          { whitespace: true },
          { min: 3 },
          ...InputRules.number,
        ]}
        hasFeedback
      >
        <Input placeholder="Enter Price" maxLength={8} />
      </Form.Item>
      <Form.Item
        label="Frequency"
        name="frequency"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Frequency",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select frequency",
          },
          { whitespace: true },
        ]}
        hasFeedback
      >
        <Select
          placeholder="Select Frequency"
          options={[
            { value: "Day", label: "Day" },
            { value: "Week", label: "Week" },
            { value: "Month", label: "Month" },
            { value: "Half-Year", label: "Half-Year" },
            { value: "Year", label: "Year" },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="Food"
        name="food"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "food",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select food",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <Select
          placeholder="Select food"
          options={[
            { value: "With Food", label: "With Food" },
            { value: "Without Food", label: "Without Food" },
          ]}
        />
      </Form.Item>

      <h3>Description</h3>
      <Form.List name="description">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field: any) => (
              <Row
                gutter={16}
                key={field.key}
                justify="space-between"
                align="top"
              >
                <Col span={23}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        // name={[field.name, "title"]}
                        rules={[
                          {
                            required: true,
                            message: "description Required",
                          },
                        ]}
                      >
                        <Input
                          addonBefore={
                            <TagOutlined className="site-form-item-icon" />
                          }
                          placeholder="Enter description"
                          maxLength={30}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>

                <DeleteOutlined onClick={() => remove(field.name)} />
              </Row>
            ))}

            <Form.Item>
              <Button onClick={() => add()} block icon={<PlusOutlined />}>
                Add Description
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Row gutter={20}>
        <Col flex={2} span={12}>
          <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
            // rules={[{ required: true }]}
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Update Payment Terms
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManagePaymentTermsForm;
