import { path } from "./path";

export const dailyMenuRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createDailyMenu",
    module: "dailyMenu",
    alias: "admin.dailyMenu.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateDailyMenu/:id",
    module: "dailyMenu",
    alias: "admin.dailyMenu.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllDailyMenu",
    module: "dailyMenu",
    alias: "admin.dailyMenu.list",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllActiveDailyMenu",
    module: "dailyMenu",
    alias: "admin.dailyMenu.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneDailyMenu/:id",
    module: "dailyMenu",
    alias: "admin.dailyMenu.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneDailyMenuDetails/:id",
    module: "dailyMenu",
    alias: "admin.dailyMenu.id.detail",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findDailyMenuByHostelId/:id",
    module: "dailyMenu",
    alias: "admin.dailyMenu.hostelId",
    isSubDomain: true,
  },
];
