import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Input,
  Col,
  Switch,
  Select,
  DatePicker,
  Card,
  Typography,
  Tag,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  TagOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";

import LocalStorageService from "../../../utils/local-storage";
import { CreateBillModalContext } from "../Modal/CreateBill";
import {
  getAllOccupant,
  getOccupantByHostelId,
  getOccupantById,
} from "../../Occupant/Requests";
import BillListByOccupant from "../Component/ListByOccupant";
import { log } from "console";
import CreateOccupantModal from "../../Occupant/Modal/CreateOccupant";
import { InputRules } from "../../../utils/InputRules";
import { getAllHostel } from "../../Hotel Detail/Requests";
const { Text, Title } = Typography;
const CreateBill: React.FC<{ id?: string ,hostelId?:string }> = (props) => {
  const modalContext = React.useContext(CreateBillModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [isOccupantSelected, setIsOccupantSelected] = React.useState(false);
  const [occupantData, setOccupantData] = React.useState<Object | any>({});
  const [tenant, setTenant] = React.useState([]);
  const [selectedOccupantId, setSelectedOccupantId] = React.useState(undefined);
  const createBillMutation = useMutation(
    (values: any) => request("admin.bill.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("bill");
      },
    }
  );
  const { data: occupant } = useQuery(
    ["occupant"],
    async () => getAllOccupant(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: hostel } = useQuery(["hostel"], async () => getAllHostel(), {
    refetchOnWindowFocus: false,
  });
  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;

    setFormLoading(true);

    createBillMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Bill has been successfully created.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const mutateTenant = useMutation((id: string) => getOccupantByHostelId(id));

  const getOccupant = async (id: string) => {
    console.log(id, "parameters");

    mutateTenant
      .mutateAsync(id)
      .then((payload) => {
        setTenant(payload);
      })
      .catch((e: any) => {
        console.log("error", e);
      });
  };
  // const[totalAmount, setTotalAmount] = React.use
  const getTotal = () => {
    let tempTotalAmount = 0;
    const item = form.getFieldsValue().item;
    item.forEach((ele: any) => {
      tempTotalAmount += parseInt(ele.amount);
    });
    form.setFieldsValue({ amount: tempTotalAmount });
  };

  const mutateOccupant = useMutation((id: string) => getOccupantById(id));
  const OnOccupantSelect = (i: any) => {
    // console.log(i);

    setSelectedOccupantId(i);
    setIsOccupantSelected(true);

    mutateOccupant
      .mutateAsync(i)
      .then((payload) => {
        setOccupantData(payload);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  React.useEffect(() => {
    if (props.id) {
      form.setFieldsValue({ occupantId: props.id });
      form.setFieldsValue({ hostelId: props.hostelId });
      OnOccupantSelect(props.id);
    }
  }, []);
  const [form] = Form.useForm();
  console.log(form.getFieldsValue());
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Form.Item
        label="Select Hostel"
        name="hostelId"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "hostelId",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Hostel ",
          },

          { whitespace: true },
        ]}
        hasFeedback
      >
        <Select
          onSelect={getOccupant}
          showSearch
          placeholder="Select Hostel"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA!.children as unknown as string)
              .toLowerCase()
              .localeCompare(
                (optionB!.children as unknown as string).toLowerCase()
              )
          }
          disabled={props.hostelId?true:false}
        >

          {hostel &&
            hostel?.map((item: any, i: number) => {
              return (
                <Select.Option value={item?.id} key={i}>
                  {item?.hostelName}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Occupant Name"
        style={{ fontWeight: "bold" }}
        name="occupantId"
        tooltip={{
          title: "Occupant Name",
          icon: <InfoCircleOutlined />,
        }}
      >
        {isOccupantSelected ? (
          <Card>
            <Row justify={"space-between"} align={"middle"}>
              <Title level={3}>{occupantData?.name}</Title>

              <Col>
                <Row>
                  <Text type="success" code>
                    Phone No:{occupantData?.phoneNo}
                  </Text>
                </Row>
                <Row>
                  <Text type="warning" code>
                    Address:{occupantData?.address}
                  </Text>
                </Row>
                <Row>
                  <Text type="danger" code>
                    {" "}
                    Parent Name:{occupantData?.parentName}
                  </Text>
                </Row>
              </Col>
              {!props.id && (
                <Button
                  onClick={() => setIsOccupantSelected(false)}
                  type="dashed"
                >
                  Change Occupant
                </Button>
              )}
            </Row>
          </Card>
        ) : (
          <Select
            onSelect={OnOccupantSelect}
            showSearch
            popupMatchSelectWidth
            // style={{ minHeight: 150 }}
            placeholder="Select Occupant"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input)
            }
          >
            {tenant &&
              tenant?.map((item: any, i: number) => {
                return (
                  <Select.Option value={item?._id} key={i}>
                    <Row justify={"space-between"} align={"middle"}>
                      <Title level={5}>{item?.name}</Title>

                      <Col flex={3} span={6}>
                        <Row>
                          <Text type="success" code>
                            Phone No:{item?.phoneNo}
                          </Text>
                        </Row>
                        <Row>
                          <Text type="warning" code>
                            Address:{item?.address}
                          </Text>
                        </Row>
                        <Row>
                          <Text type="danger" code>
                            {" "}
                            Parent Name:{item?.parentName}
                          </Text>
                        </Row>
                      </Col>
                    </Row>
                    <br />
                  </Select.Option>
                );
              })}
          </Select>
        )}
      </Form.Item>
      
      {/* <Form.Item
        label="Fee Type"
        style={{ fontWeight: "bold" }}
        name="item"
        tooltip={{
          title: "Fee Type",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true, message: "Please Select Your Fee Type" }]}
      >
        <Select
          mode="multiple"
          placeholder="Select Fee Type"
          options={[
            { value: "Security Deposit", label: "Security Deposit" },
            { value: "Registration Fee", label: "Registration Fee" },
            { value: "Rental Charge", label: "Rental Charge" },
          ]}
        />
      </Form.Item> */}
      <h3>Fee Type</h3>
      <Form.List name="item">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row gutter={16} key={key} justify="space-between" align="top">
                <Col span={23}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Row gutter={22}>
                        <Col span={11}>
                          <Form.Item
                            {...restField}
                            name={[name, "particular"]}
                            rules={[
                              {
                                required: true,
                                message: "particular Required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter Fee Type"
                              maxLength={100}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={11}>
                          <Form.Item
                            {...restField}
                            name={[name, "amount"]}
                            rules={[
                              {
                                required: true,
                                message: "amount Required",
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              onChange={getTotal}
                              placeholder="Enter Amount"
                              maxLength={100}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </Form.Item>
                </Col>

                <DeleteOutlined
                  onClick={() => {
                    remove(name);
                    getTotal();
                  }}
                />
              </Row>
            ))}

            <Form.Item>
              <Button onClick={() => add()} block icon={<PlusOutlined />}>
                Add Fee Type
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Row gutter={20}>
        <Col flex={2} span={8}>
          <Form.Item
            label="From Date"
            name="fromDate"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "From Date",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <DatePicker format={"DD-MMM-YYYY"} />
          </Form.Item>
        </Col>
        <Col flex={2} span={8}>
          <Form.Item
            label="To Date"
            name="toDate"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "To Date",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <DatePicker format={"DD-MMM-YYYY"} />
          </Form.Item>
        </Col>
        <Col flex={2} span={8}>
          <Form.Item
            label="Due Date"
            name="dueDate"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "dueDate",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <DatePicker format={"DD-MMM-YYYY"} />
          </Form.Item>
        </Col>
        <Col flex={2} span={8}>
          <Form.Item
            label="Next Bill Date"
            name="nextBillDate"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Next Bill Date",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <DatePicker format={"DD-MMM-YYYY"} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Total Amount"
        name="amount"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Amount",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Amount",
          },

          { min: 3 },
          ...InputRules.number,
        ]}
        hasFeedback
      >
        <Input placeholder="Enter Amount" maxLength={8} />
      </Form.Item>
      <Form.Item
        label="Remarks"
        name="remarks"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Remark",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Remarks",
          },
          { whitespace: true },
          { min: 3 },
          ...InputRules.text,
        ]}
        hasFeedback
      >
        <Input placeholder="Enter Remarks" maxLength={20} />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
      {selectedOccupantId && <BillListByOccupant id={selectedOccupantId} />}
    </Form>
  );
};

export default CreateBill;
