import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Space, Table, Tag } from "antd";

import { getAllEnquiry } from "../Requests/index";

import ManageEnquiryModal from "../Modal/ManageEnquiry";

interface DataType {
  id: number;
  name: any;
}

const EnquiryList: React.FC = () => {
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["enquiry"], () => getAllEnquiry(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mobile No",
      dataIndex: "mobileNo",
      key: "mobileNo",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Enquiry For?",
      dataIndex: "help",
      key: "help",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    // {
    //   title: "Action",
    //   dataIndex: "id",
    //   key: "id",
    //   render: (id: any) => (
    //     <Space size={10}>
    //       <ManageEnquiryModal id={id} />
    //     </Space>
    //   ),
    // },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default EnquiryList;
