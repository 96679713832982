import { TRoute } from "../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const HostelRouter: TRoute[] = [
  {
    path: "/hostel",
    name: "hostel",
    title: "hostel",
    component: DepartmentPageHome,
  },
];

export default HostelRouter;
