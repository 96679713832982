import * as React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Switch,
  Select,
  Col,
  Row,
  Upload,
  Steps,
  Card,
  Typography,
  Tag,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  MobileOutlined,
  UserOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";
import { CreateOccupantModalContext } from "../Modal/CreateOccupant";
import LocalStorageService from "../../../utils/local-storage";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { uploadFileToAWS } from "../../../utils/s3Config/s3upload";
import TextArea from "antd/es/input/TextArea";

import { getAllRoom, getRoomById } from "../../Room/Requests";
import {
  getAllPaymentTerms,
  getPaymentTermsById,
} from "../../PaymentTerms/Requests";
import CreatePaymentTermsModal from "../../PaymentTerms/Modal/CreatePaymentTerms";
import { InputRules } from "../../../utils/InputRules";
import { getAllHostel } from "../../Hotel Detail/Requests";

const { Text, Title } = Typography;
const CreateOccupantForm: React.FC = () => {
  const modalContext = React.useContext(CreateOccupantModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [current, setCurrent] = React.useState(0);
  const [galley, setGallery] = React.useState<string>();

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const [occupantId, setOccupantId] = React.useState<string | any>(undefined);
  const [form] = Form.useForm();
  const createOccupantMutation = useMutation(
    (values: any) =>
      occupantId
        ? request("admin.occupant.update", { id: occupantId }, values)
        : request("admin.occupant.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("occupant");
      },
    }
  );

  const { data: hostel } = useQuery(["hostel"], async () => getAllHostel(), {
    refetchOnWindowFocus: false,
  });

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const onFinish = async (values: any) => {
    setFormLoading(true);
    values.createdBy = LocalStorageService.getAccount().id;
    console.log(values);
    // const response = await uploadFileToAWS(selectedFile, "Occupant");
    values.imageUrl = galley;
    values.availability = roomData.availability - 1;
    createOccupantMutation
      .mutateAsync(values)
      .then((payload: any) => {
        setOccupantId(payload?.data.id);

        setFormLoading(false);
        message.success(payload.message);

        setCurrent(current + 1);

        if (values.roomId) {
          modalContext.setIsModalVisible(false);
          form.resetFields();
          setImageUrl(undefined);
          setCurrent(0);
        }
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e.message);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const items = [
    {
      key: "Tenant Details",
      title: "Tenant Details",
    },
    {
      key: "Parent Details",
      title: "Parent Details",
    },
    {
      key: "Assign Room",
      title: "Assign Room",
    },
  ];

  const [isPaymentTermsSelected, setIsPaymentTermsSelected] =
    React.useState(false);
  const [paymentTermsData, setPaymentTermsData] = React.useState<Object | any>(
    {}
  );
  const [isRoomSelected, setIsRoomSelected] = React.useState(false);
  const [roomData, setRoomData] = React.useState<Object | any>({});

  const { data: room } = useQuery(["room"], async () => getAllRoom(), {
    refetchOnWindowFocus: false,
  });
  const { data: paymentTerms } = useQuery(
    ["paymentTerms"],
    async () => getAllPaymentTerms(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const mutateRoom = useMutation((id: string) => getRoomById(id));
  const OnRoomSelect = (i: any) => {
    setIsRoomSelected(true);

    mutateRoom
      .mutateAsync(i)
      .then((payload) => {
        setRoomData(payload);
        form.setFieldsValue({ availability: payload.availability - 1 });
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  const mutatePayment = useMutation((id: string) => getPaymentTermsById(id));
  const OnPaymentTermsSelect = (i: any) => {
    setIsPaymentTermsSelected(true);
    mutatePayment
      .mutateAsync(i)
      .then((payload) => {
        setPaymentTermsData(payload);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Steps type="navigation" size="small" current={current} items={items} />
      <div style={{ marginTop: 24 }}></div>
      {current === 0 && (
        <>
          {" "}
          <Row gutter={20} align={"bottom"}>
            <Col flex={3} span={12}>
              <Form.Item
                label="Tenant Photo"
                style={{ fontWeight: "bold" }}
                name="imageUrl"
                valuePropName="checked"
              >
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  accept="image/png, image/jpeg"
                  action={async (file) => {
                    const response: any = await uploadFileToAWS(
                      file,
                      "Occupant"
                    );
                    setGallery(response.key);
                    return response.key;
                  }}
                  onChange={handleChange}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            </Col>
            <Col flex={3} span={12}>
              <Form.Item
                label="Select Hostel"
                name="hostelId"
                style={{ fontWeight: "bold" }}
                tooltip={{
                  title: "hostelId",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: true,
                    message: "Please Select Hostel ",
                  },

                  { whitespace: true },
                ]}
                hasFeedback
              >
                <Select
                  showSearch
                  placeholder="Select Hostel"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.children as unknown as string).includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA!.children as unknown as string)
                      .toLowerCase()
                      .localeCompare(
                        (optionB!.children as unknown as string).toLowerCase()
                      )
                  }
                >
                  {hostel &&
                    hostel?.map((item: any, i: number) => {
                      return (
                        <Select.Option value={item?.id} key={i}>
                          {item?.hostelName}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col flex={3} span={12}>
              <Form.Item
                label="Tenant Name"
                name="name"
                style={{ fontWeight: "bold" }}
                tooltip={{
                  title: "Occupant Name",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Your Name",
                  },

                  { whitespace: true },
                  ...InputRules.text,
                ]}
                hasFeedback
              >
                <Input
                  type="alphabet"
                  addonBefore={<UserOutlined />}
                  placeholder="Enter Name"
                  maxLength={20}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col flex={3} span={12}>
              <Form.Item
                label="Phone No"
                name="phoneNo"
                style={{ fontWeight: "bold" }}
                tooltip={{
                  title: "Official Phone no",
                  icon: <InfoCircleOutlined />,
                }}
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input Your Phone number!",
                //   },
                //   ...InputRules.phoneNo,
                // {
                //   pattern: new RegExp("([0-9])"),
                //   message: "field accept only number",
                // },
                // {
                //   min: 10,
                //   message: "Enter a Valid Number+",
                // },
                // ]}
                rules={[{ required: true }, { pattern: /^[0-9]{10}$/ }]}
              >
                <Input
                  placeholder="Enter Your Mobile Number"
                  addonBefore="+91-"
                  addonAfter={<MobileOutlined />}
                  minLength={10}
                  maxLength={10}
                />
              </Form.Item>
            </Col>
            <Col flex={3} span={12}>
              <Form.Item
                label="Age"
                style={{ fontWeight: "bold" }}
                name="age"
                tooltip={{
                  title: "Age",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  { required: true, message: "Please Enter Your Age" },
                  {
                    message: "Value should 2 character",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Your Age"
                  type="number"
                  min={10}
                  max={99}
                  maxLength={2}
                />
              </Form.Item>
            </Col>
            <Col flex={3} span={12}>
              <Form.Item
                label="Gender"
                style={{ fontWeight: "bold" }}
                name="gender"
                tooltip={{
                  title: "Gender",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  { required: true, message: "Please Select Your Gender" },
                ]}
              >
                <Select
                  placeholder="Select Gender"
                  options={[
                    { value: "Male", label: "Male" },
                    { value: "Female", label: "Female" },
                    { value: "Other", label: "Other" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col flex={3} span={12}>
              <Form.Item
                label="Adhaar No."
                style={{ fontWeight: "bold" }}
                name="adharNo"
                tooltip={{
                  title: "Adhar No",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  { required: true, message: "Please Enter your Adhar number" },
                  {
                    min: 12,
                    message: "Value should be 12 Character ",
                  },
                  ...InputRules.number,
                ]}
              >
                <Input placeholder="Enter Your Adhar Number" maxLength={12} />
              </Form.Item>
            </Col>
            <Col flex={3} span={12}>
              <Form.Item
                label="Occupation"
                style={{ fontWeight: "bold" }}
                name="occupation"
                tooltip={{
                  title: "Occupation",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  { required: true, message: "Please Select Your Occupation" },
                  ...InputRules.text,
                ]}
              >
                <Select
                  placeholder="Select Occupation"
                  options={[
                    { value: "Job", label: "Job" },
                    { value: "Student", label: "Student" },
                    { value: "Other", label: "Other" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col flex={3} span={12}>
              <Form.Item
                label="WorkPlace/College/School"
                style={{ fontWeight: "bold" }}
                name="workPlace"
                tooltip={{
                  title: "WorkPlace",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: true,
                    message: "Please enter your work Place",
                  },
                  ...InputRules.text,
                ]}
                hasFeedback
              >
                <Input placeholder="Enter Your WorkPlace" maxLength={30} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col flex={3} span={12}>
              <Form.Item
                label="Address"
                style={{ fontWeight: "bold" }}
                name="address"
                tooltip={{
                  title: "Address",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }, ...InputRules.text]}
              >
                <TextArea
                  placeholder="Enter Your Permanent Address"
                  maxLength={50}
                />
              </Form.Item>
            </Col>
            <Col flex={3} span={12}>
              <Form.Item
                label="Remarks"
                style={{ fontWeight: "bold" }}
                name="remarks"
                tooltip={{
                  title: "remarks",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[...InputRules.text]}
              >
                <TextArea placeholder="Enter Rmarks" maxLength={50} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col flex={3} span={12}>
              <Form.Item
                label="Reference"
                style={{ fontWeight: "bold" }}
                name="reference"
                tooltip={{
                  title: "Reference",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  { required: true, message: "Please Enter Your Reference" },
                  ...InputRules.text,
                ]}
              >
                <Input placeholder="Enter Reference" maxLength={20} />
              </Form.Item>
            </Col>

            <Col flex={1} span={8}>
              <Form.Item
                label="Status"
                style={{ fontWeight: "bold" }}
                name="isActive"
                tooltip={{
                  title: "status",
                  icon: <InfoCircleOutlined />,
                }}
                valuePropName="checked"
                // rules={[{ required: true }]}
              >
                <Switch
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  defaultChecked
                />
              </Form.Item>
            </Col>
          </Row>{" "}
        </>
      )}

      {current === 1 && (
        <>
          <Row gutter={20}>
            <Col flex={2} span={8}>
              <Form.Item
                label="Parent Name"
                style={{ fontWeight: "bold" }}
                name="parentName"
                tooltip={{
                  title: "Parent Name",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  { required: true, message: "Please Enter Your Parent Name" },
                  ...InputRules.text,
                ]}
              >
                <Input
                  addonBefore={<UserOutlined />}
                  placeholder="Enter Your Parent Name"
                  maxLength={20}
                />
              </Form.Item>
            </Col>
            <Col flex={2} span={8}>
              <Form.Item
                label="Phone No"
                style={{ fontWeight: "bold" }}
                name="pmobNo"
                tooltip={{
                  title: "Phone no",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[{ required: true }, { pattern: /^[0-9]{10}$/ }]}
              >
                <Input
                  placeholder="Enter Your Parent Mobile Number"
                  addonBefore="+91"
                  maxLength={10}
                  minLength={10}
                  addonAfter={<MobileOutlined />}
                />
              </Form.Item>
            </Col>

            <Col flex={2} span={8}>
              <Form.Item
                label="Relation"
                style={{ fontWeight: "bold" }}
                name="relation"
                tooltip={{
                  title: "relation",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  { required: true, message: " Please Enter Relation" },
                  ...InputRules.text,
                ]}
              >
                <Input placeholder="Enter Relation" maxLength={15} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col flex={2} span={8}>
              <Form.Item
                label="Address"
                style={{ fontWeight: "bold" }}
                name="paddress"
                tooltip={{
                  title: "Parent Address",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: true,
                    message: "Please Enter Your Parent Address",
                  },
                  ...InputRules.text,
                ]}
              >
                <TextArea
                  placeholder="Enter Your Parent Address"
                  maxLength={50}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      {current === 2 && (
        <>
          <Form.Item
            label="Room No"
            style={{ fontWeight: "bold" }}
            name="roomId"
            tooltip={{
              title: "Room no",
              icon: <InfoCircleOutlined />,
            }}
          >
            {isRoomSelected ? (
              <Card>
                <Row justify={"space-between"} align={"middle"}>
                  <Title level={3}>{roomData?.roomNo}</Title>
                  <Col>
                    <Row>
                      {roomData?.facilityId &&
                        roomData?.facilityId?.map((item: any, i: number) => {
                          return (
                            <Tag color="red" style={{ margin: 2 }}>
                              {item.title}
                            </Tag>
                          );
                        })}
                    </Row>
                    <Row>
                      <Text code> Occupancy:{roomData.occupancy}</Text>
                      <Text code> Available:{roomData.availability}</Text>
                    </Row>
                  </Col>
                  <Button
                    onClick={() => setIsRoomSelected(false)}
                    type="dashed"
                  >
                    Change Room
                  </Button>
                </Row>
              </Card>
            ) : (
              <Select
                onSelect={OnRoomSelect}
                showSearch
                popupMatchSelectWidth
                // style={{ minHeight: 150 }}
                placeholder="Select Room No"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string).includes(input)
                }
              >
                {room &&
                  room?.map((item: any, i: number) => {
                    return (
                      <Select.Option value={item?.id} key={i}>
                        <Row justify={"space-between"} align={"middle"}>
                          <h4>{item?.roomNo}</h4>
                          <Col>
                            <Row>
                              {item?.facilityId &&
                                item?.facilityId?.map(
                                  (item: any, i: number) => {
                                    return (
                                      <Tag color="red" style={{ margin: 2 }}>
                                        {item.title}
                                      </Tag>
                                    );
                                  }
                                )}
                            </Row>
                            <Row>
                              <Text code> Occupancy:{item.occupancy}</Text>
                              <Text code> Available:{item.availability}</Text>
                            </Row>
                          </Col>
                        </Row>
                      </Select.Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
          {/* </Col>
        <Col flex={2} span={12}> */}
          <Form.Item
            label="Payment Terms"
            style={{ fontWeight: "bold" }}
            name="paymentTermsId"
            tooltip={{
              title: "Payment Terms",
              icon: <InfoCircleOutlined />,
            }}
          >
            {isPaymentTermsSelected ? (
              <Card>
                <Row justify={"space-between"} align={"middle"}>
                  <Title level={3}>{paymentTermsData?.price}</Title>
                  <Col>
                    <Row>
                      <Text type="success" code>
                        Frequency:{paymentTermsData.frequency}
                      </Text>
                      <Text type="danger" code>
                        Food:{paymentTermsData.food}
                      </Text>
                    </Row>
                  </Col>
                  <Button
                    onClick={() => setIsPaymentTermsSelected(false)}
                    type="dashed"
                  >
                    Change Payment Terms
                  </Button>
                </Row>
              </Card>
            ) : (
              <Select
                onSelect={OnPaymentTermsSelect}
                showSearch
                popupMatchSelectWidth
                // style={{ minHeight: 150 }}
                placeholder="Select Payment Terms"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string).includes(input)
                }
                dropdownRender={(menu) => (
                  <>
                    <CreatePaymentTermsModal />

                    {menu}
                  </>
                )}
              >
                {paymentTerms &&
                  paymentTerms?.map((item: any, i: number) => {
                    return (
                      <Select.Option value={item?.id} key={i}>
                        <Row justify={"space-between"} align={"middle"}>
                          <h5>{item?.price}</h5>
                          <Col>
                            <Text type="success" code>
                              Frequency:{item.frequency}
                            </Text>
                            <Text type="danger" code>
                              Food:{item.food}
                            </Text>
                          </Col>
                        </Row>
                      </Select.Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </>
      )}

      {current > 0 && (
        <Button
          style={{ margin: "0 8px" }}
          onClick={() => setCurrent(current - 1)}
        >
          Previous
        </Button>
      )}
      <Form.Item style={{ textAlign: "center" }}>
        {current < items.length - 1 && (
          <>
            <Button
              loading={formLoading}
              type="primary"
              htmlType="submit"
              // onClick={() => next()}
            >
              Next
            </Button>
          </>
        )}

        {current === items.length - 1 && (
          <>
            <Button
              //  size="large"
              loading={formLoading}
              type="primary"
              htmlType="submit"
              //shape="round"
            >
              Submit
            </Button>
          </>
        )}
      </Form.Item>
    </Form>
  );
};

export default CreateOccupantForm;
