import { path } from "./path";

export const expenseRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveExpense",
    module: "expense",
    alias: "admin.expense.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateExpense/:id",
    module: "party",
    alias: "admin.expense.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllExpense",
    module: "party",
    alias: "admin.expense.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneExpense/:id",
    module: "party",
    alias: "admin.expense.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllActiveExpense",
    module: "party",
    alias: "admin.active.expense",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findExpenseByPartyId/:id",
    module: "party",
    alias: "admin.expense.partyId",
    isSubDomain: true,
  },
];
