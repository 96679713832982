import { Result, Tag, Image, Empty, Row } from "antd";

import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

// import OccupantManageModal from "../../Modal/ManageOccupant";

import { Button, Space, Table, Typography } from "antd";

import { getLedgerByOccupant } from "../../Payment/Requests";
import moment from "moment";

const { Text, Title } = Typography;
interface DataType {
  id: number;
  name: string;
  person: string;
  phone: number;
  status: any;
}
type DataIndex = keyof DataType;
const LedgerList: React.FC<{ id: string }> = (props) => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const [result, setResult] = React.useState(0);

  const {
    isLoading,
    isError,
    data: ledger,
  } = useQuery(["ledger", props.id], () => getLedgerByOccupant(props.id), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }

  const getResult = (values: any) => {
    var debit = 0;
    var credit = 0;
    for (var a = 0; a < values.length; a++) {
      if (values[a].type === "Debit") {
        debit = debit + values[a].amount;
      } else {
        credit = credit + values[a].amount;
      }
    }
    var result = credit - debit;

    // setResult(result);

    return result;
  };
  const column: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (id) => moment(id).format("DD-MMM-YYYY"),
    },
    // {
    //   title: "Item Type",
    //   dataIndex: "item",
    //   key: "item",
    //   render: (_, i: any) => (
    //     <>
    //       {i.billId &&
    //         i.billId.item.map((element: any) => (
    //           <Row>
    //             <Text code type="warning">
    //               {element}
    //             </Text>
    //           </Row>
    //         ))}
    //       {i.paymentId && (
    //         <Space>
    //           <Text>-</Text>
    //           {/* <Text type="success"> {i.paymentId.remarks}</Text> */}
    //         </Space>
    //       )}
    //     </>
    //   ),
    // },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_, i: any) => (
        <>
          {i?.billId && (
            <Space>
              <Text code>
                {" "}
                {moment(i?.billId.fromDate).format("DD-MMM-YY") +
                  " to " +
                  moment(i?.billId.toDate).format("DD-MMM-YY")}
              </Text>
              {/* <Text type="success"> {i.billId.remarks}</Text> */}
            </Space>
          )}
          {i.paymentId && (
            <Space>
              <Text code>Payment In Cash</Text>
              {/* <Text type="success"> {i.paymentId.remarks}</Text> */}
            </Space>
          )}
        </>
      ),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (_, i: any) => (
        <>
          {i.billId && (
            <Space>
              <Text type="success"> {i.billId.remarks}</Text>
            </Space>
          )}
          {i.paymentId && (
            <Space>
              <Text type="success"> {i.paymentId.remarks}</Text>
            </Space>
          )}
        </>
      ),
    },
    {
      title: "Amount",
      dataIndex: "type",
      key: "type",
      render: (_, text: any) =>
        text.type === "Debit" ? (
          <Text type="danger">- ₹{text.amount} db</Text>
        ) : (
          <Text type="success">+ ₹{text.amount} cr</Text>
        ),
    },
    // {
    //   title: "Debit",
    //   dataIndex: "type",
    //   key: "type",
    //   render: (_, text: any) =>
    //     text.type === "Debit" && <Text type="danger">- ₹ {text.amount}</Text>,
    // },
    // {
    //   title: "Credit",
    //   dataIndex: "type",
    //   key: "type",
    //   render: (_, text: any) =>
    //     text.type === "Credit" && <Text type="success">+ ₹ {text.amount}</Text>,
    // },
  ];

  //   React.useEffect(() => {
  //     getResult();
  //   }, []);
  return (
    <>
      <Table size="small" bordered columns={column} dataSource={ledger} />
      {getResult(ledger) === 0 ? (
        <Tag color="pink">All Clear:₹{getResult(ledger)}</Tag>
      ) : getResult(ledger) < 0 ? (
        <Tag color="red">Due:{getResult(ledger)}</Tag>
      ) : (
        <Tag color="Green">Advance:{getResult(ledger)}</Tag>
      )}
    </>
  );
};

export default LedgerList;
