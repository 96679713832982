import * as React from "react";

import "./Layout.css";
import { Col, Layout, Menu, Row } from "antd";
import {
  LineChartOutlined,
  UsergroupAddOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  PieChartOutlined,
  DeploymentUnitOutlined,
  GlobalOutlined,
  GroupOutlined,
  UngroupOutlined,
  MoneyCollectOutlined,
  ClusterOutlined,
  FileImageOutlined,
  ProfileOutlined,
  ReadOutlined,
  BankOutlined,
  DatabaseOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

import NavRouteLink from "../NavRouteLink";

import RouteLink from "../RouteLink";

const { SubMenu } = Menu;
const { Header, Sider, Content } = Layout;

const AuthLayout: React.FC<{ children?: React.ReactNode }> = (props) => {
  const [collapsed, setCollapsed] = React.useState(true);
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <Layout>
        {/* <Sider trigger={null} collapsible collapsed={collapsed}> */}
        {/* <div  /> */}
        <Row justify={"space-between"} align={"middle"}>
          <Col span={20}>
            <Menu
              mode="horizontal"
              defaultSelectedKeys={["dashboard"]}
              defaultOpenKeys={["dashboard"]}
              // style={{ height: "100vh" }}
            >
              <Menu.Item key="dashboard" icon={<PieChartOutlined />}>
                <NavRouteLink name="dashboard">Dashboard</NavRouteLink>
              </Menu.Item>

              {/* <Menu.ItemGroup title="HMS" key="HMS"> */}
              <Menu.Item key="tenant" icon={<UserOutlined />}>
                <NavRouteLink name="tenant">Tenant</NavRouteLink>
              </Menu.Item>
              <Menu.Item key="party" icon={<UsergroupAddOutlined />}>
                <NavRouteLink name="party">Party</NavRouteLink>
              </Menu.Item>
              <Menu.Item key="expense" icon={<LineChartOutlined />}>
                <NavRouteLink name="expense">Expense</NavRouteLink>
              </Menu.Item>
              <Menu.Item key="bill" icon={<DatabaseOutlined />}>
                <NavRouteLink name="bill">Bill</NavRouteLink>
              </Menu.Item>
              <Menu.Item key="room" icon={<GroupOutlined />}>
                <NavRouteLink name="room">Room</NavRouteLink>
              </Menu.Item>
              <Menu.Item key="facility" icon={<ClusterOutlined />}>
                <NavRouteLink name="facility">Facility</NavRouteLink>
              </Menu.Item>
              <Menu.Item key="plan" icon={<MoneyCollectOutlined />}>
                <NavRouteLink name="plan">Plan</NavRouteLink>
              </Menu.Item>
              <Menu.Item key="payment" icon={<ReadOutlined />}>
                <NavRouteLink name="payment">Payment</NavRouteLink>
              </Menu.Item>
              <Menu.Item key="dailymenu" icon={<ClusterOutlined />}>
                <NavRouteLink name="dailymenu">Daily Menu</NavRouteLink>
              </Menu.Item>
              <Menu.Item key="hostel" icon={<BankOutlined />}>
                <NavRouteLink name="hostel">Hostel Details</NavRouteLink>
              </Menu.Item>
              {/* </Menu.ItemGroup> */}
              {/* <Menu.ItemGroup title="Website" key="Website">
              <Menu.Item key="enquiry" icon={<ProfileOutlined />}>
                <NavRouteLink name="enquiry">Enquiry</NavRouteLink>
              </Menu.Item>

              <SubMenu
                key="Blog Management"
                icon={<UngroupOutlined />}
                title="Blog Management"
              >
                <Menu.Item key="blogCategories" icon={<ProfileOutlined />}>
                  <NavRouteLink name="blogCategories">
                    BlogCategories
                  </NavRouteLink>
                </Menu.Item>
                <Menu.Item key="blog" icon={<ReadOutlined />}>
                  <NavRouteLink name="blog">Blog</NavRouteLink>
                </Menu.Item>
              </SubMenu>
              <Menu.Item key="testimonial" icon={<DeploymentUnitOutlined />}>
                <NavRouteLink name="testimonial">Testimonial</NavRouteLink>
              </Menu.Item>

              <Menu.Item key="image" icon={<FileImageOutlined />}>
                <NavRouteLink name="image">Image</NavRouteLink>
              </Menu.Item>
            </Menu.ItemGroup> */}

              {/* <Menu.Item key="7" icon={<GlobalOutlined />}>
                <NavRouteLink name="dashboard">About Us</NavRouteLink>
              </Menu.Item> */}
            </Menu>
          </Col>
          <Col span={2}>
            <RouteLink name="logout">
              <>
                <LogoutOutlined />
                Logout
              </>
            </RouteLink>
          </Col>
        </Row>
        {/* </Sider> */}
        <Layout>
          {/* <Header className="site-layout-background" style={{ padding: 0 }}> */}
          {/* <Row justify="space-between">
              <Col>
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: toggle,
                  }
                )}
              </Col>

              <Col style={{ paddingRight: "2rem", fontWeight: "bold" }}>
                <RouteLink name="logout">
                  <>
                    <LogoutOutlined />
                    Logout
                  </>
                </RouteLink>
              </Col>
            </Row> */}
          {/* </Header> */}
          <Content
            className="site-layout-background"
            style={{
              backgroundColor: "aliceblue",
              // margin: "24px 16px",
              padding: 10,
              height: "93vh",
              overflow: "auto",
            }}
          >
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default AuthLayout;
