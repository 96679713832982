import { request } from "../../../utils/services";

export const getAllActiveExpense = async () => {
  const { data } = await request("admin.active.expense");
  return data;
};

export const getAllExpense = async () => {
  const { data } = await request("admin.expense.list");
  return data;
};

export const getExpenseById = async (id: string) => {
  const { data } = await request("admin.expense.id", { id });
  return data;
};

export const getExpenseByPartyId = async (id: string) => {
  const { data } = await request("admin.expense.partyId", { id });
  return data;
};
