import { TRoute } from "../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const FacilityRouter: TRoute[] = [
  {
    path: "/facility",
    name: "facility",
    title: "facility",
    component: DepartmentPageHome,
  },
];

export default FacilityRouter;
