import { request } from "../../../utils/services";

export const getAllBill = async () => {
  const { data } = await request("admin.bill.list");
  return data;
};

export const getBillById = async (id: string) => {
  const { data } = await request("admin.bill.id", { id });
  return data;
};

export const getBillByOccupant = async (id: string) => {
  const { data } = await request("admin.bill.occupant", { id });
  return data;
};
