import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import HostelCard from "../Component/HostelCard";
import { Tabs, TabsProps, Typography } from "antd";
import HostelStats from "../Component/HostelStats";
const items: TabsProps["items"] = [
  {
    key: "1",
    label: <Typography.Text strong>Hostel Analytics</Typography.Text>,
    children: <HostelStats />,
  },
  {
    key: "2",
    label: <Typography.Text strong> Occupant Summary </Typography.Text>,
    children: <HostelCard />,
  },
];

const DashboardPageHome: React.FC<RouteComponentProps> = () => {
  return (
    <>
      {/* <h2>Dashboard</h2> */}
      <Tabs type="card" defaultActiveKey="1" items={items} centered />
      {/* <HostelCard /> */}
    </>
  );
};

export default withRouter(DashboardPageHome);
