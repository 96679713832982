import { path } from "./path";

export const hostelRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveHostel",
    module: "hostel",
    alias: "admin.hostel.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateHostel/:id",
    module: "hostel",
    alias: "admin.hostel.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveHostel",
    module: "hostel",
    alias: "admin.hostel.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllHostel",
    module: "hostel",
    alias: "admin.hostel.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneHostel/:id",
    module: "hostel",
    alias: "admin.hostel.id",
    isSubDomain: true,
  },
];
