import * as React from "react";
import { Card, Col, Divider, Row, Space, Table, Typography } from "antd";

import { useQuery } from "react-query";

import moment from "moment";
import { HomeOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";

import { getBillById } from "../Requests";
import { LogoImage } from "../../../constants/images";
import { SpaceCompactItemContext } from "antd/es/space/Compact";
import ColumnGroup from "antd/es/table/ColumnGroup";
import Column from "antd/es/table/Column";
import { s3Path } from "../../../utils/s3Config/s3upload";
import { ColumnsType } from "antd/es/table";
interface DataType {
  id: number;
  name: any;
}

const ShowBillForm: React.FC<{ id: string }> = (props) => {
  const {
    isLoading,
    error,
    data: data,
  } = useQuery(["bill", props.id], async () => getBillById(props.id));
  console.log(data);

  return (
    <>
      <Row justify={"space-between"}>
        <img src={s3Path + data?.hostelId?.logoUrl} alt="i" width={150} />

        <Space.Compact>
          <Col>
            <Typography.Text strong style={{ fontSize: 20 }}>
              {data?.hostelId?.hostelName}
            </Typography.Text>
            <Typography>
              <HomeOutlined /> {data?.hostelId?.address}
            </Typography>
            <Typography>
              <MailOutlined /> {data?.hostelId?.email}
            </Typography>
            <Typography>
              <PhoneOutlined rotate={90} /> +91-{data?.hostelId?.contact}
            </Typography>
          </Col>
        </Space.Compact>
      </Row>

      <Card>
        <Row justify={"space-between"}>
          <Col>
            <Typography.Text strong>Tenant Details</Typography.Text>
            <Typography>Name:-{data?.occupantId?.name}</Typography>
            <Typography>
              Room No.:-{data?.occupantId?.roomId?.roomNo}
            </Typography>
          </Col>
          <Space.Compact direction="vertical">
            <Typography.Text strong> Period</Typography.Text>
            <Row>
              <Typography.Text code>
                {moment(data.fromDate).format("DD-MMM-YY")}{" "}
              </Typography.Text>{" "}
              to
              <Typography.Text code>
                {moment(data.toDate).format("DD-MMM-YY")}
              </Typography.Text>
            </Row>
          </Space.Compact>

          <Space.Compact>
            <Col>
              <Typography>Bill No.:-PRH-{data?.seq}</Typography>
              <Typography>
                Date:-{moment(data?.createdAt).format("DD-MMM-YY")}
              </Typography>
            </Col>
          </Space.Compact>
        </Row>
        <Divider />
        <Row justify={"space-between"}>
          <Col>
            <Typography.Text strong> Fee Type</Typography.Text>

            {data?.item?.map((item: any, i: number) => {
              return (
                <Row>
                  <Typography.Text code style={{ margin: 2 }} type="danger">
                    {item?.particular}:-{item?.amount}
                  </Typography.Text>
                </Row>
              );
            })}
          </Col>
          <Col>
            {" "}
            <Typography>
              Due Date:-{moment(data?.dueDate).format("DD-MMM-YY")}
            </Typography>
          </Col>
        </Row>
      </Card>
      {/* 
      <Card>
        <Table>
          <ColumnGroup>
            <Column title="S.No.">{data?.seq}</Column> */}
      {/* 
            <Column title="Item">
              {data?.item?.map((item: any, i: number) => {
                return (  
                  <Row>
                    <Typography.Text code style={{ margin: 2 }} type="danger">
                      {item?.particular}
                    </Typography.Text>
                  </Row>
                );
              })}
            </Column> */}
      {/* <Column title="Amount"></Column>
          </ColumnGroup>
        </Table>
      </Card> */}
    </>
  );
};
export default ShowBillForm;
