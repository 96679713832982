import * as React from "react";
import { Descriptions, Card, Divider, Space, Tag } from "antd";
import { useQuery } from "react-query";
import { getPartyById } from "../Requests";
import { getExpenseByPartyId } from "../../Expense/Requests";
import Table, { ColumnsType } from "antd/es/table";
import ManageExpenseModal from "../../Expense/Modal/ManageExpense";

interface DataType {
  id: number;
  name: any;
}
const ShowPartyForm: React.FC<{ id: string }> = (props) => {
  const { data: party } = useQuery(
    ["party", props.id],
    async () => getExpenseByPartyId(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );
  const column: ColumnsType<DataType> = [
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <ManageExpenseModal id={id} />
          {/* <PartyViewModal id={id} /> */}
        </Space>
      ),
    },
  ];

  return (
    <Card>
      <Divider orientation="left">Party Info</Divider>

      <Descriptions
      // title="Basic Info"
      // extra={<PartyManageModal id={party.id} />}
      >
        <Descriptions.Item label="Party Name">
          {party[0]?.partyId?.name}
        </Descriptions.Item>

        <Descriptions.Item label="Mobile">
          +91- {party[0]?.partyId?.mobile}
        </Descriptions.Item>
        <Descriptions.Item label="Adhar No">
          {party[0]?.partyId?.adharNo}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Social Media Detail</Divider>
      <Table size="small" columns={column} dataSource={party} />
    </Card>
    // </Card>
  );
};

export default ShowPartyForm;
