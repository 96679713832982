import * as React from "react";
import { Form, Button, message, Row, Input, Col, Switch, Select } from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  UserOutlined,
  MailOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";

import LocalStorageService from "../../../utils/local-storage";
import { CreateExpenseModalContext } from "../Modal/CreateExpense";
import { type } from "os";
import TextArea from "antd/es/input/TextArea";
import { InputRules } from "../../../utils/InputRules";
import CreatePartyModal from "../../Party/Modal/CreateParty";
import { getAllParty } from "../../Party/Requests";
import { getAllHostel } from "../../Hotel Detail/Requests";

const CreateExpense: React.FC = () => {
  const modalContext = React.useContext(CreateExpenseModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const createExpenseMutation = useMutation(
    (values: any) => request("admin.expense.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("expense");
      },
    }
  );
  const { data: hostel } = useQuery(["hostel"], async () => getAllHostel(), {
    refetchOnWindowFocus: false,
  });
  const { data: party } = useQuery(["party"], async () => getAllParty(), {
    refetchOnWindowFocus: false,
  });

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;

    setFormLoading(true);

    createExpenseMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Expense has been successfully created.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Select Hostel"
        name="hostelId"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "hostelId",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Hostel ",
          },

          { whitespace: true },
        ]}
        hasFeedback
      >
        <Select
          showSearch
          placeholder="Select Hostel"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA!.children as unknown as string)
              .toLowerCase()
              .localeCompare(
                (optionB!.children as unknown as string).toLowerCase()
              )
          }
        >
          {hostel &&
            hostel?.map((item: any, i: number) => {
              return (
                <Select.Option value={item?.id} key={i}>
                  {item?.hostelName}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Party"
        style={{ fontWeight: "bold" }}
        name="partyId"
        tooltip={{
          title: "Party",
          icon: <InfoCircleOutlined />,
        }}
      >
        <Select
          showSearch
          placeholder="Select Party"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA!.children as unknown as string)
              .toLowerCase()
              .localeCompare(
                (optionB!.children as unknown as string).toLowerCase()
              )
          }
          dropdownRender={(menu) => (
            <>
              <CreatePartyModal />

              {menu}
            </>
          )}
        >
          {party &&
            party?.map((item: any, i: number) => {
              return (
                <Select.Option value={item?.id} key={i}>
                  {item?.name}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>

      <Form.Item
        label="Amount"
        name="amount"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Amount",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Your  Amount",
          },
          { whitespace: true },
          ...InputRules.number,
        ]}
        hasFeedback
      >
        <Input maxLength={10} placeholder="Enter Amount" />
      </Form.Item>

      <Form.Item
        label="Reason"
        name="reason"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Reason",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please enter your Reason",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <TextArea placeholder="Enter Your Reason" maxLength={100} />
      </Form.Item>

      <Row gutter={20}>
        <Col flex={2} span={12}>
          <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateExpense;
