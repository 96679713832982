import * as React from "react";
import { Modal, Button, Tooltip } from "antd";
import { useHistory } from "react-router-dom";

import ShowBillForm from "../Form/ShowBillForm";
import {
  EyeOutlined,

  // CloseOutlined,
  // CheckOutlined,
} from "@ant-design/icons";
export const ShowBillModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const BillViewModal: React.FC<{ id: string }> = ({ id }) => {
  const { push } = useHistory();

  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ShowBillModalContext.Provider value={{ setIsModalVisible }}>
      <Tooltip title="View All Details">
        <Button
          onClick={showModal}
          type="primary"
          shape="circle"
          icon={<EyeOutlined />}
        />
      </Tooltip>
      <Modal
        width={1000}
        title=" View Bill Request Form"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ShowBillForm id={id} />
        <div style={{ textAlign: "center" }}>
          <Button type="primary">Print</Button>
        </div>
      </Modal>
    </ShowBillModalContext.Provider>
  );
};

export default BillViewModal;
