import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Space, Table, Tag, Image, Empty } from "antd";

import { getAllblogCategories } from "../Requests";
import BlogCategoriesViewModal from "../Modal/ShowBlogCategoriesDetails";
import ManageBlogCategoriesModal from "../Modal/ManageBlogCategories";
import { s3Path } from "../../../../utils/s3Config/s3upload";

interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const BlogCategoriesList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["blogCategories"], () => getAllblogCategories(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Images",
      dataIndex: "fileUrl",
      key: "fileUrl",
      render: (text: any) =>
        text ? (
          <Image width={100} height={100} src={s3Path + text} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <BlogCategoriesViewModal id={id} />
          <ManageBlogCategoriesModal id={id} />
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default BlogCategoriesList;
