import { request } from "../../../utils/services";

export const getAllActivePaymentTerms = async () => {
  const { data } = await request("admin.paymentTerms.list.active");
  return data;
};

export const getAllPaymentTerms = async () => {
  const { data } = await request("admin.paymentTerms.list");
  return data;
};

export const getPaymentTermsById = async (id: string) => {
  const { data } = await request("admin.paymentTerms.id", { id });
  return data;
};
