import { path } from "./path";

export const kitchenRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveKitchen",
    module: "kitchen",
    alias: "admin.kitchen.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateKitchen/:id",
    module: "kitchen",
    alias: "admin.kitchen.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveKitchen",
    module: "kitchen",
    alias: "admin.kitchen.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllKitchen",
    module: "kitchen",
    alias: "admin.kitchen.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneKitchen/:id",
    module: "kitchen",
    alias: "admin.kitchen.id",
    isSubDomain: true,
  },
];
