import * as React from "react";
import {
  Descriptions,
  Card,
  Divider,
  Tag,
  Row,
  Space,
  Col,
  Typography,
  Image,
} from "antd";
import { useQuery } from "react-query";
import { getOccupantById, getOccupantByIdDetail } from "../Requests";
import { getLedgerByOccupant } from "../../Payment/Requests";
import LedgerList from "../Component/Ledger";
import { s3Path } from "../../../utils/s3Config/s3upload";
const { Text } = Typography;
const ShowOccupantDetailsForm: React.FC<{ id: string }> = (props) => {
  const { data: occupant } = useQuery(
    ["occupant", props.id],
    async () => getOccupantByIdDetail(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );
  const { data: ledger } = useQuery(
    ["ledger", props.id],
    async () => getLedgerByOccupant(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );

  return (
    <Card>
      <Card hoverable>
        <Row justify={"space-between"} align={"middle"}>
          <Image
            width={150}
            height={150}
            src={s3Path + occupant[0]?.imageUrl}
            style={{}}
          />
          <Text style={{ fontSize: 25, fontWeight: "bold" }}>
            {occupant?.name}
          </Text>
          <Text type="success" style={{ fontSize: 25, fontWeight: "bold" }}>
            +91-{occupant?.phoneNo}
          </Text>
        </Row>
      </Card>
      <Divider orientation="left">Occupant Info</Divider>

      <Descriptions
      // title="Basic Info"
      // extra={<OccupantManageModal id={occupant.id} />}
      >
        <Descriptions.Item label="Name">{occupant?.name}</Descriptions.Item>
        <Descriptions.Item label="Education">
          {occupant?.education}
        </Descriptions.Item>
        <Descriptions.Item label="Mobile">
          +91-{occupant?.phoneNo}
        </Descriptions.Item>
        <Descriptions.Item label="Age">{occupant?.age}</Descriptions.Item>
        <Descriptions.Item label="Gender">{occupant?.gender}</Descriptions.Item>
        <Descriptions.Item label="Work Place">
          {occupant?.workPlace}
        </Descriptions.Item>
        <Descriptions.Item label="Address">
          {occupant?.address}
        </Descriptions.Item>
        <Descriptions.Item label="Adhar Number">
          {occupant?.adharNo}
        </Descriptions.Item>
        <Descriptions.Item label="Occupation">
          {occupant?.occupation}
        </Descriptions.Item>
        <Descriptions.Item label="Reference">
          {occupant?.reference}
        </Descriptions.Item>
        <Descriptions.Item label="Remarks">
          {occupant?.remarks}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Family Detail</Divider>
      <Descriptions>
        <Descriptions.Item label="Parent Name">
          {occupant?.parentName}
        </Descriptions.Item>
        <Descriptions.Item label="Parent Address">
          {occupant?.paddress}
        </Descriptions.Item>
        <Descriptions.Item label="Mobile">
          +91-{occupant?.pmobNo}
        </Descriptions.Item>

        <Descriptions.Item label="Relation">
          {occupant?.relation}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Room Detail</Divider>
      <Descriptions>
        <Descriptions.Item label="Room No">
          {occupant?.roomId?.roomNo}
        </Descriptions.Item>
        <Descriptions.Item label="facility">
          <Row style={{ margin: "2" }}>
            {occupant?.roomId?.facilityId.map((item: any) => (
              <>
                <Row>
                  <Tag color="red">{item.title}</Tag>
                </Row>
              </>
            ))}
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label="Bathroom">
          {occupant?.roomId?.bathroom}
        </Descriptions.Item>

        <Descriptions.Item label="Floor">
          {occupant?.roomId?.floor}
        </Descriptions.Item>
        <Descriptions.Item label="Size">
          {occupant?.roomId?.size}
        </Descriptions.Item>
        <Descriptions.Item label="Occupancy">
          {occupant?.roomId?.occupancy}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Plan Detail</Divider>
      <Card>
        <Row justify={"space-between"} align={"middle"}>
          <Col>
            <Text
              style={{
                margin: 2,
                fontWeight: "bold",
                textDecorationLine: "underline",
              }}
            >
              {occupant?.paymentTermsId?.title}
            </Text>
            {occupant?.paymentTermsId?.description?.map(
              (item: any, i: number) => {
                return (
                  <Col>
                    <Text style={{ margin: 2, fontWeight: "normal" }}>
                      {i + 1 + "." + item}
                    </Text>
                  </Col>
                );
              }
            )}
          </Col>
          <Col>
            <Row>
              <Text code>Price: ₹{occupant?.paymentTermsId?.price}</Text>
            </Row>
            <Row>
              <Text type="success" code>
                Frequency:{occupant?.paymentTermsId?.frequency}
              </Text>
            </Row>
            <Row>
              <Text type="danger" code>
                Food:{occupant?.paymentTermsId?.food}
              </Text>
            </Row>
          </Col>
        </Row>
      </Card>

      <Divider orientation="left">Ledger</Divider>
      <LedgerList id={props.id} />
    </Card>
    // </Card>
  );
};

export default ShowOccupantDetailsForm;
