import { path } from "./path";

export const bookingRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveBooking",
    module: "booking",
    alias: "admin.booking.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateBooking/:id",
    module: "booking",
    alias: "admin.booking.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveBooking",
    module: "booking",
    alias: "admin.booking.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllBooking",
    module: "booking",
    alias: "admin.booking.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneBooking/:id",
    module: "booking",
    alias: "admin.booking.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneBookingDetails/:id",
    module: "booking",
    alias: "admin.booking.id.detail",
    isSubDomain: true,
  },
];
