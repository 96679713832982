import * as React from "react";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import { Row, Col, Card, Avatar, Divider, Statistic, Button } from "antd";
import { useQuery } from "react-query";
//import { getCompanyByUser } from 'src/modules/Company/Requests';
import { queryClient } from "../../../../src";
import LocalStorageService from "../../../utils/local-storage";
import CookiesService from "../../../utils/cookie";

import {
  BankOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  MailOutlined,
  AuditOutlined,
  IdcardOutlined,
  GlobalOutlined,
  SmileTwoTone,
  ArrowUpOutlined,
  ArrowDownOutlined,
  ReconciliationOutlined,
  DislikeOutlined,
  LikeOutlined,
  SmileOutlined,
  FrownOutlined,
} from "@ant-design/icons";

import { getAllActiveOccupant } from "../../Occupant/Requests";
import { getAllPayment } from "../../Payment/Requests";
import { getAllRoom } from "../../Room/Requests";
import { getAllBill } from "../../Bill/Requests";
import { getAllExpense } from "../../Expense/Requests";
import { billRoutes } from "../../../utils/routes/bill-route";
import { Chart } from "react-google-charts";
// import DashboardSalesWidget from '../Component/SalesWidget';
// import DashboardLatestTransactionsWidget from '../Component/LatestTransactionsWidget';
// import DashboardActionNeededWidget from '../Component/ActionNeeded';

const HostelStats: React.FC<{ id?: string }> = (props) => {
  const { push } = useHistory();

  // const [page, setPage] = React.useState(1);
  // const [size, setSize] = React.useState(10);
  const [totalPayment, setTotalPayment] = React.useState(0);
  const [totalExpense, setTotalExpense] = React.useState(0);
  const [totalBill, setTotalBill] = React.useState(0);
  const [totalProfit, setTotalProfit] = React.useState(0);
  const [totalDue, setTotalDue] = React.useState(0);
  const [totalBed, setTotalBed] = React.useState(0);
  const [emptyBed, setEmptyBed] = React.useState(0);
  const [filledBed, setFilledBed] = React.useState(0);
  const { data: occupant } = useQuery(
    ["activeoccupant"],
    async () => getAllActiveOccupant(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: payment } = useQuery(["payment"], async () => getAllPayment(), {
    refetchOnWindowFocus: false,
  });
  const { data: room } = useQuery(["rooms"], async () => getAllRoom(), {
    refetchOnWindowFocus: false,
  });
  const { data: bills } = useQuery(["bills"], async () => getAllBill(), {
    refetchOnWindowFocus: false,
  });
  const { data: expense } = useQuery(["expense"], async () => getAllExpense(), {
    refetchOnWindowFocus: false,
  });

  React.useEffect(() => {
    var tempPayment = 0;
    var tempExpense = 0;
    var tempBill = 0;
    var tempTotalBed = 0;
    var tempEmptyBed = 0;

    payment.forEach((element: any) => {
      tempPayment = tempPayment + element.amount;
      setTotalPayment(tempPayment);
    });
    expense.forEach((element: any) => {
      tempExpense = tempExpense + element.amount;
      setTotalExpense(tempExpense);
    });
    bills.forEach((element: any) => {
      tempBill = tempBill + element.amount;
      setTotalBill(tempBill);
      setTotalDue(totalPayment - totalBill);
      setTotalProfit(totalPayment - totalExpense);
    });
    room.forEach((element: any) => {
      tempTotalBed = tempTotalBed + element.occupancy;
      setTotalBed(tempTotalBed);
      tempEmptyBed = tempEmptyBed + element.availability;
      setEmptyBed(tempEmptyBed);
    });
  }, []);
  setTimeout(() => {
    setTotalDue(totalPayment - totalBill);
    setTotalProfit(totalPayment - totalExpense);
    setFilledBed(totalBed - emptyBed);
  }, 1000);
  const data = [
    ["Beds", "Beds Per Room"],
    [`Filled Beds:${filledBed}`, filledBed],
    [`Empty Beds:${emptyBed}`, emptyBed],
    // ["Total Bed", totalBed],
    // CSS-style declaration
  ];

  const options = {
    title: `Analytics(Total Beds:${totalBed})`,
    pieSliceText: "label",

    pieHole: 0.3,
    is3D: true,
    showScale: true,
  };
  return (
    <>
      <div className="site-card-wrapper">
        <Row justify={"space-evenly"}>
          <Col span={14}>
            <Row gutter={16} style={{ padding: 10 }}>
              <Col span={12}>
                {/* <Divider orientation='left' > Company Info</Divider> */}
                <Card hoverable bordered color="green">
                  <Statistic title="Active Occupant" value={occupant?.length} />
                  <Button
                    style={{ marginTop: 16 }}
                    type="primary"
                    onClick={() => push("/occupant")}
                  >
                    Add occupant
                  </Button>
                </Card>
              </Col>

              <Col span={12}>
                {/* <Divider orientation='left' > Company Info</Divider> */}
                <Card hoverable bordered>
                  <Statistic title="Total Room" value={room?.length} />
                  <Button
                    style={{ marginTop: 16 }}
                    type="primary"
                    onClick={() => push("/room")}
                  >
                    Add Room
                  </Button>
                </Card>
              </Col>
            </Row>
            <Row gutter={16} style={{ padding: 10 }}>
              <Col span={12}>
                <Card hoverable bordered>
                  <Statistic
                    title="Total Bill Generated"
                    value={totalBill}
                    // precision={2}
                    valueStyle={{ color: "blue" }}
                    prefix="₹"
                    suffix={<ReconciliationOutlined />}
                    // p="%"
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card hoverable bordered>
                  <Statistic
                    title="Payment Collected"
                    value={totalPayment}
                    // precision={2}
                    valueStyle={{ color: "#3f8600" }}
                    prefix="₹"
                    suffix={<ArrowUpOutlined />}
                    // p="%"
                  />
                </Card>
              </Col>
            </Row>
            <Row gutter={16} style={{ padding: 10 }}>
              <Col span={8}>
                <Card hoverable bordered>
                  <Statistic
                    title="Total Expense"
                    value={totalExpense}
                    // precision={2}
                    valueStyle={{ color: "#cf1322" }}
                    suffix={<ArrowDownOutlined />}
                    prefix="₹"
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card hoverable bordered>
                  <Statistic
                    title={totalDue < 0 ? "Total Due" : "Total Advance"}
                    value={totalDue}
                    // precision={2}
                    valueStyle={{ color: totalDue < 0 ? "red" : "green" }}
                    prefix="₹"
                    suffix={
                      totalDue < 0 ? <DislikeOutlined /> : <LikeOutlined />
                    }
                    // p="%"
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card hoverable bordered>
                  <Statistic
                    title={totalProfit < 0 ? "Total Loss" : "Total Profit"}
                    value={totalProfit}
                    // precision={2}
                    valueStyle={{ color: totalProfit < 0 ? "red" : "green" }}
                    suffix={
                      totalProfit < 0 ? <FrownOutlined /> : <SmileOutlined />
                    }
                    prefix="₹"
                  />
                </Card>
              </Col>
            </Row>
          </Col>

          <Col span={10}>
            <Chart
              chartType="PieChart"
              width="100%"
              height="400px"
              data={data}
              options={options}
            />
          </Col>
        </Row>
        {/* <Row gutter={16}>
          <Col span={6}>
          
            <Card hoverable bordered color="green">
              <Statistic title="Active Occupant" value={occupant?.length} />
              <Button
                style={{ marginTop: 16 }}
                type="primary"
                onClick={() => push("/occupant")}
              >
                Add occupant
              </Button>
            </Card>
          </Col>

          <Col span={6}>
          
            <Card hoverable bordered>
              <Statistic title="Total Room" value={room?.length} />
              <Button
                style={{ marginTop: 16 }}
                type="primary"
                onClick={() => push("/room")}
              >
                Add Room
              </Button>
            </Card>
          </Col>
          <Col span={12}>
            <Chart
              chartType="PieChart"
              width="100%"
              height="400px"
              data={data}
              options={options}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Card hoverable bordered>
              <Statistic
                title="Total Bill Generated"
                value={totalBill}
                // precision={2}
                valueStyle={{ color: "blue" }}
                prefix="₹"
                suffix={<ReconciliationOutlined />}
                // p="%"
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card hoverable bordered>
              <Statistic
                title="Payment Collected"
                value={totalPayment}
                // precision={2}
                valueStyle={{ color: "#3f8600" }}
                prefix="₹"
                suffix={<ArrowUpOutlined />}
                // p="%"
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card hoverable bordered>
              <Statistic
                title="Total Expense"
                value={totalExpense}
                // precision={2}
                valueStyle={{ color: "#cf1322" }}
                suffix={<ArrowDownOutlined />}
                prefix="₹"
              />
            </Card>
          </Col>
        </Row> */}
        {/* <Row gutter={16}>
          <Col span={8}>
            <Card hoverable bordered>
              <Statistic
                title={totalDue < 0 ? "Total Due" : "Total Advance"}
                value={totalDue}
                // precision={2}
                valueStyle={{ color: totalDue < 0 ? "red" : "green" }}
                prefix="₹"
                suffix={totalDue < 0 ? <DislikeOutlined /> : <LikeOutlined />}
                // p="%"
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card hoverable bordered>
              <Statistic
                title={totalProfit < 0 ? "Total Loss" : "Total Profit"}
                value={totalProfit}
                // precision={2}
                valueStyle={{ color: totalProfit < 0 ? "red" : "green" }}
                suffix={totalProfit < 0 ? <FrownOutlined /> : <SmileOutlined />}
                prefix="₹"
              />
            </Card>
          </Col>
        </Row> */}
      </div>
    </>
  );
};

export default HostelStats;
