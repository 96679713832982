import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Button, Space, Table, Tag, Typography } from "antd";

import { getAllBooking } from "../Requests/index";

import BookingViewModal from "../Modal/ShowBookingDetails";
import ManageBookingModal from "../Modal/ManageBooking";
const { Text } = Typography;
interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const BookingList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["booking"], () => getAllBooking(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Registration Date",
      dataIndex: "registrationDate",
      key: "registrationDate",
    },
    {
      title: "Occupant Detail",
      dataIndex: "occupantId",
      key: "occupantId",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Button>{i?.occupantId.name}</Button>
            <Button>{i?.occupantId.parentName}</Button>
            <Button>{i?.occupantId.address}</Button>
            <Button>{i?.occupantId.phoneNo}</Button>
          </Space.Compact>
        </Space>
      ),
    },

    {
      title: "Room Detail",
      dataIndex: "roomId",
      key: "roomId",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Text>
              Room No:
              <Text type="success" code>
                {i?.roomId.roomNo}
              </Text>
            </Text>
            <Text>
              Occupany:
              <Text type="warning" code>
                {i?.roomId.occupancy}
              </Text>
            </Text>
          </Space.Compact>
        </Space>
      ),
    },
    {
      title: "Payment Terms",
      dataIndex: "paymentTermsId",
      key: "paymentTermsId",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Button type="text">
              <Text>
                Price: <Tag color="magenta">{i?.paymentTermsId.price}</Tag>
              </Text>
            </Button>
            <Button type="text">
              <Text>
                Frequency: <Tag color="cyan">{i?.paymentTermsId.frequency}</Tag>
              </Text>
            </Button>
            <Button type="text">
              <Text>
                Food: <Tag color="orange">{i?.paymentTermsId.food}</Tag>
              </Text>
            </Button>
          </Space.Compact>
        </Space>
      ),
    },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <BookingViewModal id={id} />
          <ManageBookingModal id={id} />
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default BookingList;
