import { path } from "./path";

export const facilityRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveFacility",
    module: "facility",
    alias: "admin.facility.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateFacility/:id",
    module: "facility",
    alias: "admin.facility.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveFacility",
    module: "facility",
    alias: "admin.facility.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllFacility",
    module: "facility",
    alias: "admin.facility.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneFacility/:id",
    module: "facility",
    alias: "admin.facility.id",
    isSubDomain: true,
  },
];
