import { path } from "./path";

export const imageRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveImage",
    module: "image",
    alias: "admin.image.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateImage/:id",
    module: "image",
    alias: "admin.image.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveImage",
    module: "image",
    alias: "admin.image.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllImage",
    module: "image",
    alias: "admin.image.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneImage/:id",
    module: "image",
    alias: "admin.image.id",
    isSubDomain: true,
  },
];
