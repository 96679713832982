import { path } from "./path";

export const occupantRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveOccupant",
    module: "occupant",
    alias: "admin.occupant.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateOccupant/:id",
    module: "occupant",
    alias: "admin.occupant.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveOccupant",
    module: "occupant",
    alias: "admin.occupant.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllOccupant",
    module: "occupant",
    alias: "admin.occupant.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneOccupant/:id",
    module: "occupant",
    alias: "admin.occupant.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneOccupantDetail/:id",
    module: "occupant",
    alias: "admin.occupant.id.detail",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOccupantByHostelId/:id",
    module: "occupant",
    alias: "admin.occupant.hostelId",
    isSubDomain: true,
  },
];
