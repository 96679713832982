import { TRoute } from "../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const PaymentRouter: TRoute[] = [
  {
    path: "/payment",
    name: "payment",
    title: "payment",
    component: DepartmentPageHome,
  },
];

export default PaymentRouter;
