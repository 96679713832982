import * as React from "react";
import { Descriptions, Card, Divider } from "antd";
import { useQuery } from "react-query";
import { getBlogById } from "../Requests";

const ShowBlogDetailsForm: React.FC<{ id: string }> = (props) => {
  const { data: blog } = useQuery(
    ["blog", props.id],
    async () => getBlogById(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );

  return (
    <Card>
      <Divider orientation="left">Blog Detail</Divider>

      <Descriptions>
        <Descriptions.Item label="Tilte">{blog.title}</Descriptions.Item>
        <Descriptions.Item label="Author">{blog.author}</Descriptions.Item>
        <Descriptions.Item label="Image Title">
          {blog.imageTitle}
        </Descriptions.Item>
        <Descriptions.Item label="PermaLink">
          {blog.permaLink}
        </Descriptions.Item>

        <Descriptions.Item label="content">{blog.content}</Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">SEO Detail</Divider>
      <Descriptions>
        <Descriptions.Item label="Meta Title">
          {blog.metaTitle}
        </Descriptions.Item>
        <Descriptions.Item label="Meta Keyword">
          {blog.metaKeyword}
        </Descriptions.Item>
        <Descriptions.Item label="Meta Desciption">
          {blog.metaDiscription}
        </Descriptions.Item>
      </Descriptions>
    </Card>
    // </Card>
  );
};

export default ShowBlogDetailsForm;
