import {
  Result,
  Tag,
  Image,
  Empty,
  Row,
  Col,
  InputRef,
  Input,
  Tabs,
  Segmented,
  Card,
} from "antd";

import * as React from "react";
import { SearchOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";
import type { ColumnType, ColumnsType } from "antd/lib/table";

import OccupantManageModal from "../../Modal/ManageOccupant";

import { Button, Space, Table, Typography } from "antd";
import OccupantViewModal from "../../Modal/ShowOccupantDetails";
import Highlighter from "react-highlight-words";
import { getAllOccupant, getOccupantByHostelId } from "../../Requests";
import { s3Path } from "../../../../utils/s3Config/s3upload";
import AssignedRoomManageModal from "../../Modal/assignedRoom";
import moment from "moment";
import CreateBillModal from "../../../Bill/Modal/CreateBill";
import CreatePaymentModal from "../../../Payment/Modal/CreatePayment";
import { FilterConfirmProps } from "antd/es/table/interface";
import ReactWhatsapp from "react-whatsapp";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import WhatsappMessage from "../whatsapp";
import { getAllHostel } from "../../../Hotel Detail/Requests";
import { values } from "lodash";
import PhoneCall from "../../../../components/call";

const { Text, Title } = Typography;
interface DataType {
  id: number;
  name: string;
  person: string;
  phone: number;
  status: any;
}
type DataIndex = keyof DataType;
const OccupantList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);

  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const [selectedHostelId, setSelectedHostelId] = React.useState(undefined);
  const searchInput = React.useRef<InputRef>(null);
  const {
    isLoading,
    isError,
    data: occupant,
  } = useQuery(["occupant"], () => getAllOccupant(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  const [occupantData, setOccupantData] = React.useState<any>(occupant);

  const { data: hostel } = useQuery(["hostel"], () => getAllHostel(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  const mutateHostel = useMutation((id: string) => getOccupantByHostelId(id));
  const OnHostelSelect = (i: any) => {
    // console.log(i);

    // setSelectedHostelId(i);
    //  setIsOccupantSelected(true);
    i === "All"
      ? setOccupantData(occupant)
      : mutateHostel
          .mutateAsync(i)
          .then((payload) => {
            setOccupantData(payload);
          })
          .catch((e) => {
            console.log(e, "error");
          });
  };

  // const ArrangeOccupantHostelWise = (arr: any) => {
  //   var parentChildArray: any;
  //   var res: any;
  //   arr?.reduce((acc: any, val: any, ind: any, array: any) => {
  //     var result: any = [];
  //     const childs: any = [];
  //     array.forEach((el: any, i: any) => {
  //       if (childs.includes(el.hostelId) || val.hostelId === el.hostelId) {
  //         childs.push(el);
  //         // acc.concat({ ...val.hostel, childs });
  //       }
  //     });

  //     console.log(val, ind);
  //     // console.log(array, ind);
  //     console.log(acc, ind);
  //     parentChildArray = acc.concat({ ...val.hostel, childs });
  //     // parentChildArray = acc.concat(result);
  //     parentChildArray.forEach((ele: any) => {
  //       if (res && res.includes(ele._id)) {
  //         res.push(ele);
  //       }
  //     });
  //     console.log(res);
  //     return parentChildArray;
  //   }, []);

  //   return parentChildArray;
  // };
  // let data = ArrangeOccupantHostelWise(occupant);
  // console.log(data);
  // console.log(data);
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const column: ColumnsType<DataType> = [
    {
      title: "Tenant Id",
      dataIndex: "seq",
      key: "seq",
      render: (_: any, i: any) => (
        <div style={{ backgroundColor: "aquamarine" }}>
          <Tag>PRH/Tenant/{i.seq}</Tag>
        </div>
      ),
    },
    {
      title: "Profile",
      dataIndex: "imageUrl",
      key: "imageUrl",

      render: (_: any, i: any) =>
        i.imageUrl ? (
          <Space>
            <Space.Compact direction="vertical">
              <Image width={80} height={80} src={s3Path + i.imageUrl} />
              <Text code>{i?.name}</Text>
              <Text type="success">+91-{i?.phoneNo}</Text>

              {/* <Text type="warning">{i?.address}</Text> */}
            </Space.Compact>
          </Space>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ),
    },
    // {
    //   title: "Name",
    //   dataIndex: "name",
    //   key: "Name",
    //   render: (_: any, i: any) => (
    //     <Space>
    //       <Space.Compact direction="vertical">
    //         <Button>{i?.name}</Button>
    //         <Button>{i?.education}</Button>
    //         <Button>{i?.occupation}</Button>
    //       </Space.Compact>
    //     </Space>
    //   ),
    // },
    // {
    //   title: "Name & Contacts",
    //   dataIndex: "name",
    //   key: "name",

    //   ...getColumnSearchProps("name"),
    //   render: (_: any, i: any) => (
    //     <Space>
    //       <Space.Compact direction="vertical">
    //         <Title level={4}>{i?.name}</Title>
    //         <Text type="success">+91-{i?.phoneNo}</Text>
    //         <Text type="warning">{i?.address}</Text>
    //       </Space.Compact>
    //     </Space>
    //   ),
    // },
    {
      title: "Hostel/Room No",
      dataIndex: "id",
      key: "id",
      render: (_: any, i: any) => (
        <Card size="small">
          <Text strong>{i?.hostel[0]?.hostelName}</Text>
          {i.roomId?.length > 0 && (
            <>
              <Row justify={"space-around"}>
                <Col>
                  <Title level={4}>{i.roomId[0].roomNo}</Title>{" "}
                </Col>
                <Col>
                  <Row>
                    <Text code>Price: ₹{i.paymentTermsId[0]?.price}</Text>
                  </Row>
                  <Row>
                    <Text type="success" code>
                      Frequency:{i.paymentTermsId[0].frequency}
                    </Text>
                  </Row>
                  <Row>
                    <Text type="danger" code>
                      Food:{i.paymentTermsId[0].food}
                    </Text>
                  </Row>
                </Col>
              </Row>
            </>
          )}

          <Space size={10}>
            <AssignedRoomManageModal id={i._id} hostelId={i.hostelId} />
          </Space>
        </Card>
      ),
    },
    {
      title: "Generate Bill",
      dataIndex: "id",
      key: "id",
      render: (_: any, i: any) => (
        <>
          {i.bills?.length > 0 ? (
            <Space.Compact direction={"vertical"}>
              {i.bills && (
                <>
                  <Text code type="danger">
                    Last Bill: ₹{i.bills[0].amount}
                  </Text>
                  <Text code>
                    Next Bill Date:
                    {moment(i.bills[0].nextBillDate).format("DD/MMM/YY")}
                  </Text>
                </>
              )}
              {moment().isAfter(i.bills[0].nextBillDate) && (
                <CreateBillModal id={i._id} hostelId={i?.hostelId} />
              )}
            </Space.Compact>
          ) : (
            <CreateBillModal id={i._id} />
          )}
        </>
      ),
    },
    {
      title: "Last Payment",
      dataIndex: "id",
      key: "id",
      render: (_: any, i: any) => (
        <>
          {i.payments?.length > 0 ? (
            <Space size={10}>
              <Space.Compact direction={"vertical"}>
                {i.payments && (
                  <>
                    <Space.Compact direction={"vertical"}>
                      <Text code type="success">
                        Last Paid: ₹{i.payments[0].amount}
                      </Text>
                      <Text code>
                        Payment Date:
                        {moment(i.payments[0].createdAt).format("DD/MMM/YY")}
                      </Text>
                    </Space.Compact>
                  </>
                )}
                {moment().isAfter(i.payments[0].createdAt) && (
                  <CreatePaymentModal id={i._id} />
                )}
              </Space.Compact>
            </Space>
          ) : (
            <CreatePaymentModal id={i._id} />
          )}
        </>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "isActive",
      key: "isActive",

      render: (_: any, i: any) => {
        var debit = 0;
        var credit = 0;
        for (var a = 0; a < i.ledgers?.length; a++) {
          if (i.ledgers[a].type === "Debit") {
            debit = debit + i.ledgers[a].amount;
          } else {
            credit = credit + i.ledgers[a].amount;
          }
        }
        var result = credit - debit;
        var message = `Dear +${i?.name} + %0AYour Last Payment is ₹${
          i?.payments[0]?.amount
        } Date:${moment(i?.payments[0]?.createdAt).format(
          "DD/MMM/YY"
        )}. %0AYour Last Bill is ₹${i?.bills[0]?.amount} Date:${moment(
          i?.bills[0]?.createdAt
        ).format("DD/MMM/YY")} +%0ATotal +${
          result === 0 ? "All Clear" : result < 0 ? "Due" : "Advance"
        }+₹${result} +%0AKindly ignore if already paid!`;
        return (
          <>
            <Space.Compact direction="horizontal">
              <Space.Compact direction="vertical">
                {result === 0 ? (
                  <Tag color="pink">All Clear : ₹0</Tag>
                ) : result < 0 ? (
                  <Tag color="red">Due: ₹{result}</Tag>
                ) : (
                  <Tag color="Green">Advance: ₹{result} </Tag>
                )}

                {i.isActive ? (
                  <Tag color="green">Active</Tag>
                ) : (
                  <Tag color="red">Inactive</Tag>
                )}
              </Space.Compact>
              <Space.Compact direction="vertical">
                <PhoneCall phoneNumber={i?.phoneNo} />
                <WhatsappMessage phoneNumber={i.phoneNo} message={message} />
              </Space.Compact>
            </Space.Compact>
          </>
        );
      },
    },

    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",

      render: (_, i: any) => (
        <Space size={10}>
          <OccupantViewModal id={i._id} />
          <OccupantManageModal id={i._id} />
        </Space>
      ),
    },
  ];
  let activeList = occupantData.filter(
    (element: any) => element.isActive === true
  );
  let inActiveList = occupantData.filter(
    (element: any) => element.isActive === false
  );

  const items = [
    {
      label: "Onboard Tenant",
      key: "1",
      children: (
        <Table
          size="small"
          columns={column}
          dataSource={activeList}
          scroll={{ y: "62vh" }}
        />
      ),
    },
    {
      label: "Offboard Tenant",
      key: "2",
      children: (
        <Table
          size="small"
          columns={column}
          dataSource={inActiveList}
          scroll={{ y: "62vh" }}
        />
      ),

      // disabled: true,
    },

    // {
    //   label: "All Tenant",
    //   key: "3",
    //   children: <Table size="small" columns={column} dataSource={occupant} />,
    // },
  ];
  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  return (
    <>
      <Segmented
        onChange={OnHostelSelect}
        options={[
          {
            label: "All",
            value: "All",
          },
          ...(hostel &&
            hostel?.map((item: any, i: number) => {
              return {
                label:
                  // <div style={{ padding: 4 }}>
                  //   {/* <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=8" /> */}
                  //   <div>User 1</div>
                  // </div>
                  item.hostelName,
                value: item.id,
              };
            })),
        ]}
      />

      <Tabs
        color={occupant.isActive === true ? "green" : "red"}
        defaultActiveKey="3"
        items={items}
      />
    </>
  );
};

export default OccupantList;
