import _ from "lodash";

import { authRoutes } from "./routes/auth-route";

import { blogCategoriesRoutes } from "./routes/blog-categories-route";
import { blogRoutes } from "./routes/blog-route";

import { testimonialRoutes } from "./routes/testimonial-route";
import { occupantRoutes } from "./routes/occupant-route";
import { roomRoutes } from "./routes/room-route";
import { paymentsRoutes } from "./routes/payment-route";
import { paymenttermsRoutes } from "./routes/payment-terms-route";
import { bookingRoutes } from "./routes/booking-route";
import { kitchenRoutes } from "./routes/kitchen-route";
import { imageRoutes } from "./routes/image-route";
import { hostelRoutes } from "./routes/hostel-route";
import { enquiryRoutes } from "./routes/enquiry-route";
import { facilityRoutes } from "./routes/facility-route";
import { billRoutes } from "./routes/bill-route";
import { ledgerRoutes } from "./routes/ledger-route";
import { partyRoutes } from "./routes/party-route";
import { expenseRoutes } from "./routes/expense-route";
import { dailyMenuRoutes } from "./routes/dailyMenu-route";
// import DailyMenuList from "../modules/Daily Menu/Component/List";
//const path = "https://api.patnarepair.com:8089";
//const path = "https://api.techabilititsolutions.com:8080";
const routes = {
  /**
   * @property {array} Array of all available routes
   */
  collection: [
    ...authRoutes,

    ...blogCategoriesRoutes,
    ...blogRoutes,

    ...testimonialRoutes,
    ...occupantRoutes,
    ...roomRoutes,
    ...paymentsRoutes,
    ...paymenttermsRoutes,
    ...bookingRoutes,
    ...kitchenRoutes,
    ...imageRoutes,
    ...hostelRoutes,
    ...enquiryRoutes,
    ...facilityRoutes,
    ...kitchenRoutes,
    ...billRoutes,
    ...ledgerRoutes,
    ...partyRoutes,
    ...expenseRoutes,
    ...dailyMenuRoutes,
  ],

  /**
   * Method used to generate route path from alias.
   *
   * @param {string} alias
   * @param {object} parameters
   */
  route: (alias: string, parameters: any = {}): any => {
    const routeCollection = _.cloneDeep(routes.collection);
    let route = _.find(routeCollection, { alias });
    if (route === undefined) {
      throw new Error(`Can't find route for alias: ${alias}`);
    }

    if (parameters) {
      for (const param in parameters) {
        if (!route.path.includes(param)) {
          throw new Error(
            `Route parameter: ${param} is not allowed in route path: ${route.path}`
          );
        }

        if (
          typeof parameters === "object" &&
          parameters.hasOwnProperty(param)
        ) {
          route.path = route.path.replace(`:${param}`, parameters[param]);
        }
      }
    }

    return route;
  },
};

export default routes;
