import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Input,
  Col,
  Switch,
  Space,
  Select,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  UserOutlined,
  MailOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";

import LocalStorageService from "../../../utils/local-storage";
import { CreateDailyMenuModalContext } from "../Modal/CreateDailyMenu";
import { type } from "os";
import TextArea from "antd/es/input/TextArea";
import { InputRules } from "../../../utils/InputRules";
import { getAllHostel } from "../../Hotel Detail/Requests";

const CreateDailyMenu: React.FC = () => {
  const modalContext = React.useContext(CreateDailyMenuModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const createDailyMenuMutation = useMutation(
    (values: any) => request("admin.dailyMenu.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("dailymenu");
      },
    }
  );
  const { data: hostel } = useQuery(["hostel"], async () => getAllHostel(), {
    refetchOnWindowFocus: false,
  });

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;

    setFormLoading(true);

    createDailyMenuMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The DailyMenu has been successfully created.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  return (
    <Form
      layout="horizontal"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Title"
        name="title"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "title",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter title",
          },
          { whitespace: true },
        ]}
        hasFeedback
      >
        <Input placeholder="Enter Title" />
      </Form.Item>
      <Form.Item
        label="Select Hostel"
        name="hostelId"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "hostelId",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Hostel ",
          },

          { whitespace: true },
        ]}
        hasFeedback
      >
        <Select
          showSearch
          placeholder="Select Hostel"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA!.children as unknown as string)
              .toLowerCase()
              .localeCompare(
                (optionB!.children as unknown as string).toLowerCase()
              )
          }
        >
          {hostel &&
            hostel?.map((item: any, i: number) => {
              return (
                <Select.Option value={item?.id} key={i}>
                  {item?.hostelName}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Monday"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Monday",
          icon: <InfoCircleOutlined />,
        }}
      >
        <Space direction="horizontal">
          <Form.Item
            name={["monday", "breakfast"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "breakfast",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter breakfast",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter  Breakfast" />
          </Form.Item>
          <Form.Item
            name={["monday", "lunch"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "lunch",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Lunch",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter lunch" />
          </Form.Item>
          <Form.Item
            name={["monday", "snacks"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "snacks",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Snacks",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Snacks" />
          </Form.Item>
          <Form.Item
            name={["monday", "dinner"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "dinner",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter Dinner",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Dinner" />
          </Form.Item>
        </Space>
      </Form.Item>
      <Form.Item
        label="Tuesday"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Tuesday",
          icon: <InfoCircleOutlined />,
        }}
      >
        <Space direction="horizontal">
          <Form.Item
            name={["tuesday", "breakfast"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "breakfast",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter breakfast",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter  Breakfast" />
          </Form.Item>
          <Form.Item
            name={["tuesday", "lunch"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "lunch",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Lunch",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter lunch" />
          </Form.Item>
          <Form.Item
            name={["tuesday", "snacks"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "snacks",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Snacks",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Snacks" />
          </Form.Item>
          <Form.Item
            name={["tuesday", "dinner"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "dinner",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter Dinner",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Dinner" />
          </Form.Item>
        </Space>
      </Form.Item>{" "}
      <Form.Item
        label="Wednesday"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Wednesday",
          icon: <InfoCircleOutlined />,
        }}
      >
        <Space direction="horizontal">
          <Form.Item
            name={["wednesday", "breakfast"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "breakfast",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter breakfast",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter  Breakfast" />
          </Form.Item>
          <Form.Item
            name={["wednesday", "lunch"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "lunch",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Lunch",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter lunch" />
          </Form.Item>
          <Form.Item
            name={["wednesday", "snacks"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "snacks",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Snacks",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Snacks" />
          </Form.Item>
          <Form.Item
            name={["wednesday", "dinner"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "dinner",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter Dinner",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Dinner" />
          </Form.Item>
        </Space>
      </Form.Item>{" "}
      <Form.Item
        label="Thursday"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Thursday",
          icon: <InfoCircleOutlined />,
        }}
      >
        <Space direction="horizontal">
          <Form.Item
            name={["thursday", "breakfast"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "breakfast",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter breakfast",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter  Breakfast" />
          </Form.Item>
          <Form.Item
            name={["thursday", "lunch"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "lunch",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Lunch",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter lunch" />
          </Form.Item>
          <Form.Item
            name={["thursday", "snacks"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "snacks",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Snacks",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Snacks" />
          </Form.Item>
          <Form.Item
            name={["thursday", "dinner"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "dinner",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter Dinner",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Dinner" />
          </Form.Item>
        </Space>
      </Form.Item>{" "}
      <Form.Item
        label="Friday"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Friday",
          icon: <InfoCircleOutlined />,
        }}
      >
        <Space direction="horizontal">
          <Form.Item
            name={["friday", "breakfast"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "breakfast",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter breakfast",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter  Breakfast" />
          </Form.Item>
          <Form.Item
            name={["friday", "lunch"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "lunch",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Lunch",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter lunch" />
          </Form.Item>
          <Form.Item
            name={["friday", "snacks"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "snacks",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Snacks",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Snacks" />
          </Form.Item>
          <Form.Item
            name={["friday", "dinner"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "dinner",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter Dinner",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Dinner" />
          </Form.Item>
        </Space>
      </Form.Item>
      <Form.Item
        label="Saturday"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Saturday",
          icon: <InfoCircleOutlined />,
        }}
      >
        <Space direction="horizontal">
          <Form.Item
            name={["saturday", "breakfast"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "breakfast",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter breakfast",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter  Breakfast" />
          </Form.Item>
          <Form.Item
            name={["saturday", "lunch"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "lunch",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Lunch",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter lunch" />
          </Form.Item>
          <Form.Item
            name={["saturday", "snacks"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "snacks",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Snacks",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Snacks" />
          </Form.Item>
          <Form.Item
            name={["saturday", "dinner"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "dinner",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter Dinner",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Dinner" />
          </Form.Item>
        </Space>
      </Form.Item>
      <Form.Item
        label="Sunday"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Sunday",
          icon: <InfoCircleOutlined />,
        }}
      >
        <Space direction="horizontal">
          <Form.Item
            name={["sunday", "breakfast"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "breakfast",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter breakfast",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter  Breakfast" />
          </Form.Item>
          <Form.Item
            name={["sunday", "lunch"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "lunch",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Lunch",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter lunch" />
          </Form.Item>
          <Form.Item
            name={["sunday", "snacks"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "snacks",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Snacks",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Snacks" />
          </Form.Item>
          <Form.Item
            name={["sunday", "dinner"]}
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "dinner",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter Dinner",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Dinner" />
          </Form.Item>
        </Space>
      </Form.Item>
      <Form.Item
        label="Status"
        style={{ fontWeight: "bold" }}
        name="isActive"
        tooltip={{
          title: "status",
          icon: <InfoCircleOutlined />,
        }}
        valuePropName="checked"
      >
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>
      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateDailyMenu;
