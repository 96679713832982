import { path } from "./path";

export const partyRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveParty",
    module: "party",
    alias: "admin.party.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateParty/:id",
    module: "party",
    alias: "admin.party.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllParty",
    module: "party",
    alias: "admin.party.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneParty/:id",
    module: "party",
    alias: "admin.party.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllActiveParty",
    module: "party",
    alias: "admin.active.party",
    isSubDomain: true,
  },
];
