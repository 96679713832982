import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Input,
  Col,
  Switch,
  Upload,
  UploadProps,
  Divider,
  Space,
  Typography,
  Tag,
  Card,
  Image,
  Empty,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  LinkOutlined,
  InstagramOutlined,
  FacebookOutlined,
  YoutubeOutlined,
  LinkedinOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  MailOutlined,
  PlusOutlined,
  TwitterOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";

import LocalStorageService from "../../../utils/local-storage";
import { CreateHostelModalContext } from "../Modal/CreateHostel";
import { type } from "os";
import { RcFile, UploadChangeParam, UploadFile } from "antd/es/upload";
import { InputRules } from "../../../utils/InputRules";
import TextArea from "antd/es/input/TextArea";
import { s3Path, uploadFileToAWS } from "../../../utils/s3Config/s3upload";
import Table, { ColumnsType } from "antd/es/table";
import ManageHostelModal from "../Modal/ManageHostel";
import HostelViewModal from "../Modal/ShowHotelDetails";
import { getAllHostel } from "../Requests";

interface DataType {
  id: number;
  name: any;
}
const CreateHostel: React.FC = () => {
  const modalContext = React.useContext(CreateHostelModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const [galley, setGallery] = React.useState<string>();

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const createHostelMutation = useMutation(
    (values: any) => request("admin.hostel.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("hostel");
      },
    }
  );
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["hostel"], () => getAllHostel(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    values.logoUrl = galley;

    setFormLoading(true);

    createHostelMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Hostel has been successfully created.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const column: ColumnsType<DataType> = [
    {
      title: "Images",
      dataIndex: "logoUrl",
      key: "logoUrl",
      render: (text: any) =>
        text ? (
          <Image width={50} height={50} src={s3Path + text} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ),
    },
    {
      title: "Hostel Name",
      dataIndex: "hostelName",
      key: "hostelName",
      render: (_: any, i: any) => (
        <Space.Compact direction="vertical">
          <Tag color="blue">{i?.hostelName}</Tag>

          <Tag color="cyan">{i?.address}</Tag>
        </Space.Compact>
      ),
    },

    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Typography.Text code type="success">
              +91-{i?.contact}
            </Typography.Text>

            <Typography.Text code type="warning">
              {i?.email}
            </Typography.Text>
          </Space.Compact>
        </Space>
      ),
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
    },

    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <ManageHostelModal id={id} />
          {/* <HostelViewModal id={id} /> */}
        </Space>
      ),
    },
  ];
  return (
    <Form
      layout="vertical"
      // initialValues={hostelData.Hostel}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <h3>Add Hostel</h3>
      <Card size="small">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="Hostel Name"
              name="hostelName"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "Hostel Name",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter your  Hostel Name",
                },
                { whitespace: true },
                { min: 3 },
              ]}
              hasFeedback
            >
              <Input placeholder="Enter Your Hostel Name" maxLength={40} />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="Mobile No"
              name="contact"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "Mobile No",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Enter Your  Mobile No",
                },
                { whitespace: true },
                ...InputRules.phoneNo,
              ]}
              hasFeedback
            >
              <Input
                maxLength={10}
                placeholder="Enter Your Mobile Number"
                addonBefore="+91"
                addonAfter={<MobileOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Email"
              name="email"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "Email",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter your Email",
                },
                { whitespace: true },
                { min: 3 },
                { type: "email", message: "Please enter a valid Email" },
              ]}
              hasFeedback
            >
              <Input
                addonBefore={<MailOutlined />}
                placeholder="example@gmail.com"
                maxLength={50}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Website"
              name="website"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "website",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter your  Website",
                },
                { whitespace: true },
                { min: 3 },
                { type: "url", message: "Please Enter Valid Url" },
              ]}
              hasFeedback
            >
              <Input placeholder="www.example.com" maxLength={50} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={9}>
            <Form.Item
              label="Address"
              name="address"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "Address",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter your  Address",
                },
                { whitespace: true },
                { min: 3 },
              ]}
              hasFeedback
            >
              <TextArea placeholder="Enter Your Address" maxLength={60} />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              label="Branch"
              name="branch"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "brance",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter your  Branch",
                },
                { whitespace: true },
                { min: 3 },
              ]}
              hasFeedback
            >
              <TextArea placeholder="enter your  Branch" maxLength={50} />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              label="Upload Image"
              style={{ fontWeight: "bold" }}
              name="logoUrl"
              valuePropName="checked"
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                accept="image/png, image/jpeg"
                action={async (file) => {
                  const response: any = await uploadFileToAWS(
                    file,
                    "hostellist"
                  );
                  setGallery(response.key);
                  return response.key;
                }}
                onChange={handleChange}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              label="Status"
              style={{ fontWeight: "bold" }}
              name="isActive"
              tooltip={{
                title: "status",
                icon: <InfoCircleOutlined />,
              }}
              valuePropName="checked"
            >
              <Switch
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                defaultChecked
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ textAlign: "center" }}>
          <Button
            size="large"
            loading={formLoading}
            type="primary"
            htmlType="submit"
            shape="round"
          >
            Submit
          </Button>
        </Form.Item>
      </Card>
      <h3>Hostel List</h3>
      <Card>
        <Table size="small" columns={column} dataSource={response} />
      </Card>
    </Form>
  );
};

export default CreateHostel;
