import { path } from "./path";

export const roomRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveRoom",
    module: "room",
    alias: "admin.room.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateRoom/:id",
    module: "room",
    alias: "admin.room.update",
    isSubDomain: true,
  },
  {
    method: "put",
    path: path + "/api/admin/unAssignedRoom/:id",
    module: "room",
    alias: "admin.room.unassign",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveRoom",
    module: "room",
    alias: "admin.room.list.active",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findRoomwithOccupant",
    module: "room",
    alias: "admin.room.list.occupant",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllRoom",
    module: "room",
    alias: "admin.room.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneRoom/:id",
    module: "room",
    alias: "admin.room.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneRoomDetail/:id",
    module: "room",
    alias: "admin.room.detail.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findRoomByHosteId/:id",
    module: "room",
    alias: "admin.room.hostel",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findRoomWithOccupantByHostelId/:id",
    module: "room",
    alias: "admin.room.hostelId",
    isSubDomain: true,
  },
];
