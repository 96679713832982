import { path } from "./path";

export const enquiryRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveEnquiry",
    module: "enquiry",
    alias: "admin.enquiry.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateEnquiry/:id",
    module: "enquiry",
    alias: "admin.enquiry.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveEnquiry",
    module: "enquiry",
    alias: "admin.enquiry.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllEnquiry",
    module: "enquiry",
    alias: "admin.enquiry.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneEnquiry/:id",
    module: "enquiry",
    alias: "admin.enquiry.id",
    isSubDomain: true,
  },
];
