import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Space, Table, Tag, Image, Empty, Typography } from "antd";

import { getAllBill, getBillByOccupant } from "../Requests/index";

import ManageBillModal from "../Modal/ManageBill";
import { s3Path } from "../../../utils/s3Config/s3upload";
import moment from "moment";
const { Text, Title } = Typography;
interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const BillListByOccupant: React.FC<{ id: string }> = (props) => {
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["bill", props.id], () => getBillByOccupant(props.id), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Bill Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD-MMM-YYYY"),
    },

    {
      title: "Period",
      dataIndex: "toDate",
      key: "toDate",
      render: (_: any, i: any) => (
        <>
          {moment(i.fromDate).format("DD-MMM-YY") +
            " to " +
            moment(i.toDate).format("DD-MMM-YY")}
        </>
      ),
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <Title level={4}>₹ {text}</Title>,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
  ];

  return <Table size="small" columns={column} dataSource={response} bordered />;
};

export default BillListByOccupant;
