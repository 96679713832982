import { aws } from "../keys";
import AWS from "aws-sdk";

const S3_BUCKET = "buckethms";
const REGION = "ap-south-1";

AWS.config.update({
  accessKeyId: aws.access,
  secretAccessKey: aws.secretkey,
});

// const myBucket = new AWS.S3({
//   params: { Bucket: S3_BUCKET },
//   region: REGION,
// });
export const s3Path = "https://buckethms.s3.ap-south-1.amazonaws.com/";
export const uploadFileToAWS = async (file: any, folder: any) => {
  // const date = Date.now();
  const id = crypto.randomUUID();
  const Key = folder + "/" + id;
  const params = {
    ACL: "public-read",
    Body: file,
    Bucket: S3_BUCKET,
    Key,
  };
  const s3 = new AWS.S3();

  const response = await s3.upload(params).promise();

  return response;
};

export const deleteFileToAWS = async (Key: any) => {
  const params = {
    Bucket: S3_BUCKET,
    Key,
  };
  const s3 = new AWS.S3();

  try {
    await s3.headObject(params).promise();
    console.log("File Found in S3");
    try {
      await s3.deleteObject(params).promise();
      console.log("File Deleted Successfully");
      return "File Deleted Successfully";
    } catch (err) {
      console.log("ERROR in file Deleting : " + JSON.stringify(err));
      return err;
    }
  } catch (err: any) {
    console.log("File not Found ERROR : " + err.code);
    return err;
  }
};
