import * as React from "react";
import {
  Form,
  Input,
  Button,
  Col,
  Row,
  Switch,
  Divider,
  Card,
  message,
  Space,
  Tag,
  Typography,
} from "antd";

import {
  LinkedinOutlined,
  InfoCircleOutlined,
  InstagramOutlined,
  MobileOutlined,
  MailOutlined,
  YoutubeOutlined,
  FacebookOutlined,
  TwitterOutlined,
  LinkOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
// import { queryClient } from "src";
import { createHostel, updateHostel } from "../Requests";
import { InputRules } from "../../../utils/InputRules";
import TextArea from "antd/es/input/TextArea";
import Upload, {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import { uploadFileToAWS } from "../../../utils/s3Config/s3upload";
import ManageHostelModal from "../../Hotel Detail/Modal/ManageHostel";
import HostelViewModal from "../../Hotel Detail/Modal/ShowHotelDetails";
import Table, { ColumnsType } from "antd/es/table";
import { getAllHostel } from "../../Hotel Detail/Requests";
import { useQuery } from "react-query";
// import LocalStorageService from 'src/utils/local-storage';
// import CookiesService from "src/utils/cookie";
interface DataType {
  id: number;
  name: any;
}
const CreateHostelForm: React.FC<{ id: string }> = (props) => {
  const [formLoading, setFormLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [galley, setGallery] = React.useState<string>();

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  // get hostel details
  // const {
  //   //isLoading,
  //   data: hostelData,
  //   //error,
  //   //isError,
  // } = useQuery(["hostel", props.id], async () => getHostelByUser(props.id), {
  //   refetchOnWindowFocus: false,
  //   // enabled: false,
  // });
  //hostelData.hostel.concernedPerson = hostelData.concernedPerson;
  // CookiesService.storeCompany(hostelData.Hostel.id);
  // console.log(LocalStorageService.gethostel(), "==>");

  // save or,update hostel Mutation
  // const hostelMutation = useMutation(
  //   (values: any) =>
  //     hostelData
  //       ? updateHostel(hostelData.Hostel.id, values)
  //       : createHostel(props.id, values),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries("hostel");
  //     },
  //   }
  // );
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["hostel"], () => getAllHostel(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  const onFinish = async (values: any) => {
    setFormLoading(true);
    // hostelMutation
    //   .mutateAsync(values)
    //   .then((payload: any) => {
    //     setFormLoading(false);
    //     message.success(payload.message);
    //     // modalContext.setIsModalVisible(false);
    //   })
    // .catch((e: any) => {
    //   setFormLoading(false);
    //   message.error(e);
    // });
  };
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  //   if (isLoading) return <div>Loading...</div>;
  //   if (isError || error) return <h2>Something went Wrong...</h2>;
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const column: ColumnsType<DataType> = [
    // {
    //   title: "Images",
    //   dataIndex: "logoUrl",
    //   key: "logoUrl",
    //   render: (text: any) =>
    //     text ? (
    //       <Image width={100} height={100} src={s3Path + text} />
    //     ) : (
    //       <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    //     ),
    // },
    {
      title: "Hostel Name",
      dataIndex: "hostelName",
      key: "hostelName",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Typography.Text type="success">+91-{i?.contact}</Typography.Text>

            <Typography.Text type="warning">{i?.email}</Typography.Text>

            <Typography.Text type="danger">{i?.website}</Typography.Text>
          </Space.Compact>
        </Space>
      ),
    },

    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <ManageHostelModal id={id} />
          <HostelViewModal id={id} />
        </Space>
      ),
    },
  ];
  return (
    <Form
      layout="vertical"
      // initialValues={hostelData.Hostel}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Card size="small">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="Hostel Name"
              name="hostelName"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "Hostel Name",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter your  Hostel Name",
                },
                { whitespace: true },
                { min: 3 },
              ]}
              hasFeedback
            >
              <Input placeholder="Enter Your Hostel Name" maxLength={40} />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="Mobile No"
              name="contact"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "Mobile No",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Enter Your  Mobile No",
                },
                { whitespace: true },
                ...InputRules.phoneNo,
              ]}
              hasFeedback
            >
              <Input
                maxLength={10}
                placeholder="Enter Your Mobile Number"
                addonBefore="+91"
                addonAfter={<MobileOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Email"
              name="email"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "Email",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter your Email",
                },
                { whitespace: true },
                { min: 3 },
                { type: "email", message: "Please enter a valid Email" },
              ]}
              hasFeedback
            >
              <Input
                addonBefore={<MailOutlined />}
                placeholder="example@gmail.com"
                maxLength={50}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Website"
              name="website"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "website",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter your  Website",
                },
                { whitespace: true },
                { min: 3 },
                { type: "url", message: "Please Enter Valid Url" },
              ]}
              hasFeedback
            >
              <Input placeholder="www.example.com" maxLength={50} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={9}>
            <Form.Item
              label="Address"
              name="address"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "Address",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter your  Address",
                },
                { whitespace: true },
                { min: 3 },
              ]}
              hasFeedback
            >
              <TextArea placeholder="Enter Your Address" maxLength={60} />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              label="Branch"
              name="branch"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "brance",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter your  Branch",
                },
                { whitespace: true },
                { min: 3 },
              ]}
              hasFeedback
            >
              <TextArea placeholder="enter your  Branch" maxLength={50} />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              label="Upload Image"
              style={{ fontWeight: "bold" }}
              name="logoUrl"
              valuePropName="checked"
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                accept="image/png, image/jpeg"
                action={async (file) => {
                  const response: any = await uploadFileToAWS(
                    file,
                    "hostellogo"
                  );
                  setGallery(response.key);
                  return response.key;
                }}
                onChange={handleChange}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              label="Status"
              style={{ fontWeight: "bold" }}
              name="isActive"
              tooltip={{
                title: "status",
                icon: <InfoCircleOutlined />,
              }}
              valuePropName="checked"
            >
              <Switch
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                defaultChecked
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ textAlign: "center" }}>
          <Button
            size="large"
            loading={formLoading}
            type="primary"
            htmlType="submit"
            shape="round"
          >
            Submit
          </Button>
        </Form.Item>
      </Card>
      <Card>
        <Table size="small" columns={column} dataSource={response} />
      </Card>
    </Form>
  );
};

export default CreateHostelForm;
