import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Col, Row, Space, Table, Tag, Typography } from "antd";

import { getAllPaymentTerms } from "../Requests";
import { CaretRightOutlined } from "@ant-design/icons";

import ManagePaymentTermsModal from "../Modal/ManagePaymentTerms";
import { wrap } from "module";
const { Text } = Typography;

interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const PaymentTermsList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["paymentTerms"], () => getAllPaymentTerms(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequency",
    },
    {
      title: "Food",
      dataIndex: "food",
      key: "food",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_: any, i: any) => (
        <>
          {i?.description?.map((item: any, i: number) => {
            return (
              <Row>
                <Text code style={{ margin: 2 }} type="danger">
                  {item}{" "}
                </Text>
              </Row>
            );
          })}
        </>
      ),
    },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <ManagePaymentTermsModal id={id} />
        </Space>
      ),
    },
  ];

  return (
    <Table
      size="small"
      columns={column}
      dataSource={response}
      scroll={{ y: "72vh" }}
    />
  );
};

export default PaymentTermsList;
