import { path } from "./path";

export const paymentsRoutes = [
  {
    method: "post",
    path: path + "/api/admin/savePayment",
    module: "payment",
    alias: "admin.payment.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updatePayment/:id",
    module: "payment",
    alias: "admin.payment.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActivePayment",
    module: "payment",
    alias: "admin.payment.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllPayment",
    module: "payment",
    alias: "admin.payment.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOnePayment/:id",
    module: "payment",
    alias: "admin.payment.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findPaymentByOccupantId/:id",
    module: "payment",
    alias: "admin.payment.occupant",
    isSubDomain: true,
  },
];
