import { path } from "./path";

export const billRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createBill",
    module: "bill",
    alias: "admin.bill.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateBill/:id",
    module: "bill",
    alias: "admin.bill.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllBill",
    module: "bill",
    alias: "admin.bill.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneBill/:id",
    module: "bill",
    alias: "admin.bill.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findBillByOccupantId/:id",
    module: "bill",
    alias: "admin.bill.occupant",
    isSubDomain: true,
  },
];
