import { TRoute } from "../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const EnquiryRouter: TRoute[] = [
  {
    path: "/dailymenu",
    name: "dailymenu",
    title: "dailymenu",
    component: DepartmentPageHome,
  },
];

export default EnquiryRouter;
