import { TRoute } from "../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const BookingRouter: TRoute[] = [
  {
    path: "/booking",
    name: "booking",
    title: "booking",
    component: DepartmentPageHome,
  },
];

export default BookingRouter;
