import { request } from "../../../utils/services";

export const getAllActiveParty = async () => {
  const { data } = await request("admin.active.party");
  return data;
};

export const getAllParty = async () => {
  const { data } = await request("admin.party.list");
  return data;
};

export const getPartyById = async (id: string) => {
  const { data } = await request("admin.party.id", { id });
  return data;
};
