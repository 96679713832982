import * as React from "react";

import { Modal, Button, Tooltip } from "antd";

import { EyeOutlined } from "@ant-design/icons";
import ShowBlogCategoriesDetailsForm from "../Form/ShowBlogCategoriesForm";
export const ShowBlogCategoriesDetailsModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const BlogCategoriesViewModal: React.FC<{ id: string }> = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ShowBlogCategoriesDetailsModalContext.Provider
      value={{ setIsModalVisible }}
    >
      <Tooltip title="View All Details">
        <Button
          onClick={showModal}
          type="primary"
          shape="circle"
          icon={<EyeOutlined />}
        />
      </Tooltip>
      <Modal
        width={1000}
        title=" View BlogCategories Details"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ShowBlogCategoriesDetailsForm id={id} />
      </Modal>
    </ShowBlogCategoriesDetailsModalContext.Provider>
  );
};

export default BlogCategoriesViewModal;
