import { path } from "./path";

export const paymenttermsRoutes = [
  {
    method: "post",
    path: path + "/api/admin/savePaymentTerms",
    module: "paymentTerms",
    alias: "admin.paymentTerms.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updatePaymentTerms/:id",
    module: "paymentTerms",
    alias: "admin.paymentTerms.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActivePaymentTerms",
    module: "paymentTerms",
    alias: "admin.paymentTerms.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllPaymentTerms",
    module: "paymentTerms",
    alias: "admin.paymentTerms.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOnePaymentTerms/:id",
    module: "paymentTerms",
    alias: "admin.paymentTerms.id",
    isSubDomain: true,
  },
];
