import * as React from "react";

import { Modal, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CreateEnquiry from "../Form/CreateEnquiryForm";

export const CreateEnquiryModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const CreateEnquiryModal: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <CreateEnquiryModalContext.Provider value={{ setIsModalVisible }}>
      <Button
        onClick={showModal}
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
      >
        Add Enquiry
      </Button>
      <Modal
        title="Generate Enquiry"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        <CreateEnquiry />
      </Modal>
    </CreateEnquiryModalContext.Provider>
  );
};

export default CreateEnquiryModal;
