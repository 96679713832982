import { request } from "../../../../utils/services";

export const getAllActiveblogCategories = async () => {
  const { data } = await request("admin.blogCategories.list.active");
  return data;
};

export const getAllblogCategories = async () => {
  const { data } = await request("admin.blogCategories.list");
  return data;
};

export const getblogCategoriesById = async (id: string) => {
  const { data } = await request("admin.blogCategories.id", { id });
  return data;
};
