import { Row } from "antd";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";
import OccupantList from "../Component/Home/List";
import CreateOccupantModal from "../Modal/CreateOccupant";

const OccupantPageHome: React.FC<RouteComponentProps> = () => {
  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between">
              <h2>Tenant List</h2>
              <CreateOccupantModal />
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <OccupantList />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(OccupantPageHome);
