import { TRoute } from "../../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const BlogCategoriesRouter: TRoute[] = [
  {
    path: "/blogCategories",
    name: "blogCategories",
    title: "blogCategories",
    component: DepartmentPageHome,
  },
];

export default BlogCategoriesRouter;
