import * as React from "react";
import {
  Form,
  Button,
  message,
  Select,
  Row,
  Switch,
  Col,
  Input,
  Divider,
} from "antd";
import { request } from "../../../../utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../../..";
import { ManageBlogModalContext } from "../Modal/ManageBlog";

import { useQuery } from "react-query";

import TextArea from "antd/lib/input/TextArea";

import { getBlogById } from "../Requests";

import { getAllblogCategories } from "../../Blog Categories/Requests";
import CreateBlogCategoriesModal from "../../Blog Categories/Modal/CreateBlogCategories";
import { InputRules } from "../../../../utils/InputRules";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";

const ManageBlogForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageBlogModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [galley, setGallery] = React.useState<string>();

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const manageBlogMutation = useMutation(
    (values: any) => request("admin.blog.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Blog");
      },
    }
  );
  const {
    isLoading,
    data: blog,
    error,
    isError,
  } = useQuery(["blog", props.id], async () => getBlogById(props.id), {
    refetchOnWindowFocus: false,
  });
  const { data: blogCategory } = useQuery(
    ["blogCategory"],
    async () => getAllblogCategories(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const onFinish = async (values: any) => {
    setFormLoading(true);

    manageBlogMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Blog has been successfully managed.");
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={blog}
    >
      <Row gutter={15}>
        <Col flex={2} span={12}>
          <Form.Item
            label="Title"
            name="title"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Title",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Your Title",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Your Titile" maxLength={20} />
          </Form.Item>
        </Col>
        <Col flex={2} span={12}>
          <Form.Item
            label="Blog Category"
            style={{ fontWeight: "bold" }}
            name="blogCategoriesId"
            tooltip={{
              title: "Blog Categories",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Select
              showSearch
              placeholder="Select Blog Category"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA!.children as unknown as string)
                  .toLowerCase()
                  .localeCompare(
                    (optionB!.children as unknown as string).toLowerCase()
                  )
              }
              dropdownRender={(menu) => (
                <>
                  <CreateBlogCategoriesModal />

                  {menu}
                </>
              )}
            >
              {blogCategory &&
                blogCategory?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.id} key={i}>
                      {item?.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Permalink"
            name="permaLink"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "permaLink",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter permaLink",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter PermaLink" maxLength={20} />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Image Title"
            name="imageTitle"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Image Title",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Image Title",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Image Title" maxLength={25} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Author Name"
            name="author"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "author",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Author name",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Author Name" maxLength={20} />
          </Form.Item>
        </Col>
        {/* <Col flex={5} span={12}>
          <Form.Item
            label="Upload Image"
            style={{ fontWeight: "bold" }}
            name="fileUrl"
            valuePropName="checked"
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              accept="image/png, image/jpeg"
              action={async (file) => {
                const response: any = await uploadFileToAWS(file, "blog");
                setGallery(response.key);
                return response.key;
              }}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
        </Col> */}
      </Row>

      <Form.Item
        label="Content"
        name="content"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Content",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Your Content",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <TextArea placeholder="Enter Content" maxLength={1000} />
      </Form.Item>
      <Divider />

      <h3>For SEO Purpose</h3>
      <Divider />
      <Row gutter={15}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Meta Title"
            name="metaTitle"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Meta Title",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Meta Title",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Meta Titile" maxLength={25} />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Meta Keyword"
            name="metaKeyword"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Meta Keyword",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter Meta Keyword",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Meta Keyword" maxLength={20} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Meta Discription"
        name="metaDiscription"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Meta Discription",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please enter Meta Discription",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <TextArea placeholder="Enter Meta Discription" maxLength={500} />
      </Form.Item>
      <Row gutter={20}>
        <Col flex={2} span={12}>
          <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
            // rules={[{ required: true }]}
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageBlogForm;
