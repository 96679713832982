import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Space, Table, Tag, Typography } from "antd";

import { getAllPayment } from "../Requests/index";

import PaymentViewModal from "../Modal/ShowPaymentDetails";

const { Text, Title } = Typography;
interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const PaymentList: React.FC = () => {
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["payment"], () => getAllPayment(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Payment No",
      dataIndex: "seq",
      key: "seq",
      render: (text: any) => <Tag>PRH/Payment/{text}</Tag>,
    },
    {
      title: "Occupant Detail",
      dataIndex: "occupantId",
      key: "occupantId",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Text style={{ fontSize: 16, fontWeight: "bold" }}>
              {i?.occupantId?.name}
            </Text>
            <Text style={{ fontStyle: "italic" }} code type="success">
              +91-{i?.occupantId?.phoneNo}
            </Text>
          </Space.Compact>
        </Space>
      ),
    },
    {
      title: "Bill Detail",
      dataIndex: "billId",
      key: "billId",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Tag>PRH/Bills/{i?.billId[0]?.seq}</Tag>
            <Text style={{ fontSize: 16, fontWeight: "bold" }}>
              ₹ {i?.billId[0]?.amount}
            </Text>
            {/* <Text style={{ fontStyle: "italic" }} code type="success">
              +91-{i?.occupantId?.phoneNo}
            </Text> */}
          </Space.Compact>
        </Space>
      ),
    },

    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (text) => <Tag color="blue"> {text}</Tag>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <Title level={4}>₹ {text}</Title>,
    },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <PaymentViewModal id={id} />
          {/* <ManageBlogCategoriesModal id={id} /> */}
        </Space>
      ),
    },
  ];

  return (
    <Table
      size="small"
      columns={column}
      dataSource={response}
      // scroll={{ y: 400 }}
      scroll={{ y: "72vh" }}
    />
  );
};

export default PaymentList;
