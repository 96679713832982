import * as React from "react";
import { Descriptions, Card, Divider } from "antd";
import { useQuery } from "react-query";
import { getBookingByIdDetail } from "../Requests";

const ShowBookingDetailsForm: React.FC<{ id: string }> = (props) => {
  const { data: booking } = useQuery(
    ["booking", props.id],
    async () => getBookingByIdDetail(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );

  return (
    <Card>
      <h3>Registration Date: {booking.registrationDate}</h3>
      <Divider orientation="left">Occupant Info</Divider>

      <Descriptions
      // title="Occupant Info"
      // extra={<BookingManageModal id={booking.id} />}
      >
        <Descriptions.Item label="Occupant Name">
          {booking.occupantId.name}
        </Descriptions.Item>
        <Descriptions.Item label="Education">
          {booking.occupantId.education}
        </Descriptions.Item>
        <Descriptions.Item label="Mobile">
          +91-{booking.occupantId.phoneNo}
        </Descriptions.Item>
        <Descriptions.Item label="Age">
          {booking.occupantId.age}
        </Descriptions.Item>
        <Descriptions.Item label="Gender">
          {booking.occupantId.gender}
        </Descriptions.Item>
        <Descriptions.Item label="Work Place">
          {booking.occupantId.workPlace}
        </Descriptions.Item>
        <Descriptions.Item label="Address">
          {booking.occupantId.address}
        </Descriptions.Item>
        <Descriptions.Item label="Adhar Number">
          {booking.occupantId.adharNo}
        </Descriptions.Item>
        <Descriptions.Item label="Occupation">
          {booking.occupantId.occupation}
        </Descriptions.Item>
        <Descriptions.Item label="Reference">
          {booking.occupantId.reference}
        </Descriptions.Item>
        <Descriptions.Item label="Remarks">
          {booking.occupantId.remarks}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Family Detail</Divider>
      <Descriptions>
        <Descriptions.Item label="Parent Name">
          {booking.occupantId.parentName}
        </Descriptions.Item>
        <Descriptions.Item label="Parent Address">
          {booking.occupantId.paddress}
        </Descriptions.Item>
        <Descriptions.Item label="Mobile">
          +91-{booking.occupantId.pmobNo}
        </Descriptions.Item>

        <Descriptions.Item label="Relation">
          {booking.occupantId.relation}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Room Detail</Divider>
      <Descriptions>
        <Descriptions.Item label="Room Number">
          {booking.roomId.roomNo}
        </Descriptions.Item>
        <Descriptions.Item label="Floor">
          {booking.roomId.floor}
        </Descriptions.Item>
        <Descriptions.Item label="Size">
          {booking.roomId.size}
        </Descriptions.Item>

        <Descriptions.Item label="Occupancy">
          {booking.roomId.occupancy}
        </Descriptions.Item>
        <Descriptions.Item label="Availability">
          {booking.roomId.availability}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Payment Terms Detail</Divider>
      <Descriptions>
        <Descriptions.Item label="Title">
          {booking.paymentTermsId.title}
        </Descriptions.Item>
        <Descriptions.Item label="price">
          {booking.paymentTermsId.price}
        </Descriptions.Item>
        <Descriptions.Item label="Frequency">
          {booking.paymentTermsId.frequency}
        </Descriptions.Item>

        <Descriptions.Item label="Food">
          {booking.paymentTermsId.food}
        </Descriptions.Item>
        <Descriptions.Item label="Discription">
          {booking.paymentTermsId.discription}
        </Descriptions.Item>
      </Descriptions>
    </Card>
    // </Card>
  );
};

export default ShowBookingDetailsForm;
