import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Space, Table, Tag, Image, Empty, Typography, Row } from "antd";

import { getAllBill } from "../Requests/index";

import ManageBillModal from "../Modal/ManageBill";
import { s3Path } from "../../../utils/s3Config/s3upload";
import moment from "moment";
import BillViewModal from "../Modal/ShowBill";
const { Text, Title } = Typography;
interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const BillList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["bill"], () => getAllBill(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Bill No",
      dataIndex: "seq",
      key: "seq",
      render: (text: any) => <Tag>PRH/Bill/{text}</Tag>,
    },
    {
      width: "12%",
      align: "center",
      title: "Bill Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Occupant",
      dataIndex: "occupantId",
      align: "center",
      key: "occupantId",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Text style={{ fontSize: 16, fontWeight: "bold" }}>
              {i?.occupantId?.name}
            </Text>
            <Text style={{ fontStyle: "italic" }} code type="success">
              +91-{i?.occupantId?.phoneNo}
            </Text>
          </Space.Compact>
        </Space>
      ),
    },

    {
      title: "Period",
      dataIndex: "toDate",
      width: "15%",
      key: "toDate",
      render: (_: any, i: any) => (
        <>
          <Text code>{moment(i.fromDate).format("DD-MMM-YY")} </Text> to
          <Text code>{moment(i.toDate).format("DD-MMM-YY")}</Text>
        </>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      width: "15%",
      key: "dueDate",
      render: (_: any, i: any) => (
        <Text code>{moment(i.dueDate).format("DD-MMM-YY")} </Text>
      ),
    },
    // {
    //   title: "Fee Type",
    //   dataIndex: "item",
    //   key: "item",
    //   render: (_: any, i: any) => (
    //     <>
    //       {i?.item?.map((item: any, i: number) => {
    //         return (
    //           <Row>
    //             <Text code style={{ margin: 2 }} type="danger">
    //               {item?.particular}
    //             </Text>
    //           </Row>
    //         );
    //       })}
    //     </>
    //   ),
    // },

    {
      width: "12%",
      title: "Amount",
      dataIndex: "amount",
      align: "center",
      key: "amount",
      render: (text) => <Title level={4}>₹ {text}</Title>,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      align: "center",
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => <Space size={10}>{<BillViewModal id={id} />}</Space>,
    },
  ];

  return (
    <Table
      size="small"
      columns={column}
      dataSource={response}
      scroll={{ y: "68vh" }}
    />
  );
};

export default BillList;
