import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Col,
  Switch,
  Input,
  Divider,
  Select,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { queryClient } from "../../..";

import { useQuery } from "react-query";

import TextArea from "antd/lib/input/TextArea";

import { getImageById } from "../Requests";
import moment from "moment";
import Upload, { RcFile, UploadFile, UploadProps } from "antd/es/upload";
import { ManageImageModalContext } from "../Modal/ManageImage";
const { Text } = Typography;
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
const ManageImageForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageImageModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const manageImageMutation = useMutation(
    (values: any) => request("admin.image.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Image");
      },
    }
  );
  const {
    isLoading,
    data: image,
    error,
    isError,
  } = useQuery(["image", props.id], async () => getImageById(props.id), {
    refetchOnWindowFocus: false,
  });

  const onFinish = async (values: any) => {
    setFormLoading(true);

    manageImageMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Image has been successfully managed.");
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState("");
  const [previewTitle, setPreviewTitle] = React.useState("");
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };
  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  image.equiryDate = moment(image.equiryDate, "YYYY-MM-DD");
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={image}
    >
      <Row gutter={20}>
        <Col flex={5} span={12}>
          <Form.Item
            label=" Image Type"
            name="type"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Image Type",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Select Image Type",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Select
              placeholder="Select Image"
              options={[
                { value: "gallery", label: "Gallery" },
                { value: "banner", label: "Banner" },
                { value: "offer", label: "Offer" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col flex={5} span={12}>
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {fileList.length >= 8 ? null : uploadButton}
          </Upload>
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Update Image
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageImageForm;
