import * as React from "react";
import CreateOccupantForm from "../Form/CreateOccupantForm";
import { Modal, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import StepperOccupantForm from "../Form/StepperOccupantForm";

export const CreateOccupantModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const CreateOccupantModal: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <CreateOccupantModalContext.Provider value={{ setIsModalVisible }}>
      <Button
        onClick={showModal}
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
      >
        Add Tenant
      </Button>
      <Modal
        title="Create Tenant"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        centered
      >
        <CreateOccupantForm />
        {/* <StepperOccupantForm /> */}
      </Modal>
    </CreateOccupantModalContext.Provider>
  );
};

export default CreateOccupantModal;
