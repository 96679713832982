import { request } from "../../../utils/services";

export const getAllActiveRoom = async () => {
  const { data } = await request("admin.room.list.active");
  return data;
};

export const getAllRoom = async () => {
  const { data } = await request("admin.room.list");
  return data;
};
export const getAllRoomWithOccupantDetails = async () => {
  const { data } = await request("admin.room.list.occupant");
  return data;
};

export const getRoomById = async (id: string) => {
  const { data } = await request("admin.room.id", { id });
  return data;
};
export const getRoomByIdDetail = async (id: string) => {
  const { data } = await request("admin.room.detail.id", { id });
  return data;
};
export const getRoomByHostelId = async (id: string) => {
  const { data } = await request("admin.room.hostel", { id });
  return data;
};
export const getRoomWithOccupantByHostelId = async (id: string) => {
  const { data } = await request("admin.room.hostelId", { id });
  return data;
};
