import { request } from "../../../utils/services";

export const getAllActivePayment = async () => {
  const { data } = await request("admin.payment.list.active");
  return data;
};

export const getAllPayment = async () => {
  const { data } = await request("admin.payment.list");
  return data;
};

export const getPaymentById = async (id: string) => {
  const { data } = await request("admin.payment.id", { id });
  return data;
};

export const getPaymentByOccupant = async (id: string) => {
  const { data } = await request("admin.payment.occupant", { id });
  return data;
};

export const getLedgerByOccupant = async (id: string) => {
  const { data } = await request("admin.ledger.occupant", { id });
  return data;
};
