import * as React from "react";
import { Descriptions, Card, Divider, Typography, Table, Row } from "antd";
import { useQuery } from "react-query";
import { getDailyMenuById, getDailyMenuIdWithDetail } from "../Requests";
import ColumnGroup from "antd/es/table/ColumnGroup";
import Column from "antd/es/table/Column";

const ShowDailyMenuForm: React.FC<{ id: string }> = (props) => {
  const { data: dailymenu } = useQuery(
    ["dailymenu", props.id],
    async () => getDailyMenuIdWithDetail(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );
  // src/components/DayMenu.tsx



console.log(dailymenu)


  return (
    //  <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
    //         <Menu weeklyMenu={dailymenu} />
    //     </div>
    <Card>
      <div style={{ textAlign: "center" }}>
        <Typography.Title level={3} style={{ fontWeight: "bold" }}>
          {dailymenu?.hostelId?.hostelName}
        </Typography.Title>
      </div>

      <div>
        <table style={{ border: 2, width: "100%" }}>
          <thead>
            <tr>
              <th style={{ border: 2 }}>Week</th>
              <th style={{ border: 2 }}>Breakfast</th>
              <th style={{ border: 2 }}>Lunch</th>
              <th style={{ border: 2 }}>Snacks</th>
              <th style={{ border: 2 }}>Dinner</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: "2px" }}>Monday</td>
              <td style={{ border: "2px" }}>{dailymenu?.monday?.breakfast}</td>
              <td style={{ border: "2px" }}>{dailymenu?.monday?.lunch}</td>
              <td style={{ border: "2px" }}>{dailymenu?.monday?.snacks}</td>
              <td style={{ border: "2px" }}>{dailymenu?.monday?.dinner}</td>
            </tr>
            <tr>
              <td style={{ border: "2px" }}>Tuesday</td>
              <td style={{ border: "2px" }}>{dailymenu?.tuesday?.breakfast}</td>
              <td style={{ border: "2px" }}>{dailymenu?.tuesday?.lunch}</td>
              <td style={{ border: "2px" }}>{dailymenu?.tuesday?.snacks}</td>
              <td style={{ border: "2px" }}>{dailymenu?.tuesday?.dinner}</td>
            </tr>{" "}
            <tr>
              <td style={{ border: "2px" }}>Wednesday</td>
              <td style={{ border: "2px" }}>{dailymenu?.wednesday?.breakfast}</td>
              <td style={{ border: "2px" }}>{dailymenu?.wednesday?.lunch}</td>
              <td style={{ border: "2px" }}>{dailymenu?.wednesday?.snacks}</td>
              <td style={{ border: "2px" }}>{dailymenu?.wednesday?.dinner}</td>
            </tr>{" "}
            <tr>
              <td style={{ border: "2px" }}>Thursday</td>
              <td style={{ border: "2px" }}>{dailymenu?.thursday?.breakfast}</td>
              <td style={{ border: "2px" }}>{dailymenu?.thursday?.lunch}</td>
              <td style={{ border: "2px" }}>{dailymenu?.thursday?.snacks}</td>
              <td style={{ border: "2px" }}>{dailymenu?.thursday?.dinner}</td>
            </tr>{" "}
            <tr>
              <td style={{ border: "2px" }}>Friday</td>
              <td style={{ border: "2px" }}>{dailymenu?.friday?.breakfast}</td>
              <td style={{ border: "2px" }}>{dailymenu?.friday?.lunch}</td>
              <td style={{ border: "2px" }}>{dailymenu?.friday?.snacks}</td>
              <td style={{ border: "2px" }}>{dailymenu?.friday?.dinner}</td>
            </tr>{" "}
            <tr>
              <td style={{ border: "2px" }}>Saturday</td>
              <td style={{ border: "2px" }}>{dailymenu?.saturday?.breakfast}</td>
              <td style={{ border: "2px" }}>{dailymenu?.saturday?.lunch}</td>
              <td style={{ border: "2px" }}>{dailymenu?.saturday?.snacks}</td>
              <td style={{ border: "2px" }}>{dailymenu?.saturday?.dinner}</td>
            </tr>{" "}
            <tr>
              <td style={{ border: "2px" }}>Sunday</td>
              <td style={{ border: "2px" }}>{dailymenu?.sunday?.breakfast}</td>
              <td style={{ border: "2px" }}>{dailymenu?.sunday?.lunch}</td>
              <td style={{ border: "2px" }}>{dailymenu?.sunday?.snacks}</td>
              <td style={{ border: "2px" }}>{dailymenu?.sunday?.dinner}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* 
      <Card>
        <Table>
          <ColumnGroup>
            <Column title="Week"></Column>

            <Column title="Breakfast"></Column>
            <Column title="Lunch"></Column>
            <Column title="Snacks"></Column>
            <Column title="Dinner"></Column>
          </ColumnGroup>
        </Table>
      </Card> */}
    </Card>
  );
};

export default ShowDailyMenuForm;

interface DayMenuProps {
    day: string;
    meals: {
        breakfast: string;
      lunch: string;
      snack: string;
        dinner: string;
    };
}

const DayMenu: React.FC<DayMenuProps> = ({ day, meals }) => {
    return (
        <div style={{ border: '1px solid #ddd', marginBottom: '10px', padding: '10px', borderRadius: '5px' }}>
            <h3>{day}</h3>
            <p><strong>Breakfast:</strong> {meals.breakfast}</p>
            <p><strong>Lunch:</strong> {meals.lunch}</p>
            <p><strong>Dinner:</strong> {meals.dinner}</p>
        </div>
    );
};


// interface MenuProps {
//     weeklyMenu: {
//         [key: string]: {
//             breakfast: string;
//             lunch: string;
//             dinner: string;
//         };
//     };
// }
// const Menu: React.FC<MenuProps> = ({ weeklyMenu }) => {
//     return (
//         <div>
//             <h2>Weekly Hostel Menu</h2>
//             {Object.keys(weeklyMenu).map((day) => (
//                 <DayMenu key={day} day={day} meals={weeklyMenu[day]} />
//             ))}
//         </div>
//     );
// };
