import { TRoute } from "../../utils/routes";
import OccupantPageHome from "./Page/Home";

const OccupantRouter: TRoute[] = [
  {
    path: "/tenant",
    name: "tenant",
    title: "tenant",
    component: OccupantPageHome,
  },
];

export default OccupantRouter;
