import * as React from "react";
import { Form, Button, message, Row, Col, Switch, Input, Divider } from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import {
  LinkOutlined,
  InstagramOutlined,
  FacebookOutlined,
  YoutubeOutlined,
  LinkedinOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  MailOutlined,
  PlusOutlined,
  TwitterOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";

import { useQuery } from "react-query";

import { getHostelById } from "../Requests";
import moment from "moment";

import { ManageHostelModalContext } from "../Modal/ManageHostel";
import Upload, {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import TextArea from "antd/es/input/TextArea";
import { InputRules } from "../../../utils/InputRules";
import { uploadFileToAWS } from "../../../utils/s3Config/s3upload";

const ManageHostelForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageHostelModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState<string>();
  const [galley, setGallery] = React.useState<string>();

  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const manageHostelMutation = useMutation(
    (values: any) => request("admin.hostel.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("hostel");
      },
    }
  );

  const {
    isLoading,
    data: hostel,
    error,
    isError,
  } = useQuery(["hostel", props.id], async () => getHostelById(props.id), {
    refetchOnWindowFocus: false,
  });
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const onFinish = async (values: any) => {
    values.logoUrl = galley;

    setFormLoading(true);

    manageHostelMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Hostel has been successfully managed.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={hostel}
    >
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item
            label="Hostel Name"
            name="hostelName"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Hostel Name",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter your  Hostel Name",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Your Hostel Name" maxLength={40} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label="Mobile No"
            name="contact"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Mobile No",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Enter Your  Mobile No",
              },
              { whitespace: true },
              ...InputRules.phoneNo,
            ]}
            hasFeedback
          >
            <Input
              maxLength={10}
              placeholder="Enter Your Mobile Number"
              addonBefore="+91"
              addonAfter={<MobileOutlined />}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Email"
            name="email"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Email",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter your Email",
              },
              { whitespace: true },
              { min: 3 },
              { type: "email", message: "Please enter a valid Email" },
            ]}
            hasFeedback
          >
            <Input
              addonBefore={<MailOutlined />}
              placeholder="example@gmail.com"
              maxLength={50}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Website"
            name="website"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "website",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter your  Website",
              },
              { whitespace: true },
              { min: 3 },
              { type: "url", message: "Please Enter Valid Url" },
            ]}
            hasFeedback
          >
            <Input placeholder="www.example.com" maxLength={50} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={9}>
          <Form.Item
            label="Address"
            name="address"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Address",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter your  Address",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <TextArea placeholder="Enter Your Address" maxLength={60} />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item
            label="Branch"
            name="branch"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "brance",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter your  Branch",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <TextArea placeholder="enter your  Branch" maxLength={50} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="Upload Image"
            style={{ fontWeight: "bold" }}
            name="logoUrl"
            valuePropName="checked"
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              accept="image/png, image/jpeg"
              action={async (file) => {
                const response: any = await uploadFileToAWS(file, "hostellist");
                setGallery(response.key);
                return response.key;
              }}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageHostelForm;
