import * as React from "react";
import { useMutation, useQuery } from "react-query";
import {
  getAllRoomWithOccupantDetails,
  getRoomWithOccupantByHostelId,
} from "../../Room/Requests";
import {
  Space,
  Table,
  Typography,
  Image,
  Card,
  Col,
  Empty,
  Tag,
  Avatar,
  Modal,
  Row,
  Segmented,
} from "antd";

import type { ColumnsType } from "antd/lib/table";
import { s3Path } from "../../../utils/s3Config/s3upload";
import {
  GroupOutlined,
  SettingOutlined,
  EllipsisOutlined,
  EditOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import ShowOccupantDetailsForm from "../../Occupant/Form/ShowOccupantDetailsForm";
import ShowOccupantDetails, {
  ShowOccupantDetailsModalContext,
} from "../../Occupant/Modal/ShowOccupantDetails";
import OccupantViewModal from "../../Occupant/Modal/ShowOccupantDetails";
import { size } from "lodash";
import { getAllHostel } from "../../Hotel Detail/Requests";

const { Text, Title } = Typography;
const { Meta } = Card;
interface DataType {
  id: number;
  name: string;
  person: string;
  phone: number;
  status: any;
}
type DataIndex = keyof DataType;

const HostelCard: React.FC = () => {
  const [view, setView] = React.useState(false);
  const [id, setId] = React.useState("");
  const {
    isLoading,
    isError,
    data: room,
  } = useQuery(["room"], () => getAllRoomWithOccupantDetails(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  const [roomData, setRoomData] = React.useState<any>(room);

  const { data: hostel } = useQuery(["hostel"], () => getAllHostel(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  const mutateHostel = useMutation((id: string) =>
    getRoomWithOccupantByHostelId(id)
  );
  const OnHostelSelect = (i: any) => {
    // console.log(i);

    // setSelectedHostelId(i);
    //  setIsOccupantSelected(true);
    i === "All"
      ? setRoomData(room)
      : mutateHostel
          .mutateAsync(i)
          .then((payload) => {
            setRoomData(payload);
          })
          .catch((e) => {
            console.log(e, "error");
          });
  };
  console.log(roomData, "ss");

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      width: "20%",
      title: "Room No.",
      dataIndex: "roomNo",
      key: "roomNo",
      fixed: "left",
      render: (text) => <Title level={2}>{text}</Title>,
    },
    {
      width: "80%",
      title: "Occupant Details",
      dataIndex: "roomNo",
      key: "roomNo",
      render: (_, value: any) => {
        var length = value?.occupant
          ? value.occupancy - value?.occupant?.length
          : value.occupancy;
        const array = Array.from({ length: length }, (_, index) => {
          return (
            <Space>
              <Card
                style={{ margin: 5 }}
                cover={
                  // <GroupOutlined
                  //   style={{
                  //     fontSize: 100,
                  //     color: "rgba(0, 0, 0, 0.2)",
                  //   }}
                  // />

                  <img
                    src={require("../../../assets/img/bed.jpg")}
                    width={100}
                    height={100}
                    style={{ opacity: 0.25, padding: 5, marginTop: 5 }}
                  />
                }
              >
                <Row style={{ alignContent: "center" }}>
                  <Text
                    style={{
                      marginLeft: 12,
                      fontSize: 15,
                      fontWeight: "bold",
                      color: "rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    Empty Bed
                  </Text>
                </Row>
                <br />
              </Card>
            </Space>
          );
        });
        return (
          <>
            {value.occupant &&
              value.occupant.map((item: any, i: any) => {
                var debit = 0;
                var credit = 0;
                for (var a = 0; a < item.ledgers.length; a++) {
                  if (item.ledgers[a].type === "Debit") {
                    debit = debit + item.ledgers[a].amount;
                  } else {
                    credit = credit + item.ledgers[a].amount;
                  }
                }
                var result = credit - debit;
                return (
                  <Space>
                    <Card
                      // onClick={<ShowOccupantDetails id={item._id } />}
                      onClick={() => {
                        setId(item._id);
                        setView(!view);
                      }}
                      size="small"
                      //style={{ width: 150, height: 100 }}
                      hoverable
                      style={{ margin: 5 }}
                      cover={
                        <>
                          <img
                            alt="example"
                            src={s3Path + item.imageUrl}
                            width={100}
                            height={100}
                          />
                          <Modal
                            width={1000}
                            title=" View Occupant Details"
                            open={view}
                            onOk={() => setView(false)}
                            onCancel={() => setView(false)}
                          >
                            <ShowOccupantDetailsForm id={id} />
                          </Modal>
                        </>
                      }
                      // actions={[<OccupantViewModal id={item._id} />]}
                      // cover={<img alt="example" src={s3Path + item.imageUrl} />}
                    >
                      <Space.Compact
                        direction="vertical"
                        size="small"
                        style={{ marginTop: 2 }}
                      >
                        <Text
                          style={{
                            fontStyle: "italic",
                            fontSize: 14,
                          }}
                          code
                        >
                          {item.name}
                        </Text>
                        <Text
                          style={{ fontStyle: "italic", fontSize: 14 }}
                          code
                          type="warning"
                        >
                          +91-{item?.phoneNo}
                        </Text>
                        {result === 0 ? (
                          <Tag
                            style={{ marginLeft: 2, fontSize: 14 }}
                            color="pink"
                          >
                            All Clear:₹{result}
                          </Tag>
                        ) : result < 0 ? (
                          <Tag color="red">Due:{result}</Tag>
                        ) : (
                          <Tag color="Green">Advance:{result}</Tag>
                        )}
                      </Space.Compact>
                      <br />
                      {/* <Col>
                        <Image
                          width={130}
                          height={85}
                          alt=""
                          src={s3Path + item.imageUrl}
                        />
                        <Space.Compact direction="vertical">
                          <Text code>{item.name}</Text>
                          <Text code type="success">
                            +91-{item?.phoneNo}
                          </Text>
                          {result === 0 ? (
                            <Tag color="pink">All Clear:₹{result}</Tag>
                          ) : result < 0 ? (
                            <Tag color="red">Due:{result}</Tag>
                          ) : (
                            <Tag color="Green">Advance:{result}</Tag>
                          )}
                        </Space.Compact>
                      </Col> */}
                      {/* <ShowOccupantDetails id={id} /> */}
                    </Card>
                  </Space>
                );
              })}
            {array}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Segmented
        onChange={OnHostelSelect}
        options={[
          {
            label: "All",
            value: "All",
          },
          ...(hostel &&
            hostel?.map((item: any, i: number) => {
              return {
                label: item.hostelName,
                // <div style={{ padding: 4 }}>
                //   <Avatar style={{ backgroundColor: "#f56a00" }}>
                //     <HomeOutlined />
                //   </Avatar>
                //   <div> {item.hostelName}</div>
                // </div>

                value: item.id,
              };
            })),
        ]}
      />
      <Table columns={column} dataSource={roomData} size="small" />
    </>
  );
};
export default HostelCard;
