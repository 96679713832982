import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";

import { Row } from "antd";
import CreateRoomModal from "../Modal/CreateRoom";
import RoomList from "../Component/List";

const RoomPageHome: React.FC<RouteComponentProps> = () => {
  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between">
              <h2>Room List</h2>
              <CreateRoomModal />
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <RoomList />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(RoomPageHome);
