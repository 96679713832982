import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
  Select,
  Card,
  Tag,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { queryClient } from "../../..";
import type { UploadFile } from "antd/es/upload/interface";
import type { RcFile, UploadProps } from "antd/es/upload";

import TextArea from "antd/lib/input/TextArea";

import LocalStorageService from "../../../utils/local-storage";
import { CreatePaymentModalContext } from "../Modal/CreatePayment";
import { getAllBooking } from "../../Booking/Requests";
import { getAllBill, getBillByOccupant } from "../../Bill/Requests";
import { getAllOccupant, getOccupantById } from "../../Occupant/Requests";
import { InputRules } from "../../../utils/InputRules";
import moment from "moment";

const { Text, Title } = Typography;
const CreatePayment: React.FC<{ id?: string }> = (props) => {
  const modalContext = React.useContext(CreatePaymentModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [isOccupantSelected, setIsOccupantSelected] = React.useState(false);
  const [occupantData, setOccupantData] = React.useState<Object | any>({});
  const [billData, setBillData] = React.useState([]);

  const [selectedOccupantId, setSelectedOccupantId] = React.useState(undefined);
  const createPaymentMutation = useMutation(
    (values: any) => request("admin.payment.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("payment");
        queryClient.invalidateQueries("occupant");
      },
    }
  );

  const { data: occupant } = useQuery(
    ["occupant"],
    async () => getAllOccupant(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;

    setFormLoading(true);

    createPaymentMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Payment has been successfully created.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  const mutateOccupant = useMutation((id: string) => getOccupantById(id));
  const OnOccupantSelect = (i: any) => {
    // console.log(i);

    setSelectedOccupantId(i);
    setIsOccupantSelected(true);

    mutateOccupant
      .mutateAsync(i)
      .then((payload) => {
        setOccupantData(payload);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  React.useEffect(() => {
    if (props.id) {
      form.setFieldsValue({ occupantId: props.id });
      OnOccupantSelect(props.id);
      getBill(props.id);
    }
  }, []);
  const mutateBill = useMutation((id: string) => getBillByOccupant(id));
  const getBill = async (id: string) => {
    console.log(id, "parameters");

    mutateBill
      .mutateAsync(id)
      .then((payload) => {
        setBillData(payload);
      })
      .catch((e: any) => {
        console.log("error", e);
      });
  };
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Form.Item
        label="Occupant Name"
        style={{ fontWeight: "bold" }}
        name="occupantId"
        tooltip={{
          title: "Occupant Name",
          icon: <InfoCircleOutlined />,
        }}
      >
        {isOccupantSelected ? (
          <Card>
            <Row justify={"space-between"} align={"middle"}>
              <Title level={3}>{occupantData?.name}</Title>
              <Col>
                <Row>
                  <Text type="success" code>
                    Phone No:{occupantData?.phoneNo}
                  </Text>
                </Row>
                <Row>
                  <Text type="warning" code>
                    Address:{occupantData?.address}
                  </Text>
                </Row>
                <Row>
                  <Text type="danger" code>
                    {" "}
                    Parent Name:{occupantData?.parentName}
                  </Text>
                </Row>
              </Col>
              {!props.id && (
                <Button
                  onClick={() => setIsOccupantSelected(false)}
                  type="dashed"
                >
                  Change Occupant
                </Button>
              )}
            </Row>
          </Card>
        ) : (
          <Select
            onSelect={(value) => {
              OnOccupantSelect(value);
              getBill(value);
            }}
            showSearch
            popupMatchSelectWidth
            // style={{ minHeight: 150 }}
            placeholder="Select Occupant"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input)
            }
          >
            {occupant &&
              occupant?.map((item: any, i: number) => {
                return (
                  <Select.Option value={item?._id} key={i}>
                    <Row justify={"space-between"} align={"middle"}>
                      <Title level={5}>{item?.name}</Title>
                      <Col flex={3} span={6}>
                        <Row>
                          <Text type="success" code>
                            Phone No:{item?.phoneNo}
                          </Text>
                        </Row>
                        <Row>
                          <Text type="warning" code>
                            Address:{item?.address}
                          </Text>
                        </Row>
                        <Row>
                          <Text type="danger" code>
                            {" "}
                            Parent Name:{item?.parentName}
                          </Text>
                        </Row>
                      </Col>
                    </Row>
                    <br />
                  </Select.Option>
                );
              })}
          </Select>
        )}
      </Form.Item>
      <Form.Item
        label="Select Bill"
        style={{ fontWeight: "bold" }}
        name="billId"
        tooltip={{
          title: "billId",
          icon: <InfoCircleOutlined />,
        }}
        //rules={[{ required: true }]}
        hasFeedback
      >
        <Select placeholder="Select Bill">
          {billData &&
            billData?.map((item: any, i: number) => {
              return (
                <Select.Option value={item?.id} key={i}>
                  <Row gutter={22} justify={"space-between"} align={"middle"}>
                    <Col>
                      <Text strong>
                        {moment(item.createdAt).format("DD/MMM/YYYY")}
                      </Text>
                    </Col>
                    <Col>
                      <Text code type="danger" strong>
                        PRH/Bill/{item.seq}
                      </Text>
                    </Col>
                    <Col>
                      <Tag color="green"> ₹ {item.amount}/-</Tag>
                    </Col>
                  </Row>
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Amount"
        name="amount"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Amount",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Amount",
          },
          { whitespace: true },
          { min: 3 },
          ...InputRules.number,
        ]}
        hasFeedback
      >
        <Input placeholder="Enter Amount" maxLength={8} />
      </Form.Item>

      <Form.Item
        label="Remarks"
        name="remarks"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "remarks",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Remarks",
          },
          { whitespace: true },
          ...InputRules.text,
        ]}
        hasFeedback
      >
        <Input placeholder="Enter Remarks" maxLength={30} />
      </Form.Item>

      <Form.Item
        label="Status"
        style={{ fontWeight: "bold" }}
        name="isActive"
        tooltip={{
          title: "status",
          icon: <InfoCircleOutlined />,
        }}
        valuePropName="checked"
        // rules={[{ required: true }]}
      >
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreatePayment;
