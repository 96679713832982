import { path } from "./path";

export const ledgerRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createLedger",
    module: "ledger",
    alias: "admin.ledger.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateLedger/:id",
    module: "ledger",
    alias: "admin.ledger.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllLedger",
    module: "ledger",
    alias: "admin.ledger.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneLedger/:id",
    module: "ledger",
    alias: "admin.ledger.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findLedgerByOccupantId/:id",
    module: "ledger",
    alias: "admin.ledger.occupant",
    isSubDomain: true,
  },
];
