import { Result, Tag, Image, Empty } from "antd";

import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

// import OccupantManageModal from "../../Modal/ManageOccupant";

import { Button, Space, Table, Typography } from "antd";

import { getLedgerByOccupant } from "../../Payment/Requests";
import { SearchOutlined, WhatsAppOutlined } from "@ant-design/icons";

import moment from "moment";
import ReactWhatsapp from "react-whatsapp";

const WhatsappMessage: React.FC<{ phoneNumber: string; message: string }> = (
  props
) => {
  return (
    <Button
      type="link"
      href={
        "whatsapp://send/?phone=91-" +
        props.phoneNumber +
        "&text=" +
        props.message
      }
      icon={<WhatsAppOutlined style={{ fontSize: "25px", color: "#50C878" }} />}
    />
  );
};

export default WhatsappMessage;
