import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";

import { Row } from "antd";
import CreatePaymentTermsModal from "../Modal/CreatePaymentTerms";
import PaymentTermsList from "../Component/List";

const PaymentTermsPageHome: React.FC<RouteComponentProps> = () => {
  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between">
              <h2>Plan List</h2>
              <CreatePaymentTermsModal />
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <PaymentTermsList />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(PaymentTermsPageHome);
