import { request } from "../../../utils/services";

export const getAllActiveHostel = async () => {
  const { data } = await request("admin.hostel.list.active");
  return data;
};

export const getAllHostel = async () => {
  const { data } = await request("admin.hostel.list");
  return data;
};

export const getHostelById = async (id: string) => {
  const { data } = await request("admin.hostel.id", { id });
  return data;
};
